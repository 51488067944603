import React, { useContext, useEffect, useState } from "react";
import {
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  useColorScheme,
  Dimensions,
  Platform,
} from "react-native";
import { Text, View } from "../../../components/shared/Themed";
import oktaConfig from "../../../configs/okta";
import ContentTitleHeader from "../../../components/core/ContentTitleHeader";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import CompanyLogo from "../../../components/core/CompanyLogo";
import TabBarIcon from "../../../components/core/TabBarIcon";
import { AntDesign } from "@expo/vector-icons";
import { addOktaBypassUser } from "../../../services/usersService";
import { useToast } from "react-native-toast-notifications";
import { Picker } from "react-native";
import { getAllCompaniesList } from "../../../services/companyService";
import { companyListModel } from "../../../models/companyModel";
import { ScrollView } from "react-native-gesture-handler";

type Props = {
  navigation: any;
};

type ValidationErrors = {
  [key: string]: string;
};

const { width, height } = Dimensions.get("window");

export default function CreateOktaBypassUserAccountForm({ navigation }: Props) {
  const colorScheme = useColorScheme();
  const { isSmallDevice } = useContext(DeviceOrientationContext);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [zone, setZone] = useState("Australia/Sydney");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [team, setTeam] = useState("");
  const toast = useToast();
  const [companyList, setCompanyList] = useState([]);

  const auto_sync = false;

  const validateFields = () => {
    const validationErrors: { [key: string]: string } = {};
    if (!email.trim()) {
      validationErrors.email = "Email is required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)
    ) {
      validationErrors.email = "Email is not valid.";
    }
    if (!zone.trim()) validationErrors.zone = "Zone is required.";
    if (!firstName.trim())
      validationErrors.firstName = "First name is required.";
    if (!lastName.trim()) validationErrors.lastName = "Last name is required.";
    if (!password.trim()) {
      validationErrors.password = "Password is required.";
    } else if (password.length > 25) {
      validationErrors.password = "Password must not exceed 25 characters.";
    }
    if (!team.trim()) validationErrors.team = "Team is required.";
    if (!role.trim()) validationErrors.role = "Role is required.";
    if (!companyId.trim())
      validationErrors.companyId = "Company Id is required.";

    if (!login.trim()) {
      validationErrors.login = "Username is required.";
    } else if (login.length > 25) {
      validationErrors.login = "Username must not exceed 25 characters.";
    }
    return validationErrors;
  };

  const submitHandler = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const displayName = `${firstName} ${lastName}`;
    const formData = {
      email,
      zone,
      companyId,
      role,
      firstName,
      lastName,
      displayName,
      login,
      team,
      password,
      auto_sync,
    };

    await addOktaBypassUser(formData)
      .then((result) => {
        if (result.isSuccessfull) {
          toast.show(result?.data?.message, {
            type: "success",
          });
          navigation.reset({
            index: 0,
            routes: [{ name: "OrgSettings", params: { screen: "Users" } }],
          });
        } else {
          toast.show(result.data, {
            type: "danger",
          });
        }
      })
      .catch((err) => {
        toast.show(err.message, {
          type: "danger",
        });
      });
  };

  useEffect(() => {
    const fetchCompanyList = async () => {
      setLoading(true);
      getAllCompaniesList().then((data: any) => {
        setCompanyList(data.company);
        setLoading(data.loading);
      });
    };
    fetchCompanyList();
  }, []);

  const DrawerHeader = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 2,
            borderColor: "grey",
          }}
        >
          <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
            <TouchableOpacity
              onPress={() =>
                navigation.reset({
                  index: 0,
                  routes: [
                    { name: "OrgSettings", params: { screen: "Users" } },
                  ],
                })
              }
            >
              <AntDesign name="arrowleft" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <View style={{ paddingLeft: 10, justifyContent: "center" }}>
            <Text style={{ fontWeight: "500" }}>Go Back</Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <ScrollView
      style={{
        height: "100%",
        backgroundColor: colorScheme === "light" ? "#fafafa" : "black",
      }}
    >
      {!isSmallDevice && (
        <View style={{ flexDirection: "row", width: "100%", height: 60 }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              height: 60,
            }}
          >
            <CompanyLogo />
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "60%",
              height: 60,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                (window.location.href = `${oktaConfig.api.redirectUri}`)
              }
            >
              <TabBarIcon name="home" color={"black"} />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {!isSmallDevice && (
        <ContentTitleHeader
          name="Organization Settings"
          navigation={navigation}
        />
      )}
      <DrawerHeader />
      <View style={styles.container}>
        <View style={styles.box}>
          <Text style={styles.header}>User Account Form</Text>

          <Text style={styles.label}>Email</Text>
          <TextInput
            style={[styles.input, errors.email && styles.inputError]}
            onChangeText={setEmail}
            value={email}
            placeholder="Enter email"
          />
          {errors.email && <Text style={styles.errorText}>{errors.email}</Text>}

          <Text style={styles.label}>Zone</Text>
          <Picker
            selectedValue={zone}
            onValueChange={(value: string) => {
              setZone(value);
            }}
            style={styles.picker}
          >
            <Picker.Item label="Select a zone" value="" />
            <Picker.Item
              label="Australia/Brisbane"
              value="Australia/Brisbane"
            />
            <Picker.Item
              label="Australia/Melbourne"
              value="Australia/Melbourne"
            />
            <Picker.Item label="Australia/Sydney" value="Australia/Sydney" />
            <Picker.Item
              label="Australia/Tasmania"
              value="Australia/Tasmania"
            />
            <Picker.Item label="Australia/Perth " value="Australia/Perth " />
          </Picker>
          {errors.zone && <Text style={styles.errorText}>{errors.zone}</Text>}

          <Text style={styles.label}>First Name</Text>
          <TextInput
            style={[styles.input, errors.firstName && styles.inputError]}
            onChangeText={setFirstName}
            value={firstName}
            placeholder="Enter first name"
          />
          {errors.firstName && (
            <Text style={styles.errorText}>{errors.firstName}</Text>
          )}

          <Text style={styles.label}>Last Name</Text>
          <TextInput
            style={[styles.input, errors.lastName && styles.inputError]}
            onChangeText={setLastName}
            value={lastName}
            placeholder="Enter last name"
          />
          {errors.lastName && (
            <Text style={styles.errorText}>{errors.lastName}</Text>
          )}

          <Text style={styles.label}>Username</Text>
          <TextInput
            style={[styles.input, errors.login && styles.inputError]}
            onChangeText={setLogin}
            value={login}
            placeholder="Enter username"
          />
          {errors.login && <Text style={styles.errorText}>{errors.login}</Text>}

          <Text style={styles.label}>Company</Text>
          <Picker
            selectedValue={companyId}
            onValueChange={(value: any) => {
              const selectedCompany = companyList.find(
                (company: companyListModel) => company.company_id === value
              );
              setCompanyId(value);
              setCompanyName(
                selectedCompany ? selectedCompany.company_name : ""
              );
            }}
            style={styles.picker}
          >
            <Picker.Item label="Select a company" value="" />
            {companyList.map((company: companyListModel) => (
              <Picker.Item
                key={company.company_id}
                label={company.company_name}
                value={company.company_id}
              />
            ))}
          </Picker>

          {errors.companyId && (
            <Text style={styles.errorText}>{errors.companyId}</Text>
          )}

          <Text style={styles.label}>Team</Text>
          <Picker
            selectedValue={team}
            onValueChange={(value: string) => {
              setTeam(value);
            }}
            style={styles.picker}
          >
            <Picker.Item label="Select a team" value="" />
            <Picker.Item label="Operation" value="operation" />
            <Picker.Item label="Sales" value="sales" />
            <Picker.Item label="Admin" value="admin" />
            <Picker.Item label="Warehouse" value="warehouse" />
          </Picker>
          {errors.team && <Text style={styles.errorText}>{errors.team}</Text>}

          <Text style={styles.label}>Role:</Text>
          <Picker
            selectedValue={role}
            onValueChange={(value: string) => {
              setRole(value);
            }}
            style={styles.picker}
          >
            <Picker.Item label="Select a role" value="" />
            <Picker.Item label="MEMBER" value="MEMBER" />
            <Picker.Item label="OWNER" value="OWNER" />
            <Picker.Item label="ADMIN" value="ADMIN" />
          </Picker>
          {errors.role && <Text style={styles.errorText}>{errors.role}</Text>}

          <Text style={styles.label}>Password</Text>
          <TextInput
            style={[styles.input, errors.password && styles.inputError]}
            onChangeText={setPassword}
            value={password}
            placeholder="Enter password"
            secureTextEntry
          />
          {errors.password && (
            <Text style={styles.errorText}>{errors.password}</Text>
          )}

          <TouchableOpacity style={styles.button} onPress={submitHandler}>
            <Text style={styles.buttonText}>Submit</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: height * 0.02,
    backgroundColor: "#f8f9fa",
    width: "100%",
    marginTop: 50,
    marginBottom: 50,
  },
  box: {
    width: "100%",
    maxWidth: 800,
    padding: 16,
    borderRadius: 12,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  header: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
    color: "#333",
  },
  label: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 8,
    color: "#555",
  },
  input: {
    width: "100%",
    height: 50,
    backgroundColor: "#fff",
    paddingHorizontal: 16,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 12,
    fontSize: 16,
    color: "#333",
    ...Platform.select({
      ios: {
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
      },
      android: {
        elevation: 2,
      },
    }),
  },
  inputError: {
    borderColor: "red",
    backgroundColor: "#ffe6e6",
  },
  errorText: {
    color: "red",
    fontSize: 14,
    marginBottom: 8,
    textAlign: "left",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    backgroundColor: "#1E90FF",
    borderRadius: 8,
    marginTop: 20,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 4,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
  },
  picker: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    fontSize: 15,
    marginTop: 10,
    marginBottom: 10,
  },
});
