import React, { useEffect, useState } from "react";
import { Text, View } from "../shared/Themed";
import { StyleSheet } from "react-native";
import { ResponseSelectBox } from "../../models/response";
import DropDownPicker, { ItemType } from "react-native-dropdown-picker";
import { ReadOnlyProps } from "../../utils/templates";

interface Props {
  Items:
    | Array<Record<string, string>>
    | Array<string | number>
    | ItemType<string>[];
  required: boolean;
  title: string;
  placeholder: string;
  width: number | string;
  defaultOption?: ResponseSelectBox | string | null;
  onSelectedValue: (event: any) => void;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  zIndex: number;
}

export const SelectBoxControlOrder: React.FC<ReadOnlyProps<Props>> = (
  props: ReadOnlyProps<Props>
) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.defaultOption);
  const [items, setItems] = useState(props.Items);

  useEffect(() => {
    if (props.defaultOption) {
      setValue(props.defaultOption);
    } else {
      setValue("");
    }
  }, [props.defaultOption]);

  return (
    <View
      style={[
        styles.container,
        { width: props.width },
        { zIndex: props.zIndex },
      ]}
    >
      <View style={styles.row}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>
            {props.title}{" "}
            {!props.required && (
              <Text style={styles.optionalText}>(Optional)</Text>
            )}
          </Text>
        </View>
        <View style={styles.dropdownContainer}>
          <DropDownPicker
            schema={{
              label: "value",
              value: "key",
            }}
            open={open}
            value={value as string}
            setValue={setValue}
            items={items as ItemType<string>[]}
            setItems={setItems}
            setOpen={setOpen}
            onSelectItem={(val: any) => {
              props.onSelectedValue(val["key"]);
            }}
            placeholder={props.placeholder}
            placeholderStyle={styles.placeholderStyles}
            listMode="SCROLLVIEW"
            style={styles.dropdown}
            dropDownContainerStyle={styles.dropdownOverlay}
            showTickIcon={true}
            listItemContainerStyle={styles.listItemContainerStyle}
            listItemLabelStyle={styles.listItemLabelStyle}
            tickIconStyle={styles.tickIconStyle}
            itemSeparator={false}
            disabled={props.disabled}
            onOpen={props.onOpen}
            onClose={props.onClose}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    marginTop: 15,
  },
  row: {
    flexDirection: "row",
    alignItems: "center", // Ensure proper alignment of row elements
  },
  labelContainer: {
    flex: 1,
    justifyContent: "center",
    height: 35,
  },
  label: {
    fontSize: 15,
    fontWeight: "400",
    textAlign: "left",
    color: "black",
  },
  optionalText: {
    fontSize: 10,
    color: "lightgrey",
  },
  dropdownContainer: {
    flex: 1.75,
    paddingLeft: 15,
    zIndex: 10, // Ensure dropdown is on top of other elements
  },
  dropdown: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "#79747E",
    height: 28,
    padding: 4,
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  placeholderStyles: {
    color: "grey",
  },
  dropdownOverlay: {
    position: "absolute", // Ensure absolute positioning
    top: "100%", // Position it directly below the input field
    left: 0, // Align to the left of the container
    width: "100%", // Make sure it's full-width
    marginTop: 5, // Space between the dropdown and input field
    borderRadius: 3,
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)", // Optional: Adds a subtle shadow
  },
  listItemContainerStyle: {
    display: "flex",
    flexDirection: "row",
  },
  listItemLabelStyle: {
    padding: "2%",
  },
  tickIconStyle: {
    marginTop: 5,
    marginRight: 5,
    color: "rgb(47, 149, 220)",
  },
});
