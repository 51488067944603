import React, { useEffect, useState } from "react";
import { Text, View } from "../../shared/Themed";
import { DataTable } from "react-native-paper";
import * as Services from "../../../utils/common";
import { Badge } from "@react-native-material/core";
import { StyleSheet } from "react-native";

import Icon from "react-native-vector-icons/FontAwesome5";
import { TouchableOpacity } from "react-native-gesture-handler";
import Spinner from "../../shared/Spinner";

import { default as statusColorCodes } from "../../../utils/constants/statusColorCodes";
import { ReadOnlyProps } from "../../../utils/templates";
import { NestedDataTable } from "./NestedDataTableWithPagination";
import { formatValueAsDataType } from "../../../utils/common";
import { NoDataBanner } from "../../shared/NoDataBanner";

interface Props {
  headers: any;
  rows: Array<any>;
  itemsPerPage: number;
  useModal: boolean;
  isDataLoading?: boolean;
  isDisplayTimeWithDate?: boolean;
  setModalVisible: (event: any) => void;
  handleRowClick: (event: any) => void;
  setSelected: (event: any) => void;

  //Nested Table Variables
  rowKeyValue?: string;
  nestedItemKeyValue?: string;
  nestedHeader?: any;
  isRowsExpandable?: boolean;
  isNestedDataLoaded?: boolean;
  isToExpandAllRows?: boolean;

  isDetail?: boolean; //Control Modal variable
  isEdit?: boolean; //Edit Button variable
  isDelete?: boolean; //Delete Button variable
  isSubDetail?: boolean; //Control Sub Modal variable
  handleDeleteRow: (id: string | number) => void;

  //exclude column to not have thousand separator format
  columnsToNotHaveThousandSeparator?: Array<string>;

  //handle sorting
  handleSortColumn?: (columnKey: string) => void;
  handleNestedSortColumn?: (
    columnKey: string,
    columnParentIdentifier: string
  ) => void;

  operationsScreen?: boolean;
  disableHeader?: boolean;
  operationActions?: (order: any, action: string) => void;
}

export const DataTableWithPagination: React.FC<ReadOnlyProps<Props>> = ({
  headers,
  rows,
  itemsPerPage,
  useModal,
  nestedHeader,
  rowKeyValue,
  nestedItemKeyValue,
  isDisplayTimeWithDate = false,
  isDataLoading = false,
  isRowsExpandable = false,
  isNestedDataLoaded = false,
  isToExpandAllRows = false,
  setModalVisible,
  handleRowClick,
  setSelected,
  isDetail = false,
  isSubDetail = false,
  isEdit = false,
  isDelete = false,
  handleDeleteRow,
  columnsToNotHaveThousandSeparator,
  handleSortColumn,
  handleNestedSortColumn,
  operationsScreen,
  disableHeader,
  operationActions,
}: ReadOnlyProps<Props>) => {
  const [currentRow, setCurrentRow] = useState<string>("");
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [expandAllRows, setExpandAllRows] = useState<boolean>(false);
  const [_itemsPerPage] = useState<number>(itemsPerPage);

  const [page, setPage] = useState<number>(0);

  const fromPage = (
    page: number,
    itemPerPage: number,
    rows: any[] | undefined
  ) => {
    return page * itemPerPage + itemPerPage > (rows || []).length
      ? (rows || []).length
      : page * itemPerPage + itemPerPage;
  };

  useEffect(() => {
    setExpandAllRows(isToExpandAllRows);
  }, [isToExpandAllRows]);

  const handleRowClicking = (row: any, clickType: string) => {
    if (useModal && clickType === "details") {
      setModalVisible(true);
      setSelected(row);
    } else if (clickType === "edit") {
      handleRowClick(row);
    }
  };

  const headersView = (headers: any) => {
    const views: Array<any> = [];
    if (headers) {
      Object.keys(headers).forEach((value, index) => {
        views.push(
          <DataTable.Title
            style={{
              flex: headers[value].width,
            }}
            key={Services.makeid(10)}
          >
            <View style={{ width: 120 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: headers[value].textAlign,
                }}
              >
                {index === 0 && isRowsExpandable && (
                  <>
                    <TouchableOpacity
                      onPress={() => handleExpandableAllRowIcon()}
                    >
                      <Icon
                        name={!expandAllRows ? "plus-square" : "minus-square"}
                        size={15}
                        color={
                          headers[value].color ? headers[value].color : "black"
                        }
                      />
                    </TouchableOpacity>
                    &nbsp;&nbsp;
                  </>
                )}
                <TouchableOpacity onPress={() => handleSortColumn?.(value)}>
                  <Text
                    style={{
                      color: headers[value].color
                        ? headers[value].color
                        : "black",
                    }}
                  >
                    {headers[value].name}
                  </Text>
                </TouchableOpacity>{" "}
                &nbsp;&nbsp;
                {headers[value].isSort && rows.length > 1 && (
                  <TouchableOpacity onPress={() => handleSortColumn?.(value)}>
                    <Icon
                      name={
                        headers[value].sortDirection === "asc"
                          ? "sort-alpha-down"
                          : "sort-alpha-up"
                      }
                      size={13}
                      color="black"
                    />
                  </TouchableOpacity>
                )}
              </Text>
            </View>
          </DataTable.Title>
        );
      });
      return views;
    }
  };

  const displayExpandableIcons = (
    row: any,
    expandRow: boolean,
    expandAllRows: boolean
  ): string => {
    if (!expandRow && !expandAllRows) {
      return "plus-square";
    } else if (row[rowKeyValue as string] === currentRow || expandAllRows) {
      return "minus-square";
    } else {
      return "plus-square";
    }
  };

  const handleExpandableAllRowIcon = () => {
    setCurrentRow("");
    setExpandAllRows(!expandAllRows);
  };

  const handleExpandableRowIcon = (row: any, currentIcon: string) => {
    const isRowExpanded = currentIcon === "plus-square";
    setCurrentRow(row[rowKeyValue as string]);
    setExpandRow(isRowExpanded);
  };

  const cellsView = (rows: Array<any>, headers: any) => {
    const rowsViews: Array<React.ReactNode> = [];
    rows.forEach((row, index) => {
      let views: Array<React.ReactNode> = [];
      const switchToExpand =
        (expandRow || expandAllRows) &&
        (row[rowKeyValue as string] === currentRow || expandAllRows);

      Object.keys(headers).map((value, index) => {
        if (row) {
          if (!["status", "ship_status"].includes(value)) {
            views.push(
              <View
                style={{
                  flex: headers[value].width,
                  borderColor: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: operationsScreen
                    ? "#fff"
                    : switchToExpand
                    ? "#eee"
                    : "white",
                }}
                key={Services.makeid(10)}
              >
                {index === 0 && isRowsExpandable ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "flex-start",
                      backgroundColor: operationsScreen
                        ? "#fff"
                        : switchToExpand
                        ? "#eee"
                        : "white",
                    }}
                  >
                    {!expandAllRows && (
                      <TouchableOpacity
                        onPress={() =>
                          handleExpandableRowIcon(
                            row,
                            displayExpandableIcons(
                              row,
                              expandRow,
                              expandAllRows
                            )
                          )
                        }
                      >
                        <Icon
                          name={displayExpandableIcons(
                            row,
                            expandRow,
                            expandAllRows
                          )}
                          size={15}
                          color={
                            operationsScreen ? "#000" : "rgb(47, 149, 220)"
                          }
                        />
                      </TouchableOpacity>
                    )}
                    <Text style={operationsScreen && { fontWeight: "500" }}>
                      &nbsp;{row[value]}
                    </Text>
                  </View>
                ) : value === "" ? (
                  <View
                    style={{
                      flexDirection: "row",
                      backgroundColor: operationsScreen
                        ? "#fff"
                        : switchToExpand
                        ? "#eee"
                        : "white",
                    }}
                  >
                    {isDetail && (
                      <TouchableOpacity
                        onPress={() => handleRowClicking(row, "details")}
                      >
                        <Icon
                          style={{ marginRight: "5px" }}
                          name={"info-circle"}
                          size={15}
                          color={
                            operationsScreen ? "#000" : "rgb(47, 149, 220)"
                          }
                        />
                      </TouchableOpacity>
                    )}
                    {isEdit && (
                      <TouchableOpacity
                        onPress={() => handleRowClicking(row, "edit")}
                      >
                        <Icon
                          style={{ marginRight: "5px" }}
                          name={"edit"}
                          size={15}
                          color={
                            operationsScreen ? "#000" : "rgb(47, 149, 220)"
                          }
                        />
                      </TouchableOpacity>
                    )}
                    {isDelete && (
                      <TouchableOpacity
                        onPress={() =>
                          handleDeleteRow(row[rowKeyValue as string] as string)
                        }
                      >
                        <Icon name={"trash"} size={15} color="rgb(178,34,34)" />
                      </TouchableOpacity>
                    )}
                  </View>
                ) : (
                  <Text
                    style={{
                      fontSize: 12,
                      width: "100%",
                      textAlign: headers[value].textAlign,
                    }}
                  >
                    {columnsToNotHaveThousandSeparator?.includes(
                      headers[value].name
                    )
                      ? `${
                          headers[value].name.includes("Uom1")
                            ? `${row[value]} (${row["InvntryUom"]})`
                            : headers[value].name.includes("Uom2")
                            ? `${row[value]} (${row["Uom2"]})`
                            : `${row[value]}`
                        }`
                      : formatValueAsDataType(
                          row[value],
                          isDisplayTimeWithDate
                        ) || 0}
                  </Text>
                )}
              </View>
            );
          }

          if (["status", "ship_status"].includes(value)) {
            views.push(
              <View
                style={{
                  flex: headers[value].width,
                  borderColor: "white",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  backgroundColor: operationsScreen
                    ? "#fff"
                    : switchToExpand
                    ? "#eee"
                    : "white",
                }}
                key={Services.makeid(10)}
              >
                <Badge
                  label={row[value]}
                  tintColor="white"
                  color={statusColorCodes[row[value]]}
                />
              </View>
            );
          }
        }
      });

      if (row) {
        rowsViews.push(
          <React.Fragment key={index}>
            <DataTable.Row
              style={{
                backgroundColor: operationsScreen
                  ? "#fff"
                  : switchToExpand
                  ? "#eee"
                  : "white",
              }}
              key={`${Services.makeid(10)}_${index}`}
            >
              {views}
            </DataTable.Row>
            {isRowsExpandable && switchToExpand && (
              <NestedDataTable
                itemsPerPage={itemsPerPage}
                isNestedDataLoaded={isNestedDataLoaded}
                nestedHeader={nestedHeader}
                nestedRows={row[nestedItemKeyValue as string]}
                rowKeyValue={rowKeyValue}
                isDetail={isSubDetail}
                setModalVisible={setModalVisible}
                handleRowClick={handleRowClick}
                setSelected={setSelected}
                key="rowKeyValue"
                nestedColumnsToNotHaveThousandSeparator={
                  columnsToNotHaveThousandSeparator
                }
                isDisplayTimeWithDate={isDisplayTimeWithDate}
                handleNestedSortColumn={handleNestedSortColumn}
                disableHeader={disableHeader}
                operationActions={operationActions}
              />
            )}
          </React.Fragment>
        );
      }
    });
    return rowsViews;
  };

  return (
    <DataTable>
      <DataTable.Header>{headersView(headers)}</DataTable.Header>
      {isDataLoading ? (
        <View>
          <Spinner
            size="small"
            color="green"
            styles={{
              flexDirection: "row",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          />
        </View>
      ) : rows.length > 0 ? (
        cellsView(
          rows.slice(
            page * _itemsPerPage,
            page * _itemsPerPage + _itemsPerPage
          ),
          headers
        )
      ) : (
        <NoDataBanner />
      )}
      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(rows.length / _itemsPerPage)}
        onPageChange={(page) => setPage(page)}
        label={`${fromPage(page, itemsPerPage, rows)} of ${rows.length}`}
        showFastPaginationControls
        numberOfItemsPerPage={_itemsPerPage}
        selectPageDropdownLabel={"Rows per page"}
      />
    </DataTable>
  );
};

const styles = StyleSheet.create({
  collapsableView: {
    flex: 1,
    borderStyle: "solid",
    borderColor: "rgba(47, 149, 220, 0.5)",
    borderWidth: 1,
  },
});
