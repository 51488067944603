import { deliveryModel } from "../../models/reportsModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  deliveryList: Array<deliveryModel>;
  isDeliveryListLoaded: boolean;
  selectedDelivery: deliveryModel;
  isDeliveryTableRefreshed: boolean;
  allowedRolesForDelivery: Array<string>;
  deliveryListFilterCategoryList: Array<string>;
}

export const deliveryState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  deliveryList: [],
  isDeliveryListLoaded: false,
  selectedDelivery: {} as unknown as deliveryModel,
  isDeliveryTableRefreshed: false,
  deliveryListFilterCategoryList: [
    "all",
    "NSW",
    "VIC",
    "QLD",
    "WA",
    "SA",
    "TAS",
    "Today",
    "Next 7 days",
    "Next 14 days",
    "Next 30 days"
  ],

  //Constants
  allowedRolesForDelivery: ["OWNER", "ADMIN", "MANAGER", "OPERATION"]
};
