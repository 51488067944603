export default {
  Confirmed: "dodgerblue",
  Completed: "darkseagreen",
  Delayed: "crimson",
  Delivered: "grey",
  "In Transit": "mediumslateblue",
  Shipped: "#007B5E",
  Scheduled: "#24445C",
  Pending: "#FFBD12",
  Reserve: "#eb5819",
  Cancelled: "#ff0f00",
  Prepay: "#2ca02c",
} as Record<string, string>;
