import React, { useContext, useEffect, useRef, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import useColorScheme from "../../hooks/useColorScheme";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getAllSalesOperationsList } from "../../services/operationService";
import Spinner from "../../components/shared/Spinner";
import {
  mdoperationHeaders,
  operationHeaders,
  smoperationHeaders,
} from "../Operations/utils/operationHeaders";
import { NestedDataTable } from "../../components/custom/DataTable/NestedDataTableWithPagination";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { SalesOperationBadgeHorizontalList } from "../../components/custom/Badge/SalesOperationBadgeHorizontalList";
import { SearchOperationLabelBannerWithSearchBar } from "../../components/custom/Banners/SearchOperationLabelBannerWithSearchBar";
import { FAB } from "react-native-paper";

export default function SalesOperationsManagementScreen({ navigation }: any) {
  const colorScheme = useColorScheme();
  const isFocused = useIsFocused();
  const [isRefresh, setRefresh] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const userInfo = useSelector((state: any) => state.configurations.userInfo);
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      operationHeaders
    );
  const { isLargeDevice, isMediumDevice, isMobileDevice, isSmallDevice } =
    useContext(DeviceOrientationContext);
  const columnsToNotHaveThousandsSeparator: Array<string> = ["Packing #"];
  const operationsFilterCategoryList = [
    "All",
    "Pending",
    "Prepay",
    "Reserve",
    "Draft",
    "Last 3 months",
  ];
  const { operationsList, isOperationsListLoaded } = useSelector(
    (state: any) => state.operations
  );
  const listRef = useRef<ScrollView>(null);
  const [contentOffSet, setContentOffSet] = useState<number>(0);
  const [fabPosition] = useState<number>(320);
  const handleScroll = (event: any) => {
    setContentOffSet(event.nativeEvent.contentOffset.y);
  };

  const navigateToOrder = (order: any, action: string) => {
    navigation.navigate("SingleOperation", { order, action });
  };
  useEffect(() => {
    setRefresh(false);
    setTableHeaders(
      isLargeDevice
        ? operationHeaders
        : isMediumDevice
        ? mdoperationHeaders
        : smoperationHeaders
    );
    getAllSalesOperationsList(tableHeaders, userInfo.login || "").then(
      (r) => {}
    );
  }, [isFocused, isRefresh]);
  const handleNavigation = (screen: string): void =>
    navigation.navigate(screen);

  const renderPageHeader = () => (
    <View style={styles.headerContainer}>
      <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
        <MaterialCommunityIcons
          name="menu"
          size={28}
          color={colorScheme === "dark" ? "#fff" : "#333"}
          style={{ cursor: "pointer" }}
        />
      </TouchableOpacity>
      <View style={{ flex: 1, paddingLeft: 15 }}>
        <Text style={{ fontSize: 18, fontWeight: "500" }}>Order Status</Text>
      </View>
      <TouchableOpacity
        onPress={() => handleNavigation("ManageOperations")}
        style={styles.newOrderButton}
      >
        <Text style={styles.newOrderText}>New Order</Text>
        <MaterialCommunityIcons name="plus" size={16} color="#fff" />
      </TouchableOpacity>
    </View>
  );

  const renderFilters = () => (
    <View
      style={[styles.renderFilterStyles, !isLargeDevice && styles.mobileLayout]}
    >
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.filterContainer}
      >
        {!isSmallDevice && !isMobileDevice && (
          <Text style={styles.quickFilterText}>Quick Filter</Text>
        )}
        <SalesOperationBadgeHorizontalList
          itemsList={operationsFilterCategoryList}
          filterCategory={selectedCategory || ""}
          setFilterCategory={setSelectedCategory!}
          tableHeaders={tableHeaders}
        />
      </ScrollView>

      <SearchOperationLabelBannerWithSearchBar
        titleName={`Total Items: ${operationsList.length}`}
        setRefresh={setRefresh}
        dataToExport={operationsList}
        fileName="orderReportScreen"
        sheetName="Order Report Screen"
        disableTitle={true}
        noBorder={true}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      {renderPageHeader()}
      <View style={styles.filterMainContainer}>{renderFilters()}</View>
      <ScrollView
        style={styles.contentContainer}
        onScroll={handleScroll}
        ref={listRef}
      >
        {!isOperationsListLoaded ? (
          <View style={styles.spinnerContainer}>
            <Spinner size="small" color="#74BBFF" />
          </View>
        ) : (
          <View style={{ backgroundColor: "#fff" }}>
            <FAB
              icon="arrow-up"
              style={[
                styles.fab,
                {
                  top:
                    contentOffSet +
                    Dimensions.get("window").height -
                    fabPosition,
                },
              ]}
              onPress={() =>
                listRef.current?.scrollTo({ x: 0, y: 0, animated: true })
              }
              small
            />
            <NestedDataTable
              itemsPerPage={15}
              isNestedDataLoaded={!isOperationsListLoaded}
              nestedHeader={tableHeaders}
              nestedRows={operationsList}
              key="rowKeyValue"
              nestedColumnsToNotHaveThousandSeparator={
                columnsToNotHaveThousandsSeparator
              }
              operationActions={navigateToOrder}
            />
          </View>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 20,
    paddingHorizontal: 15,
    backgroundColor: "#fff",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#000",
  },
  newOrderButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#1E88E5",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
  },
  newOrderText: {
    color: "#fff",
    marginRight: 5,
    fontWeight: "bold",
    cursor: "pointer",
  },
  filterMainContainer: {
    width: "100%",
  },
  filterContainer: {
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  operationsContainer: {
    padding: 15,
    backgroundColor: "#fff",
  },
  operationCard: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 12,
    marginBottom: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  operationCardContent: {
    flex: 1,
  },
  operationTitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 5,
  },
  operationStatus: {
    fontSize: 14,
    color: "#555",
  },
  contentContainer: {
    flex: 1,
    backgroundColor: "#f8f9fa",
  },
  spinnerContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  quickFilterText: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  renderFilterStyles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  mobileLayout: {
    flexDirection: "column",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    zIndex: 1,
  },
});
