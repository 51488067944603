import {
  checkIsDate,
  checkIsNumber,
  formatDate,
  numberToThousandSeparator,
} from "./dateUtility";

const makeid = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const validateEmail = (email: string) => {
  const pattern =
    /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,25}[\.][a-z]{2,5}/g;
  const result = pattern.test(email);
  return result;
};

const numberWithCommas = (x: Number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const renameLabel = (label: string) => {
  const splittedLabel = label.split("_");
  let recraftedLabel = "";
  if (splittedLabel.length > 0) {
    splittedLabel.forEach((label) => {
      recraftedLabel += `${label.charAt(0).toUpperCase()}${label.slice(1)} `;
    });
  } else {
    recraftedLabel += `${label.charAt(0).toUpperCase()}${label.slice(1)} `;
  }

  return recraftedLabel;
};

const handleTableLoader = (
  value: boolean,
  isTimerNeeded: boolean,
  setIsSearched: (e: boolean) => void
) => {
  if (isTimerNeeded) {
    setTimeout(() => {
      setIsSearched(value);
    }, 1000);
  } else {
    setIsSearched(value);
  }
};

/**
 * This method checks the typeof of the passing value
 * and apply some operations on the value like
 * if the value is date, then format the date
 * or if the value needs a thousand separator for a human readable format
 * it then applies thousand separator
 * and it also replaces, the spaces within a value with '|' character to avoid
 * conflict with date.parse() method
 * @param {string} value - the value on which operations need to apply for
 * @param {boolean} isDisplayTimeWithDate - a flag that decides if the returning value should have time integrated
 * @returns {string} - it returns the formatted value
 */
const formatValueAsDataType = (
  value: string,
  isDisplayTimeWithDate: boolean
) => {
  if (value && value !== undefined) {
    const removingSpaces = value.toString().replace(/\ /gi, "|");
    return checkIsDate(removingSpaces)
      ? formatDate(removingSpaces, "short", isDisplayTimeWithDate)
      : checkIsNumber(removingSpaces)
      ? Number(removingSpaces) < 0 && Number(removingSpaces) === 0
        ? "0"
        : numberToThousandSeparator(removingSpaces)
      : removingSpaces.replace(/\|/gi, " ");
  }

  return value;
};

/**
  * Replaces all occurrences of searchString with replacement and returns the new object.
  * 
  * @param entity The object on which the replacements should be applied to
  * @param serachString The search phrase (as a regular expression)
  * @param replacement Replacement value
  */
const replaceDataValues = (
  entity: Array<object>, 
  obj: object
) => {
  let newEntity = entity;
  for (const [searchString, replacement] of Object.entries(obj)) {
    newEntity = JSON.parse(
      JSON.stringify(newEntity).replaceAll(searchString, replacement)
    );
  }

  return newEntity;
};

/**
 * This method add a leading 0 to a number
 * @param {number} value - the value on which operations need to apply for
 * @returns {string} - it returns the value with leading zero
 */
const pad = (value: number) => {
  return value < 10 ? "0" + value.toString() : value.toString();
};

export {
  makeid,
  validateEmail,
  numberWithCommas,
  renameLabel,
  handleTableLoader,
  formatValueAsDataType,
  pad,
  replaceDataValues
};
