import s3Config from "../configs/s3";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = s3Config.api.domain;

export const getS3SignUrl = async (
  bucketName: string,
  fileName: string,
  s3Method: string,
  contentType: string
) => {
  const methodPath = "/s3/presignedurl";
  const httpBody = {
    bucketName: bucketName,
    fileName: fileName,
    s3Method: s3Method,
    contentType: contentType,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then((result) => {
      if (result.data.status === "ACTIVE") {
        return {
          signedUrl: result.data.url,
          isLoading: false,
        };
      } else {
        return {
          signedUrl: null,
          isLoading: false,
        };
      }
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const pushPhotoToS3 = async (
  presignedUrl: string,
  httpMethod: string,
  fileBase64: any,
  contentType: string
) => {
  const buffer = Buffer.from(fileBase64, "base64");

  return await httpMStepsClient({
    type: httpMethod,
    path: presignedUrl,
    body: buffer,
    contentType: contentType,
    isTokenRequired: false,
    isAcceptHeaderRequired: false,
  })
    .then((result) => {
      if (result.data) {
        return {
          signedUrl: result.data.url,
          isLoading: false,
        };
      } else {
        return {
          signedUrl: null,
          isLoading: false,
        };
      }
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const pushFileToS3 = async (
  httpMethod: string,
  fileBase64: any,
  name: string,
  contentType: string
) => {
  const methodPath = "/s3/saveS3File";

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: httpMethod,
    path: methodPath,
    body: {
      file: fileBase64,
      fileName: name,
      contentType: contentType,
    },
    isAcceptHeaderRequired: false,
  })
    .then((result) => {
      if (result.data) {
        return {
          signedUrl: result.data.fileUrl,
          isLoading: false,
        };
      } else {
        return {
          signedUrl: null,
          isLoading: false,
        };
      }
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const checkS3Object = async (bucketName: string, fileName: string) => {
  const methodPath = "/s3/checkS3Object";
  const _httpBody = {
    bucketName: bucketName,
    fileName: fileName,
  };
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: _httpBody,
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const getFileFromS3 = async (fileName: string) => {
  const methodPath = "/s3/getImageFromS3";
  const _httpBody = {
    fileName: fileName,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: _httpBody,
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

// The processAttachments function
export const processAttachments = async (attachments: string[]) => {
  // Process all attachments and return only valid (non-null) ones
  const updatedAttachments = await Promise.all(
    attachments.map(async (fileName) => {
      try {
        // Call the API to get the file details from S3
        const fileData = await getFileFromS3(fileName);

        if (fileData) {
          const { imageUrl } = fileData;

          // Return the new file details in the desired format
          return {
            fileName: fileName,
            fileUri: imageUrl,
            fileType: fileName.substring(fileName.lastIndexOf(".") + 1),
          };
        }

        return null; // Return null if fileData is unavailable
      } catch (error) {
        console.error(`Error fetching details for ${fileName}:`, error);
        return null; // In case of error, return null
      }
    })
  );

  // Filter out any null values and return only valid attachments
  return updatedAttachments.filter((attachment) => attachment !== null);
};
