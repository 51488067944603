import { actionTypesOperations as actionTypes } from "../constants/actionTypesOperations";
import { ordersCollectionModel } from "../../models/ordersModel";

const setOperationsList = (data: Array<ordersCollectionModel>) => {
  return {
    type: actionTypes.ListOfOperations,
    payload: data,
  };
};

const setIsOperationsTableRefreshed = (data: boolean) => {
  return {
    type: actionTypes.IsOperationsTableRefreshed,
    payload: data,
  };
};

const setSelectedOperation = (data: ordersCollectionModel) => {
  return {
    type: actionTypes.SelectedOperation,
    payload: data,
  };
};

const setFilteredOperations = (data: Array<ordersCollectionModel>) => {
  return {
    type: actionTypes.FilterOperations,
    payload: data,
  };
};

const setSearchOperations = (data: Array<ordersCollectionModel>) => {
  return {
    type: actionTypes.SearchOperations,
    payload: data,
  };
};

export default {
  setOperationsList,
  setIsOperationsTableRefreshed,
  setSelectedOperation,
  setFilteredOperations,
  setSearchOperations,
};
