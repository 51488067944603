export const drawerItemsMain = [
  {
    key: "Operations Management",
    title: "Operations Status",
    routes: [
      {
        nav: "OperationsManagement",
        routeName: "OperationsManagement",
        title: "Operations Status",
      },
    ],
  },
  {
    key: "Add Operation",
    title: "Add Operation",
    routes: [
      {
        nav: "PlaceOrder",
        routeName: "PlaceOrder",
        title: "Add Operation",
      },
    ],
  },
];
