import store from "../configureStore";
import allActions from "../actions";
import { deliveryModel } from "../../models/reportsModel";
import { actionTypesDelivery, actionTypesStockTransfer } from "../constants/actionTypesDelivery";

const { dispatch } = store;

export const DeliveryDispatcher = (
  actionType: actionTypesDelivery,
  data: unknown
) => {
  const deliveryAction = allActions.deliveryActions;

  switch (actionType) {
    case actionTypesDelivery.ListOfDelivery:
      dispatch(
        deliveryAction.setDeliveryList(data as Array<deliveryModel>)
      );
      break;
    case actionTypesDelivery.IsDeliveryTableRefreshed:
      dispatch(deliveryAction.setIsDeliveryTableRefreshed(data as boolean));
      break;
    case actionTypesDelivery.SelectedDelivery:
      dispatch(deliveryAction.setSelectedDelivery(data as deliveryModel));
      break;
  }
};

export const StockTransferDispatcher = (
  actionType: actionTypesStockTransfer,
  data: unknown
) => {
  const stockTransferAction = allActions.deliveryActions;

  switch (actionType) {
    case actionTypesStockTransfer.ListOfStockTransfer:
      dispatch(
        stockTransferAction.setStockTransferList(data as Array<deliveryModel>)
      );
      break;
    case actionTypesStockTransfer.IsStockTransferTableRefreshed:
      dispatch(stockTransferAction.setIsStockTransferTableRefreshed(data as boolean));
      break;
    case actionTypesStockTransfer.SelectedStockTransfer:
      dispatch(stockTransferAction.setSelectedStockTransfer(data as deliveryModel));
      break;
  }
};

