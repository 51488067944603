import { deliveryModel } from "../../models/reportsModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  stockTransferList: Array<deliveryModel>;
  isStockTransferListLoaded: boolean;
  selectedStockTransfer: deliveryModel;
  isStockTransferTableRefreshed: boolean;
  allowedRolesForStockTransfer: Array<string>;
  stockTransferListFilterCategoryList: Array<string>;
}

export const stockTransferState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  stockTransferList: [],
  isStockTransferListLoaded: false,
  selectedStockTransfer: {} as unknown as deliveryModel,
  isStockTransferTableRefreshed: false,
  stockTransferListFilterCategoryList: [
    "all",
    "NSW",
    "VIC",
    "QLD",
    "WA",
    "SA",
    "TAS",
    "Today",
    "Next 7 days",
    "Next 14 days",
    "Next 30 days",
    "Transfer In",
    "Transfer Out"
  ],

  //Constants
  allowedRolesForStockTransfer: ["OWNER", "ADMIN", "MANAGER", "OPERATION"]
};