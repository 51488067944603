import React, {useState} from 'react';
import {ActivityIndicator, StyleSheet, TouchableOpacity, View} from 'react-native';
import {Text} from 'react-native-paper';

interface Props {
    confirmationText:string,
    successfulText:string,
    approve: ()=>void,
    reject: ()=>void,
}

const UpdateConfirmationScreen: React.FC<Props> = ({approve, reject,successfulText,confirmationText}) => {

    const [approved, setApproved] = useState(false);

    const callApprove = () =>{
        setApproved(true);
        setTimeout(()=>{
            approve();
        },2000)
        return true;
    }

    const callReject = () =>{
        reject()
    }


    const approvedView = () =>{

            return(
                <View>
                    <Text style={styles.textCenter}>
                        {successfulText}
                    </Text>
                    <ActivityIndicator size="large" color="lightgrey" style={{marginVertical:5}}/>
                    <Text style={{fontSize:12, color:'red', textAlign:'center'}}>Redirecting...</Text>
                </View>
            )

    }

    const confirmationView = () => {

            return(
                <View>
                    <Text style={[styles.textCenter, {paddingBottom:15}]}>
                        {confirmationText}
                    </Text>
                    <View style={styles.btnContainer}>
                        <View>
                            <TouchableOpacity onPress={callApprove} style={styles.confirmationBtns}>
                                <Text style={styles.confirmationBtnsText}>Yes</Text>
                            </TouchableOpacity>
                        </View>
                        <View>
                            <TouchableOpacity onPress={callReject} style={styles.confirmationBtns}>
                                <Text style={styles.confirmationBtnsText}>No</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            )

    }
  
    return (
        <View style={{flex:1, alignContent:'center', justifyContent:'center', backgroundColor:'#fff',}}>
            {
                approved ?
                approvedView()
                :
                confirmationView()
            }

        </View>
        
    );
};

const styles = StyleSheet.create({
    textCenter:{
        textAlign:'center'
    },
    btnContainer:{
        flexDirection:'row',
        justifyContent: "space-evenly"
    },
    confirmationBtns:{
        backgroundColor:'#D9D9D9',
        paddingHorizontal:20, 
        paddingVertical:10,
        borderRadius:25,
    },
    confirmationBtnsText:{
        color:'#6C6C6C'
    }
})

export default UpdateConfirmationScreen;