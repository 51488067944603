import React, { useContext, useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { View } from "../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../components/custom/Modal";
import Spinner from "../../components/shared/Spinner";
import { getQldProductionStocks } from "../../services/stockService";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import {
  headers,
  MediumDeviceHeaders,
  SmallDeviceHeaders,
} from "./utils/productionStockHeaders";
import { productionStockModel } from "../../models/reportsModel";
import { validSignedURL } from "../../utils/validateSignedUrl";
import { useSelector } from "react-redux";
import { handleTableLoader } from "../../utils/common";
import { sortColumns } from "../../utils/sortColumns";
import { filterBasedOnAnyKey } from "../../utils/searchInArrays";

export default function ProductionStocksReportScreen({
  route,
  navigation,
}: any) {
  const { isLargeDevice, isMediumDevice } = useContext(
    DeviceOrientationContext
  );
  const { productionStockReport, isProductionStockReportListLoaded } =
    useSelector((state: any) => state.reports);

  const [selected, setSelected] = useState<productionStockModel>(
    {} as unknown as productionStockModel
  );

  const [prodStockPageLevelList, setProdStockPageLevelList] = useState<
    Array<productionStockModel>
  >([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedPmxDetailList: Array<productionStockModel> = sortColumns(
      prodStockPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setProdStockPageLevelList(_sortedPmxDetailList);
  };

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      const searchedList: Array<productionStockModel> = filterBasedOnAnyKey(
        productionStockReport,
        searchText
      );
      setProdStockPageLevelList(searchedList);
    }

    setTimeout(() => {
      fadeTableLoader();
    }, 1000);
  };

  useEffect(() => {
    let isMounted = true;

    // Avoid keep refreshing table when item is selected
    if (isMounted) {
      if (prodStockPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();
        getQldProductionStocks("", tableHeaders);
      }
    }

    if (Object.keys(selected).length !== 0) {
      validSignedURL(
        selected.ItemCode,
        "label",
        setImageLoading,
        setSelectedImage
      );
    }

    setTableHeaders(
      isLargeDevice
        ? headers
        : isMediumDevice
        ? MediumDeviceHeaders
        : SmallDeviceHeaders
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [selected, isRefresh]);

  useEffect(() => {
    setImageLoading(true);
  }, [modalVisible]);

  useEffect(() => {
    setProdStockPageLevelList(productionStockReport);

    setTimeout(() => {
      fadeTableLoader();
    }, 2000);
  }, [productionStockReport]);

  const ModalContent = () => {
    const _excludedKeys = ["ItemCode", "ProdCode", "FrgnName"];
    const _orderedKeys = [
      "ItemName",
      "InStock",
      "Required",
      "OnOrder",
      "Committed",
      "Planned",
    ];

    return (
      <Modal.ModalContent
        title={selected.ItemCode || ""}
        subTitle={selected.FrgnName}
        icon="folder"
        uri={selectedImage}
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headers}
            orderedKeys={_orderedKeys}
          />
        }
      />
    );
  };

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={[styles.container, { backgroundColor: "#f2f2f2" }]}>
        <View
          style={[
            styles.containerContent,
            {
              width: "100%",
            },
          ]}
        >
          {isProductionStockReportListLoaded ? (
            <View>
              <LabelBannerWithSearchBar
                titleName={`Total Items: ${prodStockPageLevelList.length}`}
                handleSearch={handleSearch}
                setRefresh={setRefresh}
              />
              <DataTableWithPagination
                headers={tableHeaders}
                rows={prodStockPageLevelList}
                itemsPerPage={100}
                useModal={true}
                isDetail={true}
                setModalVisible={setModalVisible}
                setSelected={setSelected}
                isDataLoading={isSearched}
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={ModalContent}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  modalBodyContainer: {
    width: "100%",
    justifySelf: "center",
    alignSelf: "center",
    backgroundColor: "black",
  },
  modalBodyContainerContent: {
    flexDirection: "row",
    width: "100%",
    justifySelf: "center",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
