import React, { useEffect, useState } from "react";
import {
  FlatList,
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { Ionicons } from "@expo/vector-icons";
import Spinner from "../../../components/shared/Spinner";
import { stockModel } from "../../../models/reportsModel";

// Define the customer data structure
interface Location {
  key: any;
  value: string;
}

interface LocationAutoCompleteProps {
  onLocationSelect: (customerName: string) => void;
  selectedLocation: string | null;
  isLoading: boolean;
  setLoading: any;
}

const LocationAutoComplete: React.FC<LocationAutoCompleteProps> = ({
  onLocationSelect,
  selectedLocation,
  isLoading,
  setLoading,
}) => {
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { isStockReportListLoaded, stockReportList } = useSelector(
    (state: any) => state.reports
  );
  const [locations, setLocations] = useState<Location[]>([
    {
      key: 1,
      value: "ACT",
    },
    {
      key: 2,
      value: "VIC",
    },
    {
      key: 3,
      value: "QLD",
    },
    {
      key: 4,
      value: "NSW",
    },
    {
      key: 5,
      value: "TAS",
    },
    {
      key: 6,
      value: "WA",
    },
  ]);
  setLoading(false);

  // useEffect(() => {
  //   const uniqueLocationsSet = new Set<string>();
  //   const uniqueLocations = stockReportList
  //     .filter((report: stockModel) => report.Location)
  //     .reduce((uniqueReports, report) => {
  //       if (!uniqueLocationsSet.has(report.Location)) {
  //         uniqueLocationsSet.add(report.Location);
  //         uniqueReports.push({
  //           key: uniqueReports.length + 1,
  //           value: report.Location,
  //         });
  //       }
  //       return uniqueReports;
  //     }, [] as Array<{ key: number; value: string }>);

  //   // Filter uniqueLocations based on the query
  //   const filteredLocations = uniqueLocations.filter((location: Location) =>
  //     location.value.toLowerCase().includes(query.toLowerCase())
  //   );

  //   // Only update locations if they are different to avoid endless loop
  //   if (
  //     filteredLocations.length !== locations.length ||
  //     !filteredLocations.every(
  //       (loc: any, index: number) => loc.value === locations[index]?.value
  //     )
  //   ) {
  //     setLocations(filteredLocations);
  //   }

  //   setLoading(false); // Set loading to false after processing
  // }, [JSON.stringify(stockReportList), locations, query]);

  const handleSelect = (locationName: string) => {
    setQuery(locationName);
    setShowDropdown(false);
    setHighlightedIndex(-1); // Reset highlighted index
    onLocationSelect(locationName);
  };

  const handleClear = () => {
    setQuery("");
    setShowDropdown(false);
    onLocationSelect("");
  };

  const handleKeyDown = (e: any) => {
    if (!showDropdown || locations.length === 0) return;

    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < locations.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : locations.length - 1
      );
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      handleSelect(locations[highlightedIndex].value);
      Keyboard.dismiss(); // Dismiss keyboard on selection
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.label}>Location Name</Text>
      {!isStockReportListLoaded && !isLoading ? (
        <Spinner size="small" color="#00ff00" styles={styles.spinner} />
      ) : (
        <View style={styles.selectBox}>
          <TextInput
            style={styles.input}
            placeholder="Select or search for a customer"
            value={query || selectedLocation || ""}
            onChangeText={(text) => setQuery(text)}
            onFocus={() => setShowDropdown(true)}
            onKeyPress={handleKeyDown}
          />
          {(showDropdown || query.length > 0) && (
            <TouchableOpacity
              onPress={handleClear}
              style={[styles.clearButton]}
            >
              {query.length > 0 ? (
                <Ionicons name="close-circle" size={20} color="gray" />
              ) : (
                <Ionicons name="chevron-down" size={20} color="gray" />
              )}
            </TouchableOpacity>
          )}

          {showDropdown && isStockReportListLoaded && (
            <View>
              <FlatList
                style={styles.dropdown}
                data={locations}
                keyExtractor={(item: Location) => item.key}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles.option,
                      highlightedIndex === index && styles.highlightedOption,
                    ]}
                    onPress={() => handleSelect(item.value)}
                  >
                    <Text style={styles.optionText}>{item.value}</Text>
                  </TouchableOpacity>
                )}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  spinner: {
    marginRight: "22.5%",
  },
  container: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  label: {
    fontSize: 16,
    marginRight: 10,
  },
  selectBox: {
    width: "50%",
    position: "relative",
  },
  input: {
    height: 40,
    borderColor: "#000",
    borderWidth: 1,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: 14,
  },
  dropdown: {
    maxHeight: 150,
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  option: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  highlightedOption: {
    backgroundColor: "#ddd",
  },
  optionText: {
    fontSize: 14,
  },
  clearButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
});

export default LocationAutoComplete;
