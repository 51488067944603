import React, { useEffect, useState } from "react";
import {
  FlatList,
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { getCustomerStatus } from "../../../services/customerStatusService";
import { Ionicons } from "@expo/vector-icons";
import Spinner from "../../../components/shared/Spinner";

// Define the customer data structure
interface Customer {
  CardName: string;
  customerReference: string;
  customerNumber: string;
  customerEmail: string;
}

// CustomerAutoComplete component
interface CustomerAutoCompleteProps {
  onCustomerSelect: (customerName: string) => void;
  selectedCustomer: string | null;
}

const CustomerAutoComplete: React.FC<CustomerAutoCompleteProps> = ({
  onCustomerSelect,
  selectedCustomer,
}) => {
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { customerStatusReport, isCustomerStatusReportListLoaded } =
    useSelector((state: any) => state.reports);

  useEffect(() => {
    if (!isCustomerStatusReportListLoaded) {
      getCustomerStatus("", "", "", {});
    }
  }, [isCustomerStatusReportListLoaded]);

  // Filter customers based on query search
  const filteredCustomers =
    query.length === 0
      ? customerStatusReport
      : customerStatusReport.filter((customer: Customer) =>
          customer.CardName.toLowerCase().includes(query.toLowerCase())
        );

  const handleSelect = (customerName: string) => {
    setQuery(customerName);
    setShowDropdown(false);
    setHighlightedIndex(-1); // Reset highlighted index
    onCustomerSelect(customerName);
  };

  const handleClear = () => {
    setQuery("");
    setShowDropdown(false);
    onCustomerSelect("");
  };

  const handleKeyDown = (e: any) => {
    if (!showDropdown || filteredCustomers.length === 0) return;

    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCustomers.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredCustomers.length - 1
      );
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      handleSelect(filteredCustomers[highlightedIndex].CardName);
      Keyboard.dismiss(); // Dismiss keyboard on selection
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.label}>Customer Name</Text>
      {!isCustomerStatusReportListLoaded ? (
        <Spinner size="small" color="#00ff00" styles={styles.spinner} />
      ) : (
        <View style={styles.selectBox}>
          <TextInput
            style={styles.input}
            placeholder="Select or search for a customer"
            value={query || selectedCustomer || ""}
            onChangeText={(text) => setQuery(text)}
            onFocus={() => setShowDropdown(true)}
            onKeyPress={handleKeyDown}
          />
          {(showDropdown || query.length > 0) && (
            <TouchableOpacity
              onPress={handleClear}
              style={[styles.clearButton]}
            >
              {query.length > 0 ? (
                <Ionicons name="close-circle" size={20} color="gray" />
              ) : (
                <Ionicons name="chevron-down" size={20} color="gray" />
              )}
            </TouchableOpacity>
          )}

          {showDropdown && isCustomerStatusReportListLoaded && (
            <View style={styles.dropdownContainer}>
              <FlatList
                style={styles.dropdown}
                data={filteredCustomers}
                keyExtractor={(item) => item.CardName}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles.option,
                      highlightedIndex === index && styles.highlightedOption,
                    ]}
                    onPress={() => handleSelect(item.CardName)}
                  >
                    <Text style={styles.optionText}>{item.CardName}</Text>
                  </TouchableOpacity>
                )}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  spinner: {
    marginRight: "22.5%",
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    zIndex: 999,
  },
  label: {
    fontSize: 16,
    marginRight: 10,
  },
  selectBox: {
    width: "50%",
    position: "relative",
  },
  input: {
    height: 40,
    textAlign: "center",
    borderColor: "#000",
    borderWidth: 1,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: 14,
  },
  dropdownContainer: {
    position: "absolute",
    top: 45,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  dropdown: {
    maxHeight: 150,
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  option: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  highlightedOption: {
    backgroundColor: "#ddd",
  },
  optionText: {
    fontSize: 14,
  },
  clearButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
});

export default CustomerAutoComplete;
