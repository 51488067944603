import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Controller } from "react-hook-form";
import { TextInputControlOrder } from "../../../components/custom/TextInputControlOrder";
import { DateInputControlOrder } from "../../../components/custom/DateInputControlOrder";
import { SelectBoxControlOrder } from "../../../components/custom/SelectBoxControlOrder";
import { Checkbox } from "react-native-paper";
import CustomerAutoComplete from "./CustomerAutoComplete";
import { googlePlaceAutoComplete } from "../../../services/generalService";
import { FlatList } from "react-native-gesture-handler";
import Spinner from "../../../components/shared/Spinner";
import { operationsState } from "../../../redux/state/operations";

interface ValidationRules {
  [key: string]: {
    required?: string;
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
    pattern?: { value: RegExp; message: string };
    validate?: (value: any) => string | boolean;
  };
}

interface Customer {
  CardName: string;
  customerReference: string;
  customerNumber: string;
  customerEmail: string;
}

interface Props {
  navigateForward: () => void;
  mobileDevice: boolean;
  form: any;
  orderTypeList: Array<Record<string, string>>;
  validationRules: ValidationRules;
}

const OrderScreenFirst: React.FC<Props> = ({
  navigateForward,
  mobileDevice,
  form,
  orderTypeList,
  validationRules,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    onSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = form;

  const shippingMethod = operationsState.shippingMethodList;

  const [selectedOrderType, setSelectedOrderType] = useState<string | null>(
    getValues("orderType") || null
  );

  const [suggestions, setSuggestions] = useState([]);
  const handleLocationInputChange = async (text, onChange) => {
    onChange(text); // Update the input field
    if (text.length > 2) {
      // Avoid unnecessary API calls
      const results = await googlePlaceAutoComplete(text);
      setSuggestions(results || []); // Update suggestions list
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion, onChange) => {
    onChange(suggestion.name); // Update input with selected suggestion
    setSuggestions([]); // Hide suggestions after selection
  };

  return (
    <View style={[styles.container, { flex: 1 }]}>
      {/* Customer Details */}
      <View style={styles.formSection}>
        {/* <Text style={styles.formHeaders}>Customer Details</Text> */}
        <Controller
          control={control}
          name="customerName"
          rules={{ ...validationRules.customerName }}
          render={({ field: { onChange, value } }) => (
            <>
              <CustomerAutoComplete
                onCustomerSelect={(selectedCustomer) =>
                  onChange(selectedCustomer)
                }
                selectedCustomer={value}
              />
              {errors.customerName && (
                <Text style={styles.errorStyle}>
                  {errors.customerName.message}
                </Text>
              )}
            </>
          )}
        />

        <Controller
          control={control}
          name="customerReference"
          rules={{ ...validationRules.customerReference }}
          render={({ field: { onChange, value } }) => (
            <>
              <TextInputControlOrder
                title="Customer Reference"
                required={true}
                width="100%"
                placeholder=""
                value={value}
                setChangeText={onChange}
                blurOnSubmit={false}
                handleOnSubmitEditing={handleSubmit(onSubmit)}
              />
              {errors.customerReference && (
                <Text style={styles.errorStyle}>
                  {errors.customerReference.message}
                </Text>
              )}
            </>
          )}
        />
        <Controller
          control={control}
          name="customerNumber"
          rules={{ ...validationRules.customerNumber }}
          render={({ field: { onChange, value } }) => (
            <>
              <TextInputControlOrder
                title="Contact Number"
                required={true}
                width="100%"
                placeholder=""
                value={value}
                setChangeText={onChange}
                blurOnSubmit={false}
                handleOnSubmitEditing={handleSubmit(onSubmit)}
              />
              {errors.customerNumber && (
                <Text style={styles.errorStyle}>
                  {errors.customerNumber.message}
                </Text>
              )}
            </>
          )}
        />
        <Controller
          control={control}
          name="customerEmail"
          render={({ field: { onChange, value } }) => (
            <>
              <TextInputControlOrder
                title="Customer Email"
                required={false}
                width="100%"
                placeholder=""
                value={value}
                setChangeText={onChange}
                blurOnSubmit={false}
                handleOnSubmitEditing={handleSubmit(onSubmit)}
              />
            </>
          )}
        />
      </View>

      {/* Order Type */}
      <View style={styles.formSection}>
        <Text style={styles.formHeaders}>Order Type Details</Text>
        {orderTypeList?.map((item, index) => {
          return (
            <Controller
              key={index}
              control={control}
              name={item.key as string}
              render={({ field: { onChange } }) => (
                <Checkbox.Item
                  label={item.value as string}
                  status={
                    selectedOrderType === item.key ? "checked" : "unchecked"
                  }
                  onPress={() => {
                    const isSelected = selectedOrderType === item.key;
                    setSelectedOrderType(isSelected ? null : item.key);
                    setValue("orderType", isSelected ? null : item.key);
                    onChange(!isSelected);
                  }}
                  style={styles.checkbox}
                  position="leading"
                  color="#000"
                  labelStyle={styles.checkboxLabel}
                />
              )}
            />
          );
        })}
        {errors.orderType && (
          <Text style={[styles.errorStyle, { marginTop: 0 }]}>
            {errors.orderType.message}
          </Text>
        )}
      </View>

      {/* Shipping Details */}
      <View style={styles.formSection}>
        <Text style={styles.formHeaders}>Shipping Details</Text>
        <View style={{ flex: 1 }}>
          <Controller
            control={control}
            name="deliveryDate"
            render={({ field: { onChange, value } }) => (
              <DateInputControlOrder
                title={"ETA Date"}
                required={true}
                setDate={onChange}
                value={new Date()}
                width="100%"
                defaultText="Select ETD"
                disabled={true}
              />
            )}
          />
        </View>
        <Controller
          control={control}
          name="shippingMethod"
          rules={{ ...validationRules.shippingMethod }}
          render={({ field: { onChange, value } }) => (
            <>
              <SelectBoxControlOrder
                Items={shippingMethod}
                defaultOption={value}
                title="Shipping Method"
                width="100%"
                placeholder=""
                required={true}
                onSelectedValue={onChange}
                zIndex={999}
              />
              {errors.shippingMethod && (
                <Text style={[styles.errorStyle, { marginTop: 0 }]}>
                  {errors.shippingMethod.message}
                </Text>
              )}
            </>
          )}
        />

        <View style={styles.containerAddress}>
          <Controller
            control={control}
            name="address"
            rules={{ required: "Address is required" }}
            render={({ field: { onChange, value } }) => (
              <>
                <TextInputControlOrder
                  title="Address"
                  required={true}
                  width="100%"
                  placeholder="Enter your address"
                  value={value}
                  setChangeText={(text) =>
                    handleLocationInputChange(text, onChange)
                  }
                  blurOnSubmit={false}
                  handleOnSubmitEditing={handleSubmit(onSubmit)}
                  isMultiline={true}
                  height={60}
                />
                {errors.address && (
                  <Text style={styles.errorText}>{errors.address.message}</Text>
                )}

                {suggestions.length > 0 && (
                  <FlatList
                    data={suggestions}
                    keyExtractor={(item) => item.name}
                    renderItem={({ item }) => (
                      <TouchableOpacity
                        onPress={() => handleSuggestionSelect(item, onChange)}
                        style={styles.suggestionItem}
                      >
                        <Text style={styles.suggestionText}>{item.name}</Text>
                      </TouchableOpacity>
                    )}
                    style={styles.dropdown}
                  />
                )}
              </>
            )}
          />
        </View>
        <View style={{ flexDirection: "row-reverse" }}>
          <TouchableOpacity
            onPress={navigateForward}
            style={{
              paddingHorizontal: 25,
              paddingVertical: 12,
              backgroundColor: "#2CA02C",
              borderRadius: 30,
            }}
          >
            <Text style={{ color: "#fff" }}>Confirm</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tab: {
    flexDirection: "row",
    alignItems: "flex-start",
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  rectangle: {
    flex: 1,
    height: 42,
    alignContent: "center",
    justifyContent: "center",
  },
  triangle: {
    width: 0,
    height: 0,
    borderTopWidth: 21,
    borderBottomWidth: 21,
    borderLeftWidth: 40,
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
    marginLeft: 0,
  },
  rectangle1_color: {
    backgroundColor: "#06CF1A",
  },
  rectangle2_color: {
    backgroundColor: "#D1D1D1",
  },
  rectangle3_color: {
    backgroundColor: "#EAE8E8",
  },
  triangle1_color: {
    borderLeftColor: "#06CF1A",
  },
  triangle2_color: {
    borderLeftColor: "#D1D1D1",
  },
  triangle3_color: {
    borderLeftColor: "#EAE8E8",
  },
  tabText: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
  },

  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
    paddingVertical: 25,
  },
  formHeaders: {
    color: "#B5B5B5",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 15,
  },
  formSection: {
    marginBottom: 20,
  },
  checkbox: {
    paddingLeft: 0,
    marginLeft: -7,
    paddingVertical: 0,
  },
  checkboxLabel: {
    marginTop: 2,
    textAlign: "left",
    justifyContent: "center",
    fontWeight: "500",
  },
  errorStyle: {
    marginTop: -10,
    marginBottom: 10,
    fontSize: 13,
    color: "red",
  },
  spinner: {
    marginLeft: 12,
  },
  containerAddress: {
    marginTop: 15,
    marginBottom: 60,
  },
  errorText: {
    color: "red",
    marginTop: 5,
  },
  dropdown: {
    maxHeight: 150,
    borderColor: "#ccc",
    borderWidth: 1,
    backgroundColor: "#fff",
    borderRadius: 8,
    marginTop: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  suggestionItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderColor: "#eee",
  },
  suggestionText: {
    fontSize: 16,
    color: "#333",
  },
  addNewButtonContainer: {
    flexDirection: "row-reverse",
    marginTop: 15,
  },
  addNewButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: "#1E83E0",
    borderRadius: 4,
  },
  addNewText: {
    color: "#FFFFFF",
    fontSize: 14,
  },
});

export default OrderScreenFirst;
