import { defaultLog, logError } from "./utils/logService";
import oktaConfig from "../configs/okta";
import {
  OktaAuth,
  OktaAuthOptions,
  TokenManager,
  AccessToken,
  IDToken,
  UserClaims,
  TokenResponse,
} from "@okta/okta-auth-js";
import mapiConfig from "../configs/mapi";
import * as Sentry from "@sentry/browser";
import { ConfigurationDispatcher } from "../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../redux/constants/actionTypesConfigurations";

const BASE_URL = mapiConfig.api.domain;

const config: OktaAuthOptions = {
  issuer: oktaConfig.api.issuer,
  // Required for login flow using getWithRedirect()
  clientId: oktaConfig.api.clientId,
  redirectUri: oktaConfig.api.redirectUri,
  responseType: "code",
  pkce: false,
  // Configure TokenManager to use sessionStorage instead of localStorage
  tokenManager: {
    storage: "sessionStorage",
    autoRenew: true,
  },
};

const authClient: OktaAuth = new OktaAuth(config);
const tokenManager: TokenManager = authClient.tokenManager;

async function deleteSession() {
  // await authClient.revokeAccessToken(); // strongly recommended
  return authClient
    .closeSession()
    .then(() => {
      return {
        isLoading: false,
      };
    })
    .catch((e) => {
      Sentry.captureException(e);
      if (e.xhr && e.xhr.status === 429) {
        defaultLog(`Okta error: ${e}`);
        return {
          isLoading: false,
        };
      }
    });
}

async function getAuthWithLinkedin() {
  return authClient
    .signInWithRedirect({
      // Use a custom IdP for social authentication
      idp: "0oa169pupbo23HCAD0h8",
    })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch(function (err) {
      logError(err);
      Sentry.captureException(err);

      return {
        status: "FAILED",
        isLoading: false,
      };
    });
}

async function getAuth(username: string, password: string) {
  try {
    // Attempt fallback authentication first
    const response = await fallbackAuth(username, password);

    if (response.status !== "FAILED") {
      return response; // Return if fallback authentication succeeds
    }

    // Attempt secondary authentication
    const transaction = await authClient.signInWithCredentials({
      username,
      password,
    });

    if (transaction.status === "SUCCESS") {
      await authClient.token.getWithRedirect({
        sessionToken: transaction.sessionToken,
        responseType: "id_token",
      });

      return { status: "ACTIVE", isLoading: false };
    } else {
      return { status: "FAILED", isLoading: false };
    }
  } catch (error) {
    return { status: "FAILED", isLoading: false };
  }
}

async function getAccessToken() {
  return await authClient.token
    .getWithoutPrompt({
      responseType: ["token", "id_token"],
      scopes: ["openid", "profile"],
    })
    .then((data: TokenResponse) => {
      return {
        status: "ACTIVE",
        data: data,
      };
    })
    .catch(function (err) {
      Sentry.captureException(err);
      return {
        status: "FAILED",
      };
    });
}

async function getSession() {
  const response: Record<string, string | TokenResponse> =
    await getAccessToken();
  if (response.status === "FAILED") {
    return null;
  } else {
    tokenManager.setTokens((response.data as TokenResponse).tokens);
    const accessToken: AccessToken = (await tokenManager.get(
      "accessToken"
    )) as AccessToken;
    const idToken: IDToken = (await tokenManager.get("idToken")) as IDToken;
    const userInfo: UserClaims = await authClient.token.getUserInfo(
      accessToken,
      idToken
    );
    return authClient.session
      .get()
      .then(() => {
        return {
          userInfo: userInfo,
          idToken: idToken,
          accessToken: accessToken,
        };
      })
      .catch((err) => {
        Sentry.captureException(err);
        logError(`endpoint: getSession - Error: ${err}`);
      });
  }
}

async function forgotPassword(username: string) {
  return authClient
    .forgotPassword({
      username: username,
      factorType: "EMAIL",
    })
    .then(function (transaction) {
      if (transaction.status == "RECOVERY_CHALLENGE") {
        return {
          status: "ACTIVE",
        };
      } else {
        return {
          status: "FAILED",
        };
      }
    })
    .catch(function (err) {
      Sentry.captureException(err);
      return {
        status: err,
      };
    });
}

async function fallbackAuth(username: string, password: string) {
  try {
    const pathURL = BASE_URL.endsWith("/")
      ? `${BASE_URL}users/login`
      : `${BASE_URL}/users/login`;

    const response = await fetch(pathURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      throw new Error(`Fallback authentication failed: ${response.statusText}`);
    }

    const data = await response.json();
    if (data.accessToken && data.refreshToken) {
      const { accessToken, refreshToken, expiresAt, user } = data;
      setCustomTokens(accessToken, refreshToken, expiresAt, user);

      await getSession();
      // ConfigurationDispatcher(actionTypesConfigurations.IsUserAuthorized, true);
      return {
        status: "ACTIVE",
        isLoading: false,
        user: data.user,
      };
    }

    return {
      status: "FAILED",
      isLoading: false,
    };
  } catch (error) {
    Sentry.captureException(error);
    logError(`Fallback auth error: ${error}`);
    return {
      status: "FAILED",
      isLoading: false,
    };
  }
}

// async function fallbackAuth(username: string, password: string) {
//   try {
//     const response = await fetch(`${BASE_URL}/users/login`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ username, password }),
//     });

//     if (!response.ok) {
//       throw new Error(`Fallback authentication failed: ${response.statusText}`);
//     }

// const { accessToken, refreshToken, users } = await response.json();
// setCustomTokens(accessToken, refreshToken, users);

// return await getSession();
//   } catch (error) {
//     logError(`Fallback auth error: ${error}`);
//     return null;
//   }
// }

function setCustomTokens(
  accessToken: string,
  refreshToken: string,
  expiresAt: any,
  users: any
) {
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);
  sessionStorage.setItem("expiresAt", expiresAt);
  sessionStorage.setItem("sub", users.login);
}

function getCustomAccessToken() {
  const accessToken = sessionStorage.getItem("accessToken");
  // Retrieve expiresAt from sessionStorage (in ISO 8601 format)
  const expiresAt = new Date(sessionStorage.getItem("expiresAt") || "0");

  if (Date.now() > expiresAt.getTime()) {
    return null; // Token expired
  }

  return accessToken;
}

function getRefreshToken() {
  return sessionStorage.getItem("refreshToken");
}

function clearTokens() {
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("expiresAt");
  sessionStorage.removeItem("sub");
}

async function getCustomSession() {
  const accessToken = getCustomAccessToken();
  console.log("here is access token : ", accessToken);
  if (!accessToken) {
    return null; // No active session
  }

  return { accessToken };
  // try {
  //   const response = await fetch(`${BASE_URL}users/me`, {
  //     headers: { Authorization: `Bearer ${accessToken}` },
  //   });

  //   if (!response.ok) {
  //     throw new Error("Failed to fetch user session");
  //   }

  //   const userInfo = await response.json();
  //   return { userInfo, accessToken };
  // } catch (error) {
  //   logError(`getSession error: ${error}`);
  //   return null;
  // }
}

async function customLogout() {
  const accessToken = getAccessToken();

  try {
    if (accessToken) {
      await fetch(`${BASE_URL}auth/logout`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    }
  } catch (error) {
    logError(`Logout error: ${error}`);
  } finally {
    clearTokens();
  }
}

export {
  forgotPassword,
  getSession,
  getCustomSession,
  getAuth,
  getAuthWithLinkedin,
  deleteSession,
  authClient,
  tokenManager,
};
