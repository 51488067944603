import { itemsModal } from "../../models/itemsModel";
import { deliveryModel } from "../../models/reportsModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  itemsList: Array<itemsModal>;
  deliveryItemsList : Array<deliveryModel>;
  stockTransferItemsList : Array<deliveryModel>;
  isItemsListLoaded: boolean;
  isDeliveryItemsListLoaded: boolean;
  isStockTransferItemsListLoaded: boolean;
  nestedItemsList: Array<itemsModal>;
  nestedDeliveryItemsList: Array<deliveryModel>;
  nestedStockTransferItemsList: Array<deliveryModel>;
  isNestedItemsListLoaded: boolean;
  isNestedDeliveryItemsListLoaded: boolean;
  isNestedStockTransferItemsListLoaded: boolean;
}

export const itemsState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  itemsList: [],
  deliveryItemsList: [],
  stockTransferItemsList: [],
  nestedItemsList: [],
  nestedDeliveryItemsList: [],
  nestedStockTransferItemsList: [],
  isItemsListLoaded: false,
  isNestedItemsListLoaded: false,
  isDeliveryItemsListLoaded: false,
  isStockTransferItemsListLoaded: false,
  isNestedDeliveryItemsListLoaded: false,
  isNestedStockTransferItemsListLoaded: false
};
