import teamConfig from "../../../utils/constants/teams";

const { warehouse } = teamConfig.teams;

export const drawerItemsMain = (usersTeam: string) => {
  return [
    {
      key: "Stocks",
      title: "Stock",
      routes: [{ nav: "Stocks", routeName: "Stocks", title: "Stock" }],
    },
    ...[
      usersTeam !== warehouse
        ? {
            key: "Production",
            title: "Production",
            routes: [
              {
                nav: "ProductionStocks",
                routeName: "ProductionStocks",
                title: "Production Stock",
              },
              {
                nav: "RawMaterialStocksOverView",
                routeName: "RawMaterialStocksOverView",
                title: "Raw Material Overview",
              },
              {
                nav: "RawMaterialStocksDetails",
                routeName: "RawMaterialStocksDetails",
                title: "Raw Material Stock",
              },
            ],
          }
        : null,
    ],
    {
      key: "Warehouse",
      title: "Warehouse",
      routes: [
        {
          nav: "WarehouseInventory",
          routeName: "WarehouseInventory",
          title: "Inventory Overview",
        },
        {
          nav: "WarehouseDetailsInventory",
          routeName: "WarehouseDetailsInventory",
          title: "Inventory Detail",
        }
      ],
    },
    ...[
      usersTeam !== warehouse
        ? {
            key: "Products",
            title: "Search Product",
            routes: [
              { nav: "Products", routeName: "Products", title: "Product" },
            ],
          }
        : null,
    ],
    ...[
      usersTeam !== warehouse
        ? {
            key: "CustomerStatus",
            title: "Customer Status",
            routes: [{ nav: "CustomerStatus", routeName: "CustomerStatus", title: "Customer Status" }],
          }
        : null,
    ],
    // ...[
    //   usersTeam !== warehouse
    //     ? {
    //         key: "CustomerOrder",
    //         title: "Customer Order",
    //         routes: [{ nav: "CustomerOrder", routeName: "CustomerOrder", title: "Customer Order" }],
    //       }
    //     : null,
    // ],
  ];
};

