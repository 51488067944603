import { View, Text } from "./Themed";
import { StyleSheet } from "react-native";

export const NoDataBanner = () => {
  return (
    <View style={styles.noDataBanner}>
      <Text
        style={{
          fontWeight: "bold",
          textDecorationLine: "underline",
          textTransform: "uppercase",
          color: "rgba(47, 149, 220, 0.7)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        No Data Available
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  noDataBanner: {
    margin: "2%",
    textAlign: "center",
  },
});
