import { actionTypesDelivery as actionTypes, actionTypesStockTransfer as transferActionTypes } from "../constants/actionTypesDelivery";
import { deliveryState } from "../state/delivery";
import { stockTransferState } from "../state/stockTransfer";

export const deliveryReducer = (state = deliveryState, action: any) => {
  switch (action.type) {
    case actionTypes.ListOfDelivery:
      return {
        ...state,
        deliveryList: action.payload,
        isDeliveryListLoaded: true,
      };
    case actionTypes.IsDeliveryTableRefreshed:
      return {
        ...state,
        isDeliveryTableRefreshed: action.payload,
      };
    case actionTypes.SelectedDelivery:
      return {
        ...state,
        selectedDelivery: action.payload,
      };
    default:
      return state;
  }
};

export const stockTransferReducer = (state = stockTransferState, action: any) => {
  switch (action.type) {
    case transferActionTypes.ListOfStockTransfer:
      return {
        ...state,
        stockTransferList: action.payload,
        isStockTransferListLoaded: true,
      };
    case transferActionTypes.IsStockTransferTableRefreshed:
      return {
        ...state,
        isStockTransferTableRefreshed: action.payload,
      };
    case transferActionTypes.SelectedStockTransfer:
      return {
        ...state,
        selectedStockTransfer: action.payload,
      };
    default:
      return state;
  }
};
