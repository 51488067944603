import oktaConfig from "../../configs/okta";
import * as Okta from "../oktaAuthService";
import { clearSessionStorage } from "./storageService";

export const logError = (error: any) => {
  if (error != undefined) {
    // Token is expired, relogin is required
    if (typeof error === "string" && error.includes("403")) {
      Okta.deleteSession().then(() => {
        clearSessionStorage();
        window.location.href = `${oktaConfig.api.redirectUri}`;
      });
    } else {
      if (error instanceof Error) {
        console.error(error);
      }
    }
  } else {
    // SKIP UNDEFINED OR NULL ERROR OBJECT LOGS
    // Handle the case when error is undefined or null
    // console.error("ERROR: Undefined or null error object.");
    // console.log("ERROR: Undefined or null error object.");
  }
};

export const logWarn = (error: any) => {
  console.warn("WARN", error);
};

export const defaultLog = (error: any) => {
  console.log("LOG:", error);
};
