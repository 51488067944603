import * as React from "react";
import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { drawerItemsMain } from "./OperationsDrawerItemsMain";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";
import ManageOperations from "../../../screens/Operations/ManageOperations";
import PlaceOrderScreen from "../../../screens/Operations/PlaceOrderScreen";
import SingleOperationScreen from "../../../screens/Operations/SingleOperationScreen";
import SalesOperationsManagementScreen from "../../../screens/SalesOperations/SalesOperationsManagementScreen";

const Drawer = createDrawerNavigator();

export default function OperationsDrawerNavigator() {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerStyle: {
          width: 200,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent drawerItems={drawerItemsMain} {...props} />
      )}
    >
      <Drawer.Screen
        options={{
          drawerLabel: "Operations Management",
          title: "Operations Status",
          headerShown: false,
        }}
        name="OperationsManagement"
        component={SalesOperationsManagementScreen}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Manage Operations",
          title: "Order Request",
          headerShown: false,
        }}
        name="ManageOperations"
        component={ManageOperations}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Add Operations",
          title: "Place Order Request",
          headerShown: false,
        }}
        name="PlaceOrder"
        component={PlaceOrderScreen}
        initialParams={{
          mode: "add",
          requestType: "place",
          operation: {},
          titleToDisplay: "Place Order Request",
        }}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Add Operations",
          title: "Reserve Order Request",
          headerShown: false,
        }}
        name="ReserveOrder"
        component={PlaceOrderScreen}
        initialParams={{
          mode: "add",
          requestType: "reserve",
          operation: {},
          titleToDisplay: "Reserve Order Request",
        }}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Add Operations",
          title: "Prepay Order Request",
          headerShown: false,
        }}
        name="PrepayOrder"
        component={PlaceOrderScreen}
        initialParams={{
          mode: "add",
          requestType: "prepay",
          operation: {},
          titleToDisplay: "Prepay Order Request",
        }}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Operations Details",
          title: "Operations Details",
          headerShown: false,
        }}
        name="SingleOperation"
        component={SingleOperationScreen}
        initialParams={{ order: {} }}
      />
    </Drawer.Navigator>
  );
}
