export const drawerItemsMain = [
  {
    key: 'Stocks',
    title: 'Stock',
    routes: [{nav: 'Stocks', routeName: 'Stocks', title: 'Stock'}],
  },
  {
    key: 'Products',
    title: 'Search Product',
    routes: [{nav: 'Products', routeName: 'Products', title: 'Product'}],
  },
  {
    key: "CustomerStatus",
    title: "Customer Status",
    routes: [{ nav: "CustomerStatus", routeName: "CustomerStatus", title: "Customer Status" }],
  },
  // {
  //   key: "CustomerOrder",
  //   title: "Customer Order",
  //   routes: [{ nav: "CustomerOder", routeName: "CustomerOrder", title: "Customer Order" }],
  // },
];
