import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Modal, Text} from 'react-native-paper';

interface Props {
    hideConfirmationModal: ()=>void
    confirmationModalVisible: boolean
    confirmationOption: string
}

const ConfirmationModal: React.FC<Props> = ({hideConfirmationModal, confirmationModalVisible, confirmationOption}) => {

    const draftApprove = () =>{
        return true;
    }

    const draftReject = () =>{
        hideConfirmationModal()
    }

    const reservationApprove = () =>{
        return true;
    }

    const reservationReject = () =>{
        hideConfirmationModal()
    }
  
    return (
        <Modal visible={confirmationModalVisible} onDismiss={hideConfirmationModal} contentContainerStyle={styles.modal}>
            <View style={{flex:1, alignContent:'center', justifyContent:'center'}}>
                {
                    confirmationOption == 'draft' ? 
                    <View>
                        <Text>
                            Would you like to save this as a draft?
                        </Text>
                        <View style={{flexDirection:'row'}}>
                            <View>
                                <TouchableOpacity onPress={draftApprove} style={styles.confirmationButtons}>
                                    <Text style={styles.confirmationBtnsText}>Yes</Text>
                                </TouchableOpacity>
                            </View>
                            <View>
                                <TouchableOpacity onPress={draftReject} style={styles.confirmationButtons}>
                                    <Text style={styles.confirmationBtnsText}>No</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    :
                    <View>
                        <Text>
                            Are you sure you are ready to submit this order request?
                        </Text>
                        <View style={{flexDirection:'row'}}>
                            <View>
                                <TouchableOpacity onPress={reservationApprove} style={styles.confirmationButtons}>
                                    <Text style={styles.confirmationBtnsText}>Yes</Text>
                                </TouchableOpacity>
                            </View>
                            <View>
                                <TouchableOpacity onPress={reservationReject} style={styles.confirmationButtons}>
                                    <Text style={styles.confirmationBtnsText}>No</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                }
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal:{
        flex:1,
        backgroundColor:'#fff',
        top:0,
        height:'100%',
        width:'100%'
    },
    confirmationButtons:{
        backgroundColor:'#D9D9D9',
        paddingHorizontal:20, 
        paddingVertical:10,
        borderRadius:25,
    },
    confirmationBtnsText:{
        color:'#6C6C6C'
    }
})

export default ConfirmationModal;