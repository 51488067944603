import { actionTypesDelivery as actionTypes, actionTypesStockTransfer as transferActionTypes } from "../constants/actionTypesDelivery";
import { deliveryModel } from "../../models/reportsModel";

const setDeliveryList = (data: Array<deliveryModel>) => {
  return {
    type: actionTypes.ListOfDelivery,
    payload: data,
  };
};

const setIsDeliveryTableRefreshed = (data: boolean) => {
  return {
    type: actionTypes.IsDeliveryTableRefreshed,
    payload: data,
  };
};

const setSelectedDelivery = (data: deliveryModel) => {
  return {
    type: transferActionTypes.SelectedStockTransfer,
    payload: data,
  };
};

const setStockTransferList = (data: Array<deliveryModel>) => {
  return {
    type: transferActionTypes.ListOfStockTransfer,
    payload: data,
  };
};

const setIsStockTransferTableRefreshed = (data: boolean) => {
  return {
    type: transferActionTypes.IsStockTransferTableRefreshed,
    payload: data,
  };
};

const setSelectedStockTransfer = (data: deliveryModel) => {
  return {
    type: transferActionTypes.SelectedStockTransfer,
    payload: data,
  };
};

export default {
  setDeliveryList,
  setIsDeliveryTableRefreshed,
  setSelectedDelivery,
  setStockTransferList,
  setIsStockTransferTableRefreshed,
  setSelectedStockTransfer
};
