export const headers = {
  State: {
    name: "State",
    width: 0.6,
    textAlign: "left",
  },
  Warehouse: {
    name: "Warehouse",
    width: 0.8,
    textAlign: "left",
  },
  CustomerName: {
    name: "Customer Name",
    width: 2.0,
    textAlign: "left",
  },
  DocNum: {
    name: "Document #",
    width: 0.8,
    textAlign: "left",
  },
  DocType: {
    name: "Document",
    width: 1.0,
    textAlign: "left",
  },
  OrderStatus: {
    name: "Order Status",
    width: 0.8,
    textAlign: "left",
  },
  RequestDate: {
    name: "Request Date",
    width: 1.0,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ShippingType: {
    name: "Shipping Type",
    width: 1.0,
    textAlign: "left",
  },
  ShipToCode: {
    name: "Shipping Code",
    width: 1.3,
    textAlign: "left",
  },
  SalesPerson: {
    name: "Sales",
    width: 1.1,
    textAlign: "left",
  },
  ProcessBy: {
    name: "Operator",
    width: 1.0,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdHeaders = {
  Warehouse: {
    name: "Warehouse",
    width: 0.8,
    isSort: true,
    textAlign: "left",
  },
  CustomerCode: {
    name: "Customer Code",
    width: 1.0,
    textAlign: "left",
  },
  CustomerName: {
    name: "Customer Name",
    width: 2.5,
    textAlign: "center",
  },
  ShippingType: {
    name: "Shipping Type",
    width: 1.0,
    textAlign: "center",
  },
  ShipToCode: {
    name: "Shipping Code",
    width: 1.2,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smHeaders = {
  Warehouse: {
    name: "Warehouse",
    width: 0.9,
    isSort: true,
    textAlign: "left",
  },
  CustomerName: {
    name: "Customer Name",
    width: 1.5,
    textAlign: "left",
  },
  OrderStatus: {
    name: "Order Status",
    width: 0.9,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};
