import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  View,
  Image,
  Picker,
} from "react-native";
import { SafeAreaView, SafeAreaProvider } from "react-native-safe-area-context";
import { FontAwesome } from "@expo/vector-icons";
import { checkS3Object, getS3SignUrl } from "../../../services/s3BucketService";
import { Controller } from "react-hook-form";
import { ActivityIndicator, DataTable } from "react-native-paper";
import OrderScreenProductModalQuantity from "./OrderScreenProductModalQuantity";

interface OrderScreenSecondProductModalProps {
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  hideProductList: any;
  showProductList: any;
  handleSearch: (text: string) => void;
  setSearchText: any;
  searchText: string;
  isFilteredProductListVisible: boolean;
  filteredData: any[];
  setFilteredData: any;
  renderItem: any;
  mode: string | null;
  form: any;
  existingProductData?: any;
  totalsCalculation: any;
  selectedProductIndex?: number | null;
  removeProduct: any;
}

const OrderScreenSecondProductModal: React.FC<
  OrderScreenSecondProductModalProps
> = ({
  modalVisible,
  setModalVisible,
  hideProductList,
  showProductList,
  handleSearch,
  searchText,
  setSearchText,
  isFilteredProductListVisible,
  filteredData,
  setFilteredData,
  renderItem,
  mode,
  form,
  existingProductData,
  totalsCalculation,
  selectedProductIndex,
  removeProduct,
}) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const [selectedProduct, setSelectedProduct] = useState<any>(
    existingProductData || null
  );
  const [productLoader, setProductLoader] = useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const renderEnhancedProduct = ({ item }) => (
    <TouchableOpacity style={styles.listItem} onPress={() => addProduct(item)}>
      <View style={styles.listItemDetails}>
        <Text style={styles.listItemText}>{item.name}</Text>
        <Text style={styles.listItemSubText}>{item.name}</Text>
      </View>
      <FontAwesome name="angle-right" size={18} style={styles.listItemIcon} />
    </TouchableOpacity>
  );

  const [validationMessage, setValidationMesage] = useState<string | null>(
    null
  );

  const validSignedURL = async (itemId: string) => {
    const keyTypeJpg = `${itemId}.jpg`;
    const keyTypePng = `${itemId}.png`;
    const keys = [
      keyTypePng,
      keyTypePng.toUpperCase(),
      keyTypeJpg,
      keyTypeJpg.toUpperCase(),
    ];
    // `no-image.png`]
    const bucket = "multisteps-label";

    let flag = false;
    keys.forEach((key) => {
      checkS3Object(bucket, key).then((result) => {
        if (result === "true" || result === true) {
          getS3SignUrl(bucket, key, "getObject", "image/*").then((data) => {
            setSelectedImage(data?.signedUrl);
            setLoading(false);
            flag = true;
            return data?.signedUrl;
          });
        }
      });
    });

    if (!flag) {
      // set default image to no-image.png
      getS3SignUrl(bucket, "no-image.png", "getObject", "image/*").then(
        (data) => {
          setSelectedImage(data?.signedUrl);
          setLoading(false);
          return data?.signedUrl;
        }
      );
    }
  };

  const addProduct = (product: any) => {
    setProductLoader(true);

    // Get existing products
    const oldData = getValues("products") || [];
    const isFirstProduct = oldData.length === 0;

    // Determine default UoM and quantity based on conditions
    const defaultUom = isFirstProduct ? "Boxes" : product.uom || "Pieces";
    const defaultQuantity =
      defaultUom === "Boxes"
        ? Math.floor(product.OnHand / product.PerCtns) || 1 // Ensure quantity is an integer
        : product.OnHand || 1;

    const newProduct = {
      ...product,
      quantity: product.quantity || defaultQuantity, // Use default quantity
      price: product.price || 1, // Default price if not provided
      uom: defaultUom, // Default or user-selected UoM
      totalPrice: 0,
      totalBoxes: 0,
      totalPieces: 0,
    };

    setSelectedProduct(newProduct);

    // Reset search fields and trigger recalculation
    setSearchText("");
    setFilteredData([]);
    calculateTotals(newProduct);

    setProductLoader(false);
  };

  const calculateTotals = (selectedProduct: any) => {
    let totalPrice = 0;
    let totalBoxes = 0;
    let totalPieces = 0;

    if (selectedProduct) {
      const productPrice = selectedProduct.price
        ? parseFloat(selectedProduct.price)
        : 1;
      const productQuantity = selectedProduct.quantity
        ? parseFloat(selectedProduct.quantity)
        : 0;

      if (selectedProduct.uom === "Boxes") {
        // Calculate total quantity for Boxes
        const qtyInPieces = productQuantity * selectedProduct.PerCtns;
        totalBoxes += productQuantity;
        totalPieces += qtyInPieces; // Convert boxes to pieces and add to total
        totalPrice += productPrice * productQuantity;
      } else if (selectedProduct.uom === "Pieces") {
        // Calculate total quantity for Pieces
        totalPieces += productQuantity;
        totalPrice += productPrice * productQuantity;
      }

      // Ensure totals are rounded to 2 decimal places
      totalPrice = parseFloat((Math.round(totalPrice * 100) / 100).toFixed(2));
      totalBoxes = parseFloat((Math.round(totalBoxes * 100) / 100).toFixed(2));
      totalPieces = parseFloat(
        (Math.round(totalPieces * 100) / 100).toFixed(2)
      );

      const updatedProductData = {
        ...selectedProduct,
        totalPrice: parseFloat((productPrice * productQuantity).toFixed(2)),
        totalBoxes: selectedProduct.uom === "Boxes" ? productQuantity : 0,
        totalPieces:
          selectedProduct.uom === "Boxes"
            ? parseFloat((productQuantity * selectedProduct.PerCtns).toFixed(2))
            : parseFloat(productQuantity.toFixed(2)),
      };

      setSelectedProduct(updatedProductData);
    }
  };

  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    let isMounted = true;

    // Only fetch data if selectedProduct exists and hasn't been fetched yet
    if (isMounted && selectedProduct && !isDataFetched) {
      setLoading(true);
      validSignedURL((selectedProduct?.ItemCode as string) || "")
        .then(() => {
          if (isMounted) setIsDataFetched(true);
        })
        .catch((err) => console.error("API Error:", err))
        .finally(() => {
          if (isMounted) setLoading(false);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [selectedProduct, isDataFetched]);

  const CustomModalComponent = () => {
    return (
      <View style={[styles.container, { width: "100%", height: "100%" }]}>
        <Image
          source={{ uri: selectedImage }}
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            resizeMode: "contain",
          }}
          onError={(error) => {
            console.error("Image Load Error:", error.nativeEvent.error);
          }}
        />
      </View>
    );
  };

  const handleAddItemPress = () => {
    const products = getValues("products");
    setValue("products", [...products, selectedProduct]);
    setSearchText("");
    setFilteredData([]);
    setModalVisible(false);
    totalsCalculation();
  };

  const handleUpdateItemPress = (index: number) => {
    const products = getValues("products");

    // Ensure index is valid
    if (index < 0 || index >= products.length) {
      console.error("Invalid index provided");
      return;
    }

    const updatedProducts = [...products]; // Create a shallow copy of the array
    updatedProducts[index] = selectedProduct; // Replace the old product at the given index

    setValue("products", updatedProducts); // Update the products with the modified array
    setSearchText("");
    setFilteredData([]);
    setModalVisible(false);
    totalsCalculation(); // Recalculate totals based on updated data
  };

  const handleRemoveProduct = () => {
    if (selectedProductIndex != null) {
      setSearchText("");
      setFilteredData([]);
      setSelectedProduct(null);
      setSelectedImage("");
      setIsDataFetched(false);
      setModalVisible(false);
      removeProduct(selectedProductIndex);
    } else {
      setSearchText("");
      setFilteredData([]);
      setSelectedProduct(null);
      setSelectedImage("");
      setIsDataFetched(false);
    }
  };

  return (
    <SafeAreaProvider>
      <SafeAreaView style={styles.safeArea}>
        <Modal
          animationType="slide"
          transparent={false}
          visible={modalVisible}
          onRequestClose={() => setModalVisible(false)}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.headerText}>Add Products</Text>
            {/* Search for product */}
            {!selectedProduct ? (
              <>
                <View style={styles.searchSection}>
                  <View style={styles.searchInputContainer}>
                    <TextInput
                      style={styles.searchInput}
                      placeholder="Search for products, categories, or brands..."
                      onChangeText={handleSearch}
                      value={searchText}
                      onBlur={hideProductList}
                      onFocus={showProductList}
                      placeholderTextColor="#aaa"
                    />
                    <TouchableOpacity style={styles.searchButton}>
                      <FontAwesome name="search" size={18} color="#fff" />
                    </TouchableOpacity>
                  </View>
                  {filteredData.length > 0 && isFilteredProductListVisible && (
                    <FlatList
                      data={filteredData}
                      renderItem={renderEnhancedProduct}
                      keyExtractor={(item) => item}
                      style={styles.list}
                    />
                  )}
                  <TouchableOpacity
                    style={styles.backButton}
                    onPress={() => setModalVisible(false)}
                  >
                    <Text style={styles.backButtonText}>Back</Text>
                  </TouchableOpacity>
                </View>
              </>
            ) : (
              <>
                <View style={styles.formSection}>
                  {/* {productLoader && (
                    <View style={styles.activityLoaderView}>
                      <ActivityIndicator color="lightgrey" />
                    </View>
                  )} */}
                  <Text style={styles.formHeaders}>Product Info</Text>
                  <View style={styles.productInfoContainer}>
                    <View style={styles.productHeader}>
                      <Text style={styles.productName}>
                        {selectedProduct.name}
                      </Text>
                      <Text style={styles.productCode}>
                        | {selectedProduct.ItemCode}
                      </Text>
                      <TouchableOpacity style={styles.deleteButton}>
                        <FontAwesome
                          name="trash"
                          size={20}
                          color="#333"
                          onPress={handleRemoveProduct}
                        />
                      </TouchableOpacity>
                    </View>

                    <View style={styles.productDetails}>
                      {/* Order Section */}
                      <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>Order</Text>
                        <OrderScreenProductModalQuantity
                          styles={styles}
                          selectedProduct={selectedProduct}
                          validationMessage={validationMessage}
                          setValidationMessage={setValidationMesage}
                          control={control}
                          calculateTotals={calculateTotals}
                        />
                      </View>

                      {/* UoM Section */}
                      <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>UoM</Text>
                        <Controller
                          control={control}
                          name={`product.uom`}
                          defaultValue={selectedProduct.uom || "Pieces"}
                          render={({ field: { onChange, value } }) => (
                            <Picker
                              selectedValue={value}
                              style={styles.quantityPriceInputsNew}
                              onValueChange={(val) => {
                                onChange(val);

                                const updatedProduct = {
                                  ...selectedProduct,
                                  uom: val,
                                };

                                calculateTotals(updatedProduct);
                              }}
                            >
                              <Picker.Item label="Boxes" value="Boxes" />
                              <Picker.Item label="Pieces" value="Pieces" />
                            </Picker>
                          )}
                        />
                      </View>

                      {/* Price Section */}
                      {getValues("orderType") === "contract_order" ? (
                        <View style={styles.detailItem}>
                          <Text style={styles.detailLabel}>Price</Text>
                          <Text style={styles.inventoryQuantity}>Contract</Text>
                        </View>
                      ) : (
                        <View style={styles.detailItem}>
                          <Text style={styles.detailLabel}>Price</Text>
                          <Controller
                            control={control}
                            name={`products.price`}
                            defaultValue={
                              selectedProduct.price?.toFixed(2) || "1.00"
                            } // Ensure default value has 2 decimal places
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                style={styles.quantityPriceInputsPrice}
                                placeholder="Enter Price"
                                value={value?.toString() || ""}
                                onChangeText={(text) => {
                                  if (text === "") {
                                    // Allow empty input without resetting to 1
                                    onChange("");
                                    return;
                                  }

                                  // Use regex to validate and format as a decimal with up to 2 decimal places
                                  const formattedText = text.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ); // Remove non-numeric and non-dot characters

                                  // Check if there's more than one dot and prevent additional dots
                                  const dotCount = (
                                    formattedText.match(/\./g) || []
                                  ).length;
                                  if (dotCount > 1) return;

                                  // Limit to 6 digits before decimal and 2 after
                                  const match = formattedText.match(
                                    /^(\d{0,6})(\.\d{0,2})?/
                                  );
                                  const updatedPrice = match
                                    ? match[0]
                                    : formattedText;

                                  onChange(updatedPrice);

                                  // Update the selectedProduct object with the new price
                                  const updatedProduct = {
                                    ...selectedProduct,
                                    price: parseFloat(updatedPrice) || 0, // Convert to float for calculations
                                  };

                                  // Call calculateTotals with the updated product object
                                  calculateTotals(updatedProduct);
                                }}
                                keyboardType="numeric"
                              />
                            )}
                          />
                        </View>
                      )}
                    </View>
                    {/* Inventory UoM */}
                    <Text style={styles.inventoryText}>Inventory UoM</Text>
                    <Controller
                      control={control}
                      name={`product.inventory`}
                      defaultValue={
                        selectedProduct.uom === "Boxes"
                          ? (
                              selectedProduct.OnHand / selectedProduct.PerCtns
                            ).toFixed(2)
                          : selectedProduct.OnHand.toString()
                      }
                      render={({ field: { value } }) => (
                        <TextInput
                          style={[styles.inventoryQuantity]}
                          placeholder="Inventory"
                          value={
                            selectedProduct.uom === "Boxes"
                              ? (
                                  selectedProduct.OnHand /
                                  selectedProduct.PerCtns
                                ).toFixed(2)
                              : selectedProduct.OnHand.toString()
                          }
                          keyboardType="numeric"
                          editable={false}
                        />
                      )}
                    />
                    {validationMessage && (
                      <Text style={styles.errorStyle}>{validationMessage}</Text>
                    )}
                  </View>
                </View>
                {/* Totals Row */}
                <DataTable.Row
                  style={{
                    paddingVertical: 12,
                    backgroundColor: "#F9F9F9", // Light gray for alternating row background
                    borderBottomWidth: 1,
                    borderBottomColor: "#E0E0E0", // Divider for clean separation
                    flexDirection: "row", // Flex layout
                    justifyContent: "space-between", // Distribute the items evenly
                    alignItems: "center", // Align items centrally
                  }}
                >
                  {/* Total Label */}
                  <View
                    style={{
                      flex: 1.2,
                      justifyContent: "center",
                      paddingHorizontal: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "#007BFF", // Blue to stand out
                        fontSize: 14, // Adjusted for smaller devices
                        textAlign: "left",
                      }}
                    >
                      Total
                    </Text>
                  </View>

                  {/* Boxes Count */}
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingHorizontal: 5,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#555", // Softer color for subtext
                        fontWeight: "600", // Semi-bold to distinguish from main total
                        textAlign: "center",
                      }}
                    >
                      📦 Boxes: {selectedProduct.totalBoxes}
                    </Text>
                  </View>

                  {/* Pieces Count */}
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingHorizontal: 5,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#555", // Softer color for subtext
                        fontWeight: "600", // Semi-bold for clarity
                        textAlign: "center",
                      }}
                    >
                      🧩 Pieces: {selectedProduct.totalPieces}
                    </Text>
                  </View>

                  {/* Total Price */}
                  <View
                    style={{
                      flex: 1.2,
                      justifyContent: "center",
                      paddingHorizontal: 5,
                      alignItems: "flex-end", // Right-align the total price
                    }}
                  >
                    {getValues("orderType") === "contract_order" ? (
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: "#28a745", // Green to stand out for price
                          fontSize: 16, // Larger for emphasis
                          textAlign: "right", // Align to the right for a clean finish
                        }}
                      >
                        Contract
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: "#28a745", // Green to stand out for price
                          fontSize: 16, // Larger for emphasis
                          textAlign: "right", // Align to the right for a clean finish
                        }}
                      >
                        💲{selectedProduct.totalPrice}
                      </Text>
                    )}
                  </View>
                </DataTable.Row>

                <Text style={styles.formHeaders}>Label</Text>
                <CustomModalComponent />
                {selectedProductIndex === null ? (
                  <TouchableOpacity
                    style={[
                      styles.backButton,
                      selectedProduct?.quantity <= 0 ? styles.backButton : {},
                    ]}
                    onPress={handleAddItemPress}
                    disabled={selectedProduct?.quantity <= 0}
                  >
                    {selectedProduct?.quantity <= 0 ? (
                      <Text style={styles.tooltipMessage}>
                        Quantity must be greater than 0 to add an item.
                      </Text>
                    ) : (
                      <Text style={styles.backButtonText}>Add Item</Text>
                    )}
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={[
                      styles.backButton,
                      selectedProduct?.quantity <= 0 ? styles.backButton : {},
                    ]}
                    onPress={() =>
                      handleUpdateItemPress(Number(selectedProductIndex))
                    }
                    disabled={selectedProduct?.quantity <= 0}
                  >
                    {selectedProduct?.quantity <= 0 ? (
                      <Text style={styles.tooltipMessage}>
                        Quantity must be greater than 0 to add an item.
                      </Text>
                    ) : (
                      <Text style={styles.backButtonText}>Update Item</Text>
                    )}
                  </TouchableOpacity>
                )}
              </>
            )}
          </View>
        </Modal>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  safeArea: {
    flex: 1,
    backgroundColor: "white",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
    color: "black",
  },
  formSection: {
    marginBottom: 20,
  },
  formHeaders: {
    color: "#B5B5B5",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 15,
  },
  searchSection: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#f8f9fa",
  },
  searchInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 30,
    backgroundColor: "#fff",
    paddingHorizontal: 16,
    shadowColor: "#000",
    shadowOpacity: 0.08,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    elevation: 2,
  },
  searchInput: {
    flex: 1,
    height: 48,
    fontSize: 16,
    color: "#333",
    paddingHorizontal: 8,
  },
  searchButton: {
    backgroundColor: "#0078d4",
    borderRadius: 25,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  list: {
    backgroundColor: "#fff",
    borderRadius: 8,
    marginTop: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 2 },
    maxHeight: 300, // Limit list height for better usability
    overflow: "hidden",
  },
  listItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 14,
    backgroundColor: "#f9f9f9",
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  listItemDetails: {
    flex: 1,
    flexDirection: "column",
  },
  listItemText: {
    fontSize: 16,
    color: "#444",
    fontWeight: "500",
  },
  listItemSubText: {
    fontSize: 14,
    color: "#777",
  },
  listItemIcon: {
    color: "#0078d4",
  },
  backButton: {
    backgroundColor: "#555",
    padding: 14,
    borderRadius: 6,
    alignItems: "center",
    marginTop: 16,
  },
  backButtonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "600",
  },
  tooltipMessage: {
    color: "red",
    fontSize: 16,
    fontWeight: "bold",
  },
  productInfoContainer: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 10,
    padding: 15,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    elevation: 3,
  },
  productHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  productName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
  },
  productCode: {
    fontSize: 16,
    color: "#666",
  },
  deleteButton: {
    marginLeft: "auto",
  },
  productDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  detailItem: {
    flex: 1,
    alignItems: "center",
  },
  detailLabel: {
    fontSize: 14,
    color: "#666",
    marginBottom: 5,
  },
  detailValue: {
    fontSize: 16,
    fontWeight: "500",
    color: "#333",
  },
  counter: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    overflow: "hidden",
  },
  counterButton: {
    backgroundColor: "#f0f0f0",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  counterButtonText: {
    fontSize: 16,
    color: "#333",
  },
  counterInput: {
    width: 50,
    textAlign: "center",
    fontSize: 16,
    color: "#333",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#ddd",
  },
  priceInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 10,
    textAlign: "center",
    fontSize: 16,
    color: "#333",
    width: 80,
  },
  inventoryText: {
    fontSize: 14,
    color: "#888",
    textAlign: "center",
    marginTop: 10,
  },
  inventoryValue: {
    fontSize: 16,
    fontWeight: "500",
    color: "#333",
    textAlign: "center",
  },
  quantityPriceInputsNew: {
    padding: 4,
    textAlign: "center",
    width: "100%",
    maxWidth: 80,
    height: "40px",
  },
  quantityPriceInputsPrice: {
    borderWidth: 1,
    borderColor: "#79747E",
    padding: 4,
    textAlign: "center",
    borderRadius: 3,
    width: "100%",
    maxWidth: 80,
    height: "40px",
  },
  inventoryQuantity: {
    borderWidth: 1,
    borderColor: "#79747E",
    padding: 4,
    textAlign: "center",
    borderRadius: 3,
    height: "30px",
    backgroundColor: "#f7f7f7",
  },
  activityLoaderView: {
    position: "absolute",
    zIndex: 999,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fffefe7a",
  },
  errorStyle: {
    marginBottom: 10,
    fontSize: 13,
    color: "red",
  },
});

export default OrderScreenSecondProductModal;
