import React, { useState, useEffect, useContext } from "react";
import { TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { Text, View } from "../../../components/shared/Themed";
import { getOktaBypassUsers } from "../../../services/usersService";
import {
  headers,
  MediumDeviceHeaders,
  SmallDeviceHeaders,
} from "./createUserHeaders";
import Modal from "../../../components/custom/Modal";
import CreateOktaBypassUserAccountForm from "./CreateOktaBypassUserAccountForm";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { NestedDataTable } from "../../../components/custom/DataTable/NestedDataTableWithPagination2";

export default function CreateUserSettingsScreen({ navigation }: any) {
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  // const { navigation } = useSelector((state: any) => state.configurations);
  const [users, setUsers] = useState([]);
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );
  const fetchUsers = () => {
    getOktaBypassUsers().then((data) => {
      setUsers(data.users);
    });
  };
  const { isLargeDevice, isMediumDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );

  const onHandleSortColumn = (columnKey: string) => {};

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchUsers();

      setTableHeaders(
        isLargeDevice
          ? headers
          : isMediumDevice
          ? MediumDeviceHeaders
          : SmallDeviceHeaders
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const navigateToOrder = (userId: string, mode: string) => {
    navigation.navigate("BypassUserDetails", { mode: mode, userId: userId });
  };

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.headerContainer}>
        <Text style={styles.title}>User List</Text>
        <TouchableOpacity style={styles.addButton}>
          <Text
            style={styles.buttonText}
            onPress={() =>
              navigation.navigate("CreateUser", { screen: "AddUserScreen" })
            }
          >
            Add User
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ width: "100%" }}>
        <NestedDataTable
          itemsPerPage={15}
          isNestedDataLoaded={isLoading}
          nestedHeader={tableHeaders}
          nestedRows={users}
          key="rowKeyValue"
          userActions={navigateToOrder}
        />
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={CreateOktaBypassUserAccountForm}
      />
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 20,
    marginVertical: 10,
  },
  container: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  addButton: {
    backgroundColor: "#6495ED",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  userCard: {
    backgroundColor: "#f9f9f9",
    padding: 15,
    marginVertical: 10,
    borderRadius: 10,
    width: "90%",
  },
  userName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  userRole: {
    fontSize: 14,
    color: "grey",
  },
  modalContainer: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    width: "90%",
    alignSelf: "center",
  },
  modalHeader: {
    marginBottom: 20,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  modalContent: {
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    marginVertical: 10,
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
  input: {
    width: "100%",
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginBottom: 15,
  },
});
