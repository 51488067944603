import React, { useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  TextInput,
  View,
  Modal,
  Text,
  Alert,
} from "react-native";
import { Badge, Button } from "react-native-paper";
import { renameLabel } from "../../../utils/common";
import { getAllFilteredOperations } from "../../../services/operationService";

interface Props {
  itemsList: Array<string>;
  filterCategory: string;
  setFilterCategory: (category: string) => void;
  tableHeaders: any; // Add tableHeaders prop if it's not global
}

export const OperationBadgeHorizontalList: React.FC<Props> = ({
  itemsList,
  filterCategory,
  setFilterCategory,
  tableHeaders,
}) => {
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: "",
    to: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDateChange = (key: "from" | "to", value: string) => {
    setDateRange((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const applyFilter = (filter: string) => {
    setFilterCategory(filter);
  };

  const validateAndApply = () => {
    if (!dateRange.from || !dateRange.to) {
      setErrorMessage("Both 'From' and 'To' dates are required.");
      return;
    }

    handleCustomDateApply(dateRange);
    setIsModalVisible(false);
  };

  const handleCustomDateApply = (dateRange: any) => {
    if (dateRange.from && dateRange.to) {
      const customFilter = `From: ${dateRange.from} To: ${dateRange.to}`;
      applyFilter(customFilter);
    }
    setIsModalVisible(false);
    let filters: { status?: string; dateRange?: { from: string; to: string } } =
      {};
    filters = {
      dateRange: dateRange,
    };
    getAllFilteredOperations(tableHeaders, filters);
  };

  const handleBadgeClick = (item: string) => {
    const today = new Date();
    let filters: { status?: string; dateRange?: { from: string; to: string } } =
      {};

    if (item === "Custom Date Range") {
      setIsModalVisible(true); // Open modal for custom date selection
      return;
    } else if (item === "Last 3 months") {
      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      );
      setDateRange({
        from: threeMonthsAgo.toISOString().split("T")[0],
        to: today.toISOString().split("T")[0],
      });
      filters = {
        dateRange: {
          from: threeMonthsAgo.toISOString().split("T")[0],
          to: today.toISOString().split("T")[0],
        },
      };
    } else {
      filters = { status: item };
    }

    applyFilter(item); // Update the selected badge visually
    getAllFilteredOperations(tableHeaders, filters); // Pass filters for API call
  };

  return (
    <View style={styles.container}>
      {/* Badge Row */}
      <View style={styles.badgesRow}>
        {itemsList.map((item) => (
          <TouchableOpacity key={item} onPress={() => handleBadgeClick(item)}>
            <Badge
              style={[
                styles.badgeStyle,
                filterCategory === item
                  ? styles.activeBadge
                  : styles.inActiveBadge,
              ]}
            >
              {renameLabel(item)}
            </Badge>
          </TouchableOpacity>
        ))}

        {/* Custom Date Range Badge */}
        <TouchableOpacity onPress={() => handleBadgeClick("Custom Date Range")}>
          <Badge
            style={[
              styles.badgeStyle,
              filterCategory.includes("From")
                ? styles.activeBadge
                : styles.inActiveBadge,
            ]}
          >
            {filterCategory.includes("From")
              ? filterCategory
              : "Custom Date Range"}
          </Badge>
        </TouchableOpacity>
      </View>

      {/* Custom Date Range Modal */}
      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setIsModalVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Select Custom Date Range</Text>

            {/* From Date Input */}
            <TextInput
              style={styles.dateInput}
              placeholder="Select From Date"
              value={dateRange.from}
              onChangeText={(value) => handleDateChange("from", value)}
              onFocus={(e) => (e.currentTarget.type = "date")} // Show date picker on focus
            />

            {/* To Date Input */}
            <TextInput
              style={styles.dateInput}
              placeholder="Select To Date"
              value={dateRange.to}
              onChangeText={(value) => handleDateChange("to", value)}
              onFocus={(e) => (e.currentTarget.type = "date")} // Show date picker on focus
            />

            {/* Error Message */}
            {errorMessage ? (
              <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}

            {/* Modal Buttons */}
            <View style={styles.modalActions}>
              <Button
                mode="outlined"
                onPress={() => setIsModalVisible(false)}
                style={styles.modalButton}
              >
                Cancel
              </Button>
              <Button
                mode="contained"
                onPress={validateAndApply}
                style={styles.modalButton}
              >
                Apply
              </Button>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
  },
  badgesRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  badgeStyle: {
    marginHorizontal: 4,
    fontSize: 12,
    paddingHorizontal: 10,
  },
  inActiveBadge: {
    color: "black",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  activeBadge: {
    color: "white",
    backgroundColor: "rgb(47, 149, 220)",
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    width: "90%",
    borderRadius: 8,
    padding: 16,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
  },
  dateInput: {
    width: "100%",
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginBottom: 12,
    fontSize: 14,
  },
  modalActions: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  modalButton: {
    flex: 1,
    marginHorizontal: 8,
  },
  errorMessage: {
    color: "red",
    marginBottom: 10,
    textAlign: "center",
  },
});
