import { MAPI_URL } from "@env";

export default {
  api: {
    domain:
      process.env.NODE_ENV === "development"
        ? // ? "https://testmapi.msteps.net" //"http://localhost:1982/"
          "http://localhost:1982/"
        : MAPI_URL,
  },
};
