import { ordersCollectionModel } from "../../models/ordersModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  operationsList: Array<ordersCollectionModel>;
  isOperationsListLoaded: boolean;
  selectedContainer: ordersCollectionModel;
  isOperationsTableRefreshed: boolean;
  allowedRolesForOperations: Array<string>;
  shippingMethodList: Array<Record<string, string>>;
  shipToList: Array<Record<string, string>>;
  orderTypeList: Array<Record<string, string>>;
  locationsList: Array<Record<string, string>>;
}

export const operationsState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  operationsList: [],
  isOperationsListLoaded: false,
  selectedContainer: {} as unknown as ordersCollectionModel,
  isOperationsTableRefreshed: false,

  //Constants
  allowedRolesForOperations: ["OWNER", "ADMIN", "MANAGER", "OPERATION"],
  shippingMethodList: [
    {
      key: "",
      value: "--",
    },
    {
      key: "pick_up",
      value: "Pick Up",
    },
    {
      key: "local_delivery",
      value: "Local Delivery",
    },
    {
      key: "customer_pay",
      value: "Customer Pay",
    },
    {
      key: "free_shipping",
      value: "Free Shipping",
    },
    {
      key: "request_for_freight_quote",
      value: "Request For Freight Quote",
    },
    {
      key: "our_truck",
      value: "Our Truck (QLD Only)",
    },
    {
      key: "direct_ship",
      value: "Direct Ship (FCL Only)",
    },
  ],
  shipToList: [
    {
      key: "",
      value: "--",
    },
    {
      key: "melbourne_berries",
      value: "Melbourne Berries",
    },
    {
      key: "nsw_berries",
      value: "NSW Berries",
    },
    {
      key: "perth_berries",
      value: "Perth Berries",
    },
    {
      key: "queensland_berries",
      value: "Queensland Berries",
    },
    {
      key: "tasmania_berries",
      value: "Tasmania Berries",
    },
  ],
  orderTypeList: [
    {
      key: "new_order",
      value: "New Order",
    },
    {
      key: "contract_order",
      value: "Contract Order",
    },
    {
      key: "dispatch_request",
      value: "Dispatch Request",
    },
    {
      key: "stock_reservation_request",
      value: "Stock Reservation Request",
    },
    {
      key: "buffer_stock_request",
      value: "Buffer Stock Request",
    },
  ],
  locationsList: [
    {
      key: "",
      value: "--",
    },
    {
      key: "NSW",
      value: "NSW",
    },
    {
      key: "Perth",
      value: "Perth",
    },
    {
      key: "Queensland",
      value: "Queensland",
    },
  ],
};
