export const headers = {
  email: {
    name: "Email",
    width: 1.7,
    textAlign: "left",
  },
  zone_info: {
    name: "Zone",
    width: 1,
    textAlign: "left",
  },
  display_name: {
    name: "Display Name",
    width: 1.7,
    textAlign: "left",
  },
  role: {
    name: "Role",
    width: 1,
    textAlign: "left",
  },
  "": { name: "", width: 0.3, textAlign: "" },
  actions: {
    name: "Actions",
    width: 0.4,
    textAlign: "center",
    justifyContent: "center",
  },
};

export const MediumDeviceHeaders = {
  email: {
    name: "Email",
    width: 1.7,
    textAlign: "left",
  },
  display_name: {
    name: "Display Name",
    width: 1,
    textAlign: "left",
  },
  "": { name: "", width: 0.3, textAlign: "" },
  actions: {
    name: "Actions",
    width: 0.4,
    textAlign: "center",
    justifyContent: "center",
  },
};

export const SmallDeviceHeaders = {
  email: {
    name: "Email",
    width: 1.0,
    textAlign: "left",
  },
  actions: {
    name: "Actions",
    width: 0.4,
    textAlign: "center",
    justifyContent: "center",
  },
};
