import React, { useContext } from "react";
import { Avatar, Card, Divider } from "react-native-paper";
import { Text, View } from "../../shared/Themed";
import { StyleSheet } from "react-native";
import { Image } from "react-native";
import Spinner from "../../shared/Spinner";

import DeviceOrientationContext from "../../context/DeviceOrientationContext";
import { ReadOnlyProps } from "../../../utils/templates";

interface Props {
  childern: React.ReactNode | JSX.Element;
  title: string;
  subTitle?: string;
  icon?: string;
  loading?: boolean;
  uri?: string;
  cardContainerHeight?: string;
}

export const ModalContent: React.FC<ReadOnlyProps<Props>> = (
  props: ReadOnlyProps<Props>
) => {
  const { isMediumDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );

  const leftContent = () => {
    return props.icon !== "" ? (
      <Avatar.Icon size={32} icon={props.icon || ""} />
    ) : (
      ""
    );
  };

  return (
    <View
      style={[
        styles.cardViewContainer,
        {
          minWidth: isSmallDevice ? "45%" : isMediumDevice ? "30%" : "24%",
          minHeight: isSmallDevice ? "75%" : "80%",
          maxHeight: isSmallDevice ? "94%" : "100%",
          overflow: "scroll",
        },
      ]}
    >
      <Card mode="outlined" style={[styles.cardContainer, , { height: props.cardContainerHeight ? props.cardContainerHeight : "100%" }]}>
        <Card.Title
          style={styles.cardTitle}
          title={props.title}
          subtitle={
            <Text style={{ flex: 1, textWrap: "wrap" }}>
              {props.subTitle || ""}
            </Text>
          }
          left={leftContent}
          leftStyle={{
            display: props.icon === undefined ? "none" : "flex",
            padding: 0,
            marginRight: 0,
          }}
        />
        <Divider />
        {props.uri !== undefined ? (
          !props.loading ? (
            <>
              <Image style={styles.cardImage} source={{ uri: props.uri }} />
              <Divider />
            </>
          ) : (
            <>
              <View style={styles.spinnerContainer}>
                <Spinner
                  size="small"
                  color="red"
                  styles={{ flexDirection: "row" }}
                />
              </View>
              <Divider />
            </>
          )
        ) : (
          <View></View>
        )}

        <Card.Content style={styles.cardContent}>{props.childern}</Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  cardViewContainer: {
    flexDirection: "row",
    width: 340,
    backgroundColor: "white",
  },
  cardContainer: {
    width: "100%",
    // height: "60%",
    borderColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  cardImage: {
    resizeMode: "contain",
    width: "100%",
    height: "60%",
    justifySelf: "center",
    alignSelf: "center",
  },
  spinnerContainer: {
    width: "100%",
    height: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: { width: "100%", marginTop: "2%" },
  cardContent: { marginBottom: 10, width: 300, marginTop: 10 },
});
