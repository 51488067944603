import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import React from "react";

export default function ManageOperations({ navigation }: any) {
  const DrawerHeader = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 2,
            borderColor: "grey",
          }}
        >
          <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <AntDesign name="arrowleft" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <View style={{ paddingLeft: 10, justifyContent: "center" }}>
            <Text style={{ fontWeight: "500" }}>Order Request</Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={{ backgroundColor: "#fff", flex: 1 }}>
      <DrawerHeader />
      <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
        <View>
          <Text style={{ textAlign: "center", marginBottom: 20, fontSize: 18 }}>
            Select type of order request:
          </Text>
          <View style={{ paddingHorizontal: 20 }}>
            <TouchableOpacity
              style={styles.screenButtons}
              onPress={() => navigation.navigate("ReserveOrder")}
            >
              <Text style={styles.screenButtonsText}>Reserve Order</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.screenButtons}
              onPress={() => navigation.navigate("PlaceOrder")}
            >
              <Text style={styles.screenButtonsText}>Place Order</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.screenButtons}
              onPress={() => navigation.navigate("PrepayOrder")}
            >
              <Text style={styles.screenButtonsText}>Prepay Order</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  drawerHeader: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderBottomWidth: 0.5,
    borderColor: "#ededed",
  },
  screenButtons: {
    backgroundColor: "#59ADFF",
    paddingVertical: 15,
    paddingHorizontal: 25,
    borderRadius: 30,
    marginVertical: 20,
  },
  screenButtonsText: {
    color: "#fff",
    fontSize: 16,
    textAlign: "center",
  },
});
