export const drawerItemsMain = [
  {
    key: "Delivery",
    title: "Delivery",
    routes: [
      {
        nav: "DeliveryReport",
        routeName: "DeliveryReport",
        title: "Delivery Report",
      }
    ],
  },
  {
    key: "Stock Transfer",
    title: "Stock Transfer",
    routes: [
      {
        nav: "StockTransferReport",
        routeName: "StockTransferReport",
        title: "Stock Transfer Report",
      },
    ],
  }
];
