import { companyModel } from "../../models/companyModel";
import { actionTypesConfigurations } from "../../redux/constants/actionTypesConfigurations";
import { ConfigurationDispatcher } from "../../redux/dispatcher/configurationDispatcher";
import { getCompanyDetailsByUserId, getCompanyDetailsByUserIdV2 } from "../../services/companyService";
import { setItemInSessionStorage } from "../../services/utils/storageService";

interface companyItem {
  companyId: string;
  companyName: string;
}

const setCompanies = (data: Array<companyModel>, hasSingleCompany: boolean) => {
  ConfigurationDispatcher(actionTypesConfigurations.Companies, data);

  if (hasSingleCompany) {
    ConfigurationDispatcher(actionTypesConfigurations.CurrentCompany, data[0]);
  }
};

export const extractCompanyId = async () => {
  let hasCompany: companyItem = {
    companyId: "",
    companyName: "",
  };

  return new Promise<companyItem>((resolve, reject) => {
    try {
      getCompanyDetailsByUserIdV2().then((mapiData) => {
        const companiesItems: Array<companyModel> = mapiData?.company.data;
        const company = companiesItems && companiesItems[0];

        setCompanies(companiesItems, true);

        const isCompanyExists = typeof mapiData?.company[0] !== "undefined";
        hasCompany = {
          companyId: isCompanyExists ? company.company_id : "0000000001",
          companyName: isCompanyExists ? company.company_name : "Multisteps",
        };

        setItemInSessionStorage(
          "company-context",
          JSON.stringify({
            company_id: hasCompany.companyId,
            company_name: hasCompany.companyName,
          })
        );

        resolve(hasCompany);
      });
    } catch (error: unknown) {
      reject(error);
    }
  });
};
