import store from "../configureStore";
import allActions from "../actions";
import { ordersCollectionModel } from "../../models/ordersModel";
import { actionTypesOperations } from "../constants/actionTypesOperations";

const { dispatch } = store;

export const OperationsDispatcher = (
  actionType: actionTypesOperations,
  data: unknown
) => {
  const operationsAction = allActions.operationsActions;

  switch (actionType) {
    case actionTypesOperations.ListOfOperations:
      dispatch(
        operationsAction.setOperationsList(data as Array<ordersCollectionModel>)
      );
      break;
    case actionTypesOperations.ListOfOperations:
      dispatch(
        operationsAction.setOperationsList(data as Array<ordersCollectionModel>)
      );
      break;
    case actionTypesOperations.FilterOperations:
      dispatch(
        operationsAction.setFilteredOperations(
          data as Array<ordersCollectionModel>
        )
      );
      break;
    case actionTypesOperations.SearchOperations:
      dispatch(
        operationsAction.setSearchOperations(
          data as Array<ordersCollectionModel>
        )
      );
      break;
    case actionTypesOperations.IsOperationsTableRefreshed:
      dispatch(operationsAction.setIsOperationsTableRefreshed(data as boolean));
      break;
    case actionTypesOperations.SelectedOperation:
      dispatch(
        operationsAction.setSelectedOperation(data as ordersCollectionModel)
      );
      break;
  }
};
