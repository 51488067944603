import React from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";

export default function MobileSettingsScreen({ navigation }: any) {
  const { companies, currentCompany, currentRole, userInfo } = useSelector(
    (state: any) => state.configurations
  );
  const { logo } = useSelector((state: any) => state.configurations);
  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <View style={[styles.user, styles.row]}>
          <View style={styles.imgIconContainer}>
            <Image
              source={{ uri: logo ? logo : "" }}
              style={styles.userImg}
              resizeMode="contain"
            />
          </View>
          <View style={styles.justifyContent}>
            <Text style={styles.userName}>{userInfo?.display_name}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.imgIconContainer}>
            <FontAwesome name="user" size={24} color="black" />
          </View>
          <View style={styles.justifyContent}>
            <Text
              style={styles.rowTitle}
              onPress={() =>
                navigation.navigate("UserSettings", { screen: "Profile" })
              }
            >
              Account Settings
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.imgIconContainer}>
            <FontAwesome name="users" size={24} color="black" />
          </View>
          <View style={styles.justifyContent}>
            <Text
              style={styles.rowTitle}
              onPress={() =>
                navigation.navigate("OrgSettings", { screen: "Users" })
              }
            >
              User Management
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.imgIconContainer}>
            <FontAwesome name="building" size={24} color="black" />
          </View>
          <View style={styles.justifyContent}>
            <Text style={styles.rowTitle}>Organisation Settings</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.imgIconContainer}>
            <MaterialCommunityIcons
              name="electric-switch-closed"
              size={24}
              color="black"
            />
          </View>
          <View style={styles.justifyContent}>
            <Text style={styles.rowTitle}>Name--</Text>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.row}>
          <View style={[styles.imgIconContainer, { paddingRight: 0 }]}>
            <MaterialCommunityIcons name="logout" size={24} color="red" />
          </View>
          <View style={styles.justifyContent}>
            <Text style={styles.logoutTitle}>Sign Out</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  justifyContent: { justifyContent: "center" },
  container: { flex: 1, backgroundColor: "#fff" },
  row: { flexDirection: "row", paddingHorizontal: 12, paddingVertical: 15 },
  user: { borderBottomWidth: 1, borderBottomColor: "#F0F0F0" },
  userImg: { width: 28, height: 28, backgroundColor: "grey", borderRadius: 50 },
  userName: { fontWeight: "800" },
  rowTitle: { fontWeight: "500" },
  imgIconContainer: { paddingRight: 10, width: 35, alignItems: "center" },
  logoutTitle: { fontWeight: "500", fontSize: 16, color: "red" },
});
