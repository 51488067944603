import React, { useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { replaceDataValues } from "../../utils/common";
import { View } from "../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../components/custom/Banners/LabelBannerWithSearchBar";
import { LabelBannerWithSearchTags } from "../../components/custom/Banners/LabelBannerWithSearchTags";
import { DataTableWithPagination } from "../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../components/custom/Modal/index";
import Spinner from "../../components/shared/Spinner";
import useColorScheme from "../../hooks/useColorScheme";
import { userClaimInformation } from "../../models/usersModel";
import { validSignedURL } from "../../utils/validateSignedUrl";

import _ from "lodash";

import {
  getDelivery
} from "../../services/deliveryService";
import { headers, mdHeaders, smHeaders } from "./utils/deliveryHeaders";
import {
  nestedHeaders as deliveryHeader,
  mdNestedHeaders as deliveryMdHeaders,
  smNestedHeaders as deliverySmHeaders,
} from "./utils/deliveryDetailsHeaders";
import { deliveryModel } from "../../models/reportsModel";
import {
  filterBasedOnAnyKey,
} from "../../utils/searchInArrays";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { useSelector } from "react-redux";
import { handleTableLoader } from "../../utils/common";
import { sortColumns } from "../../utils/sortColumns";

export default function DeliverytScreen({ route, navigation }: any) {
  const scrollRef = useRef<ScrollView>(null);
  const isFocused = useIsFocused();
  const colorScheme = useColorScheme();

  const columnsToNotHaveThousandsSeparator: Array<string> = ["Document #", "Reference", "Document # ", "DocNum" ];
  const dataToBeConverted: object = {
    /*{ "searchString" : "replaceValue" }*/
    "Dispatched":"Shipped",
    "Not Confirmed": "Pending for Payment"
  };


  const { userInfo } = useSelector((state: any) => state.configurations);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [deliveryPageLevelList, setDeliveryPageLevelList] = useState<
    Array<deliveryModel>
  >([]);


  const { isLargeDevice, isMediumDevice, isMobileDevice, isSmallDevice } =
    useContext(DeviceOrientationContext);

  const [_filterCategory, _setFilterCategory] = useState<string>("Next 7 days");
  const { userRoles } = useSelector((state: any) => state.configurations);

  const { deliveryList, deliveryListFilterCategoryList, isDeliveryListLoaded } = useSelector(
    (state: any) => state.delivery
  );

  const { deliveryItemsList } = useSelector((state: any) => state.items);

  const [selected, setSelected] = useState<deliveryModel>(
    {} as unknown as deliveryModel
  );

  //Table & Nested Table Headers
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );

  const [nestedTableHeaders, setNestedTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      deliveryHeader
    );

  const [_isDisplayViewOn, _setIsDisplayViewOn] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [, setContentOffSet] = useState<number>(0);

  const filterDeliveryByCategory = (
    _deliveryMode: Array<deliveryModel>,
    _filterCategory: string
  ) => {
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1
    );
    const tmw = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    const Nxt7Days = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    const Nxt14Days = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 14
    );
    const Nxt30Days = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 30
    );
    return _deliveryMode.filter((delivery: deliveryModel) => {
      switch (_filterCategory) {
        case "Today":
          return new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate()) 
            >= today &&
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            <= tmw    
          break;
        case "Next 7 days":
          return (
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            >= today &&
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            <= Nxt7Days
          )
          break;
        case "Next 14 days":
          return (
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            >= today &&
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            <= Nxt14Days
          )
          break;
        case "Next 30 days":
          return (
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            >= today &&
            new Date(
              new Date(delivery.RequestDate).getFullYear(), 
              new Date(delivery.RequestDate).getMonth(),
              new Date(delivery.RequestDate).getDate())
            <= Nxt30Days
          )
          break;
        case "NSW":
          return delivery.State === "NSW";
          break;
        case "VIC":
          return delivery.State === "VIC";
          break;
        case "QLD":
          return delivery.State === "QLD";
          break;
        case "TAS":
          return delivery.State === "TAS";
          break;
        case "WA":
          return delivery.State === "WA";
          break;
        case "SA":
          return delivery.State === "SA";
          break;
        default:
          return delivery;
          break;
      }
    });
  };
 
  const onHandleSortColumn = (columnKey: string) => {
    const _sortedDeliveryList: Array<deliveryModel> = sortColumns(
      deliveryPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setDeliveryPageLevelList(filterDeliveryByCategory(_sortedDeliveryList,_filterCategory));
  };

  /**
   * TODO: NESTED TABLE SORTED Is Disabled,
   * enable when sorting on nested table feature is requested
   */
  // const onHandleNestedSortColumn = (
  //   columnKey: string,
  //   columnParentIdentifier: string
  // ) => {
  //   const _sortedItemsList: Array<itemsModal> = sortColumns(
  //     nestedItemsPageLevelList,
  //     columnKey,
  //     nestedTableHeaders,
  //     setNestedTableHeaders
  //   );

  //   setNestedItemsPageLevelList(_sortedItemsList);
  // };

  const onHandleScroll = (event: any) => {
    setContentOffSet(Math.floor(event.nativeEvent?.contentOffset?.y) ?? 0);
  };

  const onHandleRowClick = (row: deliveryModel) => {};

  const fadeTableLoader = (setIsSearched: (e: boolean) => void) => {
    handleTableLoader(false, true, setIsSearched);
  };

  const onHandleRemoveRow = (containerId: string) => {};

  const onHandleSearch = (searchText: string) => {
    displayTableLoader(setIsSearched);

    let searchedDelivery: Array<deliveryModel> = [];
    if (searchText.trim() !== "") {
      /**
       * When SearchBy: Delivery, enter into if block
       * otherwise go for else block
       */
      if (!_isDisplayViewOn) {
        const filteredDelivery = filterBasedOnAnyKey(
          extractIdenticalDelivery(),
          searchText
        );

        searchedDelivery = feedItemsInDelivery(filteredDelivery);
      } else {
        /**
         * Steps to search for items within containers
         * 1: Extract all items from containers & union them
         * 2: Search by keyword in the list of items extracted from containers
         * 3: Group by container_number on the searchedItems
         * 4: Remove the duplicates from array of container_numbers
         * 5: filter the unique containers from the identicalFeededDeliveryWithItems()
         *    & return the list of containers with items matching the search text
         */

        //Step 1
        const itemsFromDelivery = _.map(
          identicalFeededDeliveryWithItems(),
          "items"
        );
        const flattenItems = _.flatten(itemsFromDelivery);

        //Step 2
        const searchedItems = filterBasedOnAnyKey(flattenItems, searchText);

        //Step 3
        const groupedDelivery = Object.keys(
          _.groupBy(searchedItems, "DocNum")
        );

        //Step 4
        const uniqueDelivery = _.uniq(groupedDelivery);

        //Step 5
        const filteredDelivery = identicalFeededDeliveryWithItems().filter(
          (delivery) => uniqueDelivery.includes(delivery.DocNum)
        );

        searchedDelivery = filteredDelivery;
      }
      
      setDeliveryPageLevelList(filterDeliveryByCategory(searchedDelivery,_filterCategory));
    }

    setTimeout(() => {
      fadeTableLoader(setIsSearched);
    }, 2000);
  };

  const onHandleScrollToBase = () => {
    scrollRef.current?.scrollTo({
      x: 0,
      y: 0,
      animated: true,
    });
  };

  useEffect(() => {
    validSignedURL(
      selected.ItemCode || "",
      "label",
      setImageLoading,
      setSelectedImage
    );
  }, [selected]);

  const ModalContent = () => {
    const _excludedKeys = [
      "BaseReference", 
      "BatchAttribute1", 
      "BatchAttribute2", 
      "BatchAttribute3",
      "U_CashCusName",
      "U_CashCusPhone",
      "U_PMX_BAV1",
      "U_PMX_BAV2",
      "U_PMX_BAV3",
      "LastUpdate",
      "OrderQtyPCS",
      "SQRef.",
      "PaymentTerm",
      "RemainingQtyPCS"
    ];

    const _orderedKeys = [
      "DocNum",
      "DocType",
      "ItemCode",
      "ProductGroup",
      "CustomerCode",
      "CustomerRef",
      "DeliveryAddress",
      "OrderDate",
      "RequestDate",
      "DelivrdQty",
      "OrderQty",
      "RemainingQty",
      "ShipToCode",
      "ShippingType",
      "State",
      "Warehouse",
      "SalesPerson",
      "ProcessBy"
    ];

    const _specialLabels = {
      DocType: {
        oldLabel: "Document",
        newLabel: "Document Type",
      },
      ItemCode: {
        oldLabel: "ItemCode",
        newLabel: "Item Code",
      },  
      ProductGroup: {
        oldLabel: "ProductGroup",
        newLabel: "Product Group",
      },  
      CustomerCode: {
        oldLabel: "CustomerCode",
        newLabel: "Customer Code",
      },   
      CustomerRef: {
        oldLabel: "CustomerCode",
        newLabel: "Customer Ref#",
      },    
      DeliveryAddress: {
        oldLabel: "DeliveryAddress",
        newLabel: "Delivery Address",
      }, 
      OrderDate: {
        oldLabel: "OrderDate",
        newLabel: "Order Date",
      }, 
      RequestDate: {
        oldLabel: "RequestDate",
        newLabel: "Request Date",
      }, 
      DelivrdQty: {
        oldLabel: "DelivrdQty",
        newLabel: "Delivery Qty",
      }, 
      OrderQty: {
        oldLabel: "OrderQty",
        newLabel: "Order Qty",
      }, 
      RemainingQty: {
        oldLabel: "RemainingQty",
        newLabel: "Remaining Qty",
      }, 
      SalesPerson: {
        oldLabel: "SalesPerson",
        newLabel: "Sales",
      }, 
      ProcessBy: {
        oldLabel: "ProcessBy",
        newLabel: "Operator",
      }, 
    };

    return (
      <Modal.ModalContent
        title={selected.ItemCode || ""}
        subTitle={selected.Description}
        icon="folder"
        uri={selectedImage}
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headers}
            orderedKeys={_orderedKeys}
            specialLabels={_specialLabels}
            columnsToNotHaveThousandSeparator={columnsToNotHaveThousandsSeparator}
          />
        }
      />
    );
  };

  const extractIdenticalDelivery = () => {
    //process both containers and items list to return filtered containers list
    const _deliveryItemsList: Array<deliveryModel> = deliveryList;
    const groupDelivery = _.groupBy(
      _deliveryItemsList,
      (item) => item.DocNum
    );
    // check here
    return _deliveryItemsList as Array<deliveryModel>;
  };

  const feedItemsInDelivery = (delivery: deliveryModel[]) => {
    const _deliveryItemsList: Array<deliveryModel> = [...deliveryList];

    return delivery.map((_delivery) => {
      return {
        ..._delivery,
        items: _deliveryItemsList.filter(
          (item: deliveryModel) =>
            item.DocNum === _delivery.DocNum
        ),
      };
    });
  };

  const identicalFeededDeliveryWithItems = (): Array<deliveryModel> => {
    return feedItemsInDelivery(extractIdenticalDelivery());
  };

  const displayTableLoader = (setIsSearched: React.Dispatch<React.SetStateAction<boolean>>) => {
    handleTableLoader(true, false, setIsSearched);
  };


  useEffect(() => {
    setRefresh(true);
  }, [_isDisplayViewOn]);

  useEffect(() => {
    let isMounted: boolean = true;
    const _userInfo = userInfo as userClaimInformation;
    // Avoid keep refreshing table when item is selected
    if (isMounted) {  
      if (deliveryPageLevelList.length === 0 || isRefresh) {
        displayTableLoader(setIsSearched);
        _setFilterCategory("Next 7 days");
        getDelivery(
          "DeliveryReport",
          "",
          _userInfo.team,
          _userInfo.zone_info || "",
          "delivery_report",
          tableHeaders
        );
      }
    }

    setTableHeaders(
      isLargeDevice ? headers : isMediumDevice ? mdHeaders : smHeaders
    );

    setNestedTableHeaders(
      isLargeDevice
        ? deliveryHeader
        : isMediumDevice
        ? deliveryMdHeaders
        : deliverySmHeaders
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [isFocused, isRefresh, _filterCategory === "Next 7 days"]);

  useEffect(() => {
    const unique = [...
      identicalFeededDeliveryWithItems().reduce((map, obj) => {
        if(!map.has(obj.DocNum)) map.set(obj.DocNum, obj);
        return map;
      }, new Map)
      .values()
    ];
    setDeliveryPageLevelList(filterDeliveryByCategory(unique,_filterCategory));

    setTimeout(() => {
      fadeTableLoader(setIsSearched);
    }, 2000);
  }, [deliveryList, deliveryItemsList, _filterCategory]);

  return (
    <ScrollView
      style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      onScroll={onHandleScroll}
      ref={scrollRef}
    >
      <View
        style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      >
        <View
          style={[
            styles.container,
            {
              backgroundColor: colorScheme === "light" ? "#f2f2f2" : "#f2f2f2",
            },
          ]}
        >
          <View
            style={[
              styles.containerContent,
              {
                width: isLargeDevice
                  ? "100%"
                  : isMediumDevice
                  ? "100%"
                  : "100%",
              },
            ]}
          >
            {isDeliveryListLoaded ? (
              <View>
                <LabelBannerWithSearchBar
                  titleName={`Total Delivery: ${deliveryPageLevelList.length}`}
                  handleSearch={onHandleSearch}
                  setRefresh={setRefresh}
                  isResetFields={isRefresh}
                />
                <LabelBannerWithSearchTags
                  isMobileDevice={isMobileDevice}
                  isSmallDevice={isSmallDevice}
                  label="Quick Filter"
                  filterCategoryList={deliveryListFilterCategoryList}
                  filterCategory={_filterCategory}
                  setFilterCategory={_setFilterCategory}
                />
                <DataTableWithPagination
                  headers={tableHeaders}
                  rows={replaceDataValues(deliveryPageLevelList,dataToBeConverted)}
                  itemsPerPage={20}
                  isDataLoading={isSearched}
                  useModal={true}
                  nestedHeader={nestedTableHeaders}
                  isRowsExpandable={true}
                  setModalVisible={setModalVisible}
                  handleRowClick={onHandleRowClick}
                  setSelected={setSelected}
                  isToExpandAllRows={_isDisplayViewOn}
                  rowKeyValue="DocNum"
                  nestedItemKeyValue="items"
                  isSubDetail={true}
                  isDetail={false}
                  isEdit={false}
                  isDelete={false}
                  columnsToNotHaveThousandSeparator={columnsToNotHaveThousandsSeparator}
                  handleSortColumn={onHandleSortColumn} 
                  handleDeleteRow={function (id: string | number): void {
                    throw new Error("Function not implemented.");
                  }}/>
              </View>
            ) : (
              <View>
                <Spinner
                  size="small"
                  color="red"
                  styles={{ flexDirection: "row", margin: "20%" }}
                />
              </View>
            )}
          </View>
        </View>
        <Modal.ModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          childern={ModalContent}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  fab: {
    bottom: -10,
    position: "absolute",
    zIndex: 1,
  },
});