import * as React from "react";
import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import StocksReportsScreen from "../../../screens/Reports/StocksReportScreens/StocksReportScreen";
import PrdouctsReportsScreen from "../../../screens/Reports/ProductsReportScreens/ProductsReportScreen";
import CustomerStatusReportsScreen from "../../../screens/Reports/CustomerStatusReportScreens/CustomerStatusReportScreen";
import CustomerOrderReportsScreen from "../../../screens/Reports/CustomerOrderReportScreens/CustomerOrderReportScreen";
import { drawerItemsMain } from "./ReportsDrawerItemsMain";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";

const Drawer = createDrawerNavigator();

export default function ReportsDrawerNavigator() {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      initialRouteName="Home"
      screenOptions={{
        drawerStyle: {
          width: 180,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent drawerItems={drawerItemsMain} {...props} />
      )}
    >
      <Drawer.Screen
        name="Stocks"
        component={StocksReportsScreen}
        options={{ title: "Stock" }}
      />
      <Drawer.Screen
        name="Products"
        component={PrdouctsReportsScreen}
        options={{ title: "Search Product" }}
      />
      <Drawer.Screen
        name="CustomerStatus"
        component={CustomerStatusReportsScreen}
        options={{ title: "Customer Status" }}
      />
      {/* <Drawer.Screen
        name="CustomerOrder"
        component={CustomerOrderReportsScreen}
        options={{ title: "Customer Order" }}
      /> */}
    </Drawer.Navigator>
  );
}
