export const nestedStockTransferHeaders = {
  DocNum: {
    name: "Document #",
    width: 0.3,
    textAlign: "left",
  },
  ItemCode: {
    name: "Item Code",
    width: 0.4,
    textAlign: "left",
  },
  Dscription: {
    name: "Description",
    width: 1.0,
    textAlign: "left",
  },
  DeliveryAddress: {
    name: "Address",
    width: 1.0,
    textAlign: "left",
  },
  ProductGroup: {
    name: "Product Group",
    width: 0.5,
    textAlign: "left",
  },
  RemainingQty: {
    name: "Remaining Qty",
    width: 0.5,
    textAlign: "left",
  },
};

export const mdNestedStockTransferHeaders = {
  DocNum: {
    name: "Document #",
    width: 0.3,
    textAlign: "left",
  },
  ItemCode: {
    name: "Item Code",
    width: 0.4,
    textAlign: "left",
  },
  DeliveryAddress: {
    name: "Address",
    width: 1.0,
    textAlign: "left",
  },
  RemainingQty: {
    name: "Remaining Qty",
    width: 0.5,
    textAlign: "left",
  },
};

export const nestedHeaders = {
  DocNum: {
    name: "Document #",
    width: 0.3,
    textAlign: "left",
  },
  ItemCode: {
    name: "Item Code",
    width: 0.4,
    textAlign: "left",
  },
  Dscription: {
    name: "Description",
    width: 1.5,
    textAlign: "left",
  },
  DeliveryAddress: {
    name: "Address",
    width: 1.5,
    textAlign: "left",
  },
  'CustomerRef.': {
    name: "Reference",
    width: 0.7,
    textAlign: "left",
  },
  ProductGroup: {
    name: "Product Group",
    width: 0.6,
    textAlign: "center",
  },
  OrderQty: {
    name: "Order Qty",
    width: 0.6,
    textAlign: "center",
  },
  DelivrdQty: {
    name: "Delivery Qty",
    width: 0.6,
    textAlign: "center",
  },
  RemainingQty: {
    name: "Remaining Qty",
    width: 0.6,
    textAlign: "center",
  },
  NoOfPallets: {
    name: "Pallets",
    width: 0.6,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdNestedHeaders = {
  DocNum: {
    name: "Document #",
    width: 0.3,
    textAlign: "left",
  },
  ItemCode: {
    name: "Item Code",
    width: 0.4,
    textAlign: "left",
  },
  DeliveryAddress: {
    name: "Address",
    width: 2.0,
    textAlign: "left",
  },
  OrderQty: {
    name: "Order Qty",
    width: 1.0,
    textAlign: "center",
  },
  DelivrdQty: {
    name: "Delivery Qty",
    width: 1.0,
    textAlign: "center",
  },
  RemainingQty: {
    name: "Remaining Qty",
    width: 1.0,
    textAlign: "center",
  },
  NoOfPallets: {
    name: "Pallets",
    width: 1.0,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smNestedHeaders = {
  DocNum: {
    name: "Document #",
    width: 0.8,
    textAlign: "left",
  },
  ItemCode: {
    name: "Item Code",
    width: 0.8,
    textAlign: "left",
  },
  Dscription: {
    name: "Description",
    width: 1.5,
    textAlign: "left",
  }
};
