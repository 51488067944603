import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Controller } from "react-hook-form";
import { TextInputControlOrder } from "../../../components/custom/TextInputControlOrder";
import ConfirmationModal from "./ConfirmationModal";
import { FontAwesome } from "@expo/vector-icons";
import StyledDataTable from "./StyledDataTable";

interface Props {
  navigateBack: () => void;
  navigateForward: () => void;
  mobileDevice: boolean;
  form: any;
  shippingMethod: Array<Record<string, string>>;
  orderTypeList: Array<Record<string, string>>;
  locations: Array<Record<string, string>>;
}

const OrderScreenThird: React.FC<Props> = ({
  navigateBack,
  navigateForward,
  mobileDevice,
  form,
  shippingMethod,
  orderTypeList,
  locations,
}) => {
  const { control, handleSubmit, reset, getValues, onSubmit } = form;

  const [remarksInput, setRemarksInput] = useState("");

  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const showConfirmationModal = () => setConfirmationModalVisible(true);
  const hideConfirmationModal = () => setConfirmationModalVisible(false);
  const [confirmationOption, setConfirmationOption] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);
  const [totalBoxes, setTotlaBoxes] = useState(0);

  const confirmOrder = (val: string) => {
    navigateForward(val);
  };

  // const deliveryDate = new Intl.DateTimeFormat('en-US', {
  //   day: '2-digit',
  //   month: '2-digit',
  //   year: 'numeric'
  // }).format(getValues('deliveryDate'));

  const deliveryDate = `${getValues("deliveryDate")}`.split("T")[0];

  const getSelectedShippingMethod =
    shippingMethod?.find((sm) => sm.key == getValues("shippingMethod"))
      ?.value ?? null;
  const getSelectedOrderType =
    orderTypeList?.find((sm) => sm.key === getValues("orderType"))?.value ??
    null;

  // const getSelectedLocation = locations.find(sm => sm.key == getValues('location')).value;
  const getSelectedLocation = getValues("location");
  const productList = getValues("products");

  const calculateTotals = () => {
    let totalPrice = 0;
    let totalBoxes = 0;
    let totalPieces = 0;

    // Iterate through products and calculate totals
    productList.forEach((product: any) => {
      totalPrice += product.price * product.quantity;

      // Check if the product has a UoM and count accordingly
      if (product.uom === "Boxes") {
        totalBoxes += product.quantity;
      } else if (product.uom === "Pieces") {
        totalPieces += product.quantity;
      }
    });
    setTotalPrice(totalPrice);
    setTotlaBoxes(totalBoxes);
    setTotalPieces(totalPieces);
  };

  useEffect(() => {
    calculateTotals();
  }, []);

  return (
    <View style={[styles.container, { flex: 1 }]}>
      {/* Customer Details */}
      <View style={styles.formSection}>
        <Text style={styles.formHeaders}>Customer Details</Text>
        <View style={styles.customerRow}>
          <View style={{ flex: 1 }}>
            <Text>{getValues("customerName")}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text>{getValues("customerReference")}</Text>
          </View>
        </View>
        <View style={styles.customerRow}>
          <View style={{ flex: 1 }}>
            <Text>{getValues("customerNumber")}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text>{getValues("customerEmail")}</Text>
          </View>
        </View>
      </View>
      <View style={styles.formSection}>
        <Text style={styles.formHeaders}>Shipping Details</Text>
        <View>
          <View style={styles.shippingRow}>
            <View style={{ flex: 1 }}>
              <Text>Delivery Date:</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{deliveryDate}</Text>
            </View>
          </View>
          <View style={styles.shippingRow}>
            <View style={{ flex: 1 }}>
              <Text>Shipping Method:</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{getSelectedShippingMethod}</Text>
            </View>
          </View>
          <View style={styles.shippingRow}>
            <View style={{ flex: 1 }}>
              <Text>Order Type</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{getSelectedOrderType}</Text>
            </View>
          </View>
          <View style={styles.shippingRow}>
            <View style={{ flex: 1 }}>
              <Text>Address</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{getValues("address")}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.formSection, { borderBottomWidth: 0 }]}>
        <Text style={styles.formHeaders}>Products</Text>
        <View style={styles.customerRow}>
          <View style={{ flex: 1, paddingHorizontal: 5 }}>
            <Text style={{ textAlign: "right", color: "grey" }}>Location</Text>
          </View>
          <View style={{ flex: 1, paddingHorizontal: 5 }}>
            <Text>{getSelectedLocation}</Text>
          </View>
        </View>
        <View style={{ marginHorizontal: -15 }}>
          <StyledDataTable
            products={getValues("products")}
            totalBoxes={totalBoxes}
            totalPieces={totalPieces}
            totalPrice={totalPrice}
            orderType={getValues("orderType")}
          />
        </View>
      </View>
      <View style={styles.formSection}>
        <Text style={styles.formHeaders}>Frieght Delivery</Text>
        <View style={{ alignItems: "center" }}>
          <View
            style={{
              flexDirection: "row",
              borderWidth: 1,
              borderColor: "#000",
              borderRadius: 3,
            }}
          >
            <View
              style={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRightWidth: 1,
                borderRightColor: "#000",
              }}
            >
              <FontAwesome name="dollar" size={20} color="black" />
            </View>
            <View
              style={{ paddingVertical: 5, paddingHorizontal: 10, width: 150 }}
            >
              <Text>{getValues("freightDelivery")}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.formSection, { borderBottomWidth: 0 }]}>
        <Text style={styles.formHeaders}>Submission</Text>
        <View style={[styles.customerRow, { marginBottom: 10 }]}>
          <View style={{ flex: 1 }}>
            <Text>Receipt ID</Text>
          </View>
          <View style={{ flex: 1.75, paddingLeft: 15 }}>
            <Text style={{ backgroundColor: "#D9D9D9", textAlign: "center" }}>
              Auto-Generated ID
            </Text>
          </View>
        </View>
        <View>
          <Controller
            control={control}
            rules={{
              validate: {
                required: async (value) => {
                  if (!value) return "* This is required.";
                },
              },
            }}
            name="remarks"
            render={({ field: { onChange, value } }) => (
              <TextInputControlOrder
                title="Remarks"
                required={true}
                width="100%"
                placeholder=""
                value={value}
                setChangeText={onChange}
                blurOnSubmit={false}
                handleOnSubmitEditing={handleSubmit(onSubmit)}
                isMultiline={true}
                height={60}
              />
            )}
          />
        </View>
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: 40,
            }}
          >
            <View>
              <TouchableOpacity
                onPress={() => confirmOrder("draft")}
                style={[styles.navigationBtns, { backgroundColor: "#000" }]}
              >
                <Text style={{ color: "#fff" }}>Save Draft</Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                onPress={() => confirmOrder("reservation")}
                style={[styles.navigationBtns, { backgroundColor: "#2CA02C" }]}
              >
                <Text style={{ color: "#fff" }}>Submit Reservation</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <ConfirmationModal
        confirmationOption={confirmationOption}
        confirmationModalVisible={confirmationModalVisible}
        hideConfirmationModal={hideConfirmationModal}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
    paddingVertical: 25,
    flex: 1,
  },
  formHeaders: {
    color: "#B5B5B5",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 7,
  },
  formSection: {
    paddingVertical: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#000",
  },
  customerRow: {
    flexDirection: "row",
    paddingVertical: 8,
  },
  shippingRow: {
    flexDirection: "row",
    paddingVertical: 5,
  },
  checkbox: {
    paddingLeft: 0,
    marginLeft: -7,
    paddingVertical: 0,
  },
  checkboxLabel: {
    marginTop: 2,
    textAlign: "left",
    justifyContent: "center",
    fontWeight: "500",
  },
  datatableHeader: {
    height: 45,
    backgroundColor: "#D9D9D9",
  },
  productColumn: {
    flex: 2.75,
    paddingVertical: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  orderQtyColumn: {
    flex: 0.75,
    paddingVertical: 0,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  uom: {
    flex: 1.1,
    justifyContent: "center",
    paddingVertical: 0,
    alignItems: "flex-start",
  },
  inventory: {
    flex: 1.1,
    justifyContent: "center",
    paddingVertical: 0,
    alignItems: "flex-start",
  },
  price: {
    flex: 1.2,
    justifyContent: "flex-end",
    paddingVertical: 0,
    alignItems: "flex-start",
  },
  remarksInput: {
    borderWidth: 1,
    borderColor: "#000",
    height: 50,
  },
  navigationBtns: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    backgroundColor: "#D9D9D9",
    borderRadius: 30,
  },
  datatableHeaderItemText: {
    fontSize: 12,
    textAlign: "center",
    lineHeight: 16,
    color: "#000",
  },
});

export default OrderScreenThird;
