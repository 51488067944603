export enum actionTypesDelivery {
  ListOfDelivery = "deliveryList",
  IsDeliveryTableRefreshed = "isDeliveryTableRefreshed",
  SelectedDelivery = "selectedDelivery",
}

export enum actionTypesStockTransfer {
  ListOfStockTransfer = "stockTransferList",
  IsStockTransferTableRefreshed = "isStockTransferTableRefreshed",
  SelectedStockTransfer = "selectedStockTransfer",
}
