import * as React from "react";
import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { drawerItemsMain } from "./DeliveryDrawerItemsMain ";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";
import DeliveryScreen from "../../../screens/Delivery/DeliveryScreen";
import StockTransferScreen from "../../../screens/Delivery/StockTransferScreen";

const Drawer = createDrawerNavigator();

export default function DeliveryDrawerNavigator() {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerStyle: {
          width: 200,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent drawerItems={drawerItemsMain} {...props} />
      )}
    >
      <Drawer.Screen
        options={{
          drawerLabel: "Delivery",
          title: "Delivery",
        }}
        name="DeliveryReport"
        component={DeliveryScreen}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Stock Transfer",
          title: "Stock Transfer",
        }}
        name="StockTransferReport"
        component={StockTransferScreen}
      />
    </Drawer.Navigator>
  );
}
