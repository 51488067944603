import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";

import StocksReportsScreen from "../../../screens/Reports/StocksReportScreens/StocksReportScreen";
import PrdouctsReportsScreen from "../../../screens/Reports/ProductsReportScreens/ProductsReportScreen";
import ProductionStocksReportScreen from "../../../screens/Reports/ProductionStocksReportScreen";
import RawMaterialStocksReportScreen from "../../../screens/Reports/RawMaterialReportScreens/RawMaterialStocksReportScreen";
import RawMaterialStocksOverviewReportScreen from "../../../screens/Reports/RawMaterialReportScreens/RawMaterialStocksOverviewReportScreen";
import CustomerStatusReportsScreen from "../../../screens/Reports/CustomerStatusReportScreens/CustomerStatusReportScreen";
import CustomerOrderReportsScreen from "../../../screens/Reports/CustomerOrderReportScreens/CustomerOrderReportScreen";
import PmxInventoryReportScreen from "../../../screens/Reports/PmxReportScreens/PmxInventoryReportScreen";
import PmxDetailsInventoryReportScreen from "../../../screens/Reports/PmxReportScreens/PmxDetailsInventoryReportScreen";
import DeliveryReportScreen from "../../../screens/Reports/DeliveryReportScreens/DeliveryReportScreen";
import { drawerItemsMain } from "./ReportsDrawerItemsMain";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";
import { useEffect, useState } from "react";
import { userClaimInformation } from "../../../models/usersModel";
import { useSelector } from "react-redux";
import teamConfig from "../../../utils/constants/teams";

import MobileSettingsScreen from "../../../screens/Settings/MobileSettingsScreen";

const Drawer = createDrawerNavigator();

export default function ReportsDrawerNavigator() {
  const dimensions = useWindowDimensions();
  const [userProfile, setUserProfile] = useState<userClaimInformation>();
  const { userInfo } = useSelector((state: any) => state.configurations);

  const { warehouse } = teamConfig.teams;

  useEffect(() => {
    setUserProfile(userInfo);
  }, [userInfo]);

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      screenOptions={{
        drawerStyle: {
          width: 180,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent
          drawerItems={drawerItemsMain(userProfile?.team.toUpperCase())}
          {...props}
        />
      )}
    >
      <Drawer.Screen
        name="Stocks"
        component={StocksReportsScreen}
        options={{ title: "Stock" }}
      />
      {userProfile?.team.toUpperCase() !== warehouse && (
        <>
          <Drawer.Screen
            name="ProductionStocks"
            component={ProductionStocksReportScreen}
            options={{ title: "Production Stock" }}
          />
          <Drawer.Screen
            name="RawMaterialStocksOverView"
            component={RawMaterialStocksOverviewReportScreen}
            options={{ title: "Raw Material Overview" }}
          />
          <Drawer.Screen
            name="RawMaterialStocksDetails"
            component={RawMaterialStocksReportScreen}
            options={{ title: "Raw Material Stock" }}
          />
        </>
      )}
      <Drawer.Screen
        name="WarehouseInventory"
        component={PmxInventoryReportScreen}
        options={{ title: "Inventory Overview" }}
      />
      <Drawer.Screen
        name="WarehouseDetailsInventory"
        component={PmxDetailsInventoryReportScreen}
        options={{ title: "Inventory Detail" }}
      />
      {userProfile?.team.toUpperCase() !== warehouse && (
        <Drawer.Screen
          name="Products"
          component={PrdouctsReportsScreen}
          options={{ title: "Search Product" }}
        />
      )}
      <Drawer.Screen
        name="CustomerStatus"
        component={CustomerStatusReportsScreen}
        options={{ title: "Customer Status" }}
      />
      <Drawer.Screen
        name="SettingsM"
        component={MobileSettingsScreen}
        options={{ title: "Settings" }}
      />
      {/*<Drawer.Screen
        name="CustomerOrder"
        component={CustomerOrderReportsScreen}
        options={{ title: "Customer Order" }}
      />*/}
    </Drawer.Navigator>
  );
}
