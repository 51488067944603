import React, { useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useColorScheme,
  View,
} from "react-native";
import { Checkbox, DataTable } from "react-native-paper";
import { AntDesign, Feather, FontAwesome } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import { useSelector } from "react-redux";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import UpdateConfirmationScreen from "../../Operations/partials/UpdateConfirmationScreen";
import {
  deleteBypassUserById,
  getBypassUserDetailsById,
  updateOktaBypassUser,
} from "../../../services/usersService";
import TabBarIcon from "../../../components/core/TabBarIcon";
import CompanyLogo from "../../../components/core/CompanyLogo";
import oktaConfig from "../../../configs/okta";
import ContentTitleHeader from "../../../components/core/ContentTitleHeader";

interface Props {
  navigation: any;
  route: any;
}

const CreateOktaUserNavigate: React.FC<Props> = ({ navigation, route }) => {
  const toast = useToast();
  const { isMediumDevice, isMobileDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );
  const isFocused = useIsFocused();
  const [title, setTitle] = useState("");
  const [action, setAction] = useState("");
  const [confirmationTextCS, setConfirmationTextCS] = useState("");
  const [successfulTextCS, setSuccessfulTextCS] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [approved, setApproved] = useState(false);
  const { shippingMethodList, shipToList } = useSelector(
    (state: any) => state.operations
  );
  let { mode, userId } = route.params;
  const [selectedUser, setSelectedUser] = useState(mode || null);
  const colorScheme = useColorScheme();

  useEffect(() => {
    if (mode == undefined || userId == undefined) {
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: "OrgSettings", params: { screen: "Users" } }],
        });
      }, 1500);
    } else {
      getBypassUserDetailsById(userId)
        .then((data) => setSelectedUser(data))
        .catch((error) => toast.show(error));
    }
  }, [mode]);

  useEffect(() => {
    if (isFocused) {
      switch (mode) {
        case "edit":
          setTitle("View User");
          setApproved(true);
          break;
        case "delete":
          setTitle("Delete User");
          setApproved(false);
          break;
        default:
          break;
      }
      setAction(mode);
      setLoaded(true);
    } else setLoaded(false);
  }, [isFocused]);

  useEffect(() => {
    // Handle title and confirmation text based on action
    switch (action) {
      case "edit":
        setTitle("View User");
        break;
      case "delete":
        setTitle("Delete User");
        setConfirmationTextCS(
          "Are you sure you want to delete the bypass user?"
        );
        break;
      default:
        setTitle("");
        setConfirmationTextCS("");
        break;
    }
  }, [action]);

  useEffect(() => {
    // Directly call deleteOperation when approved and action is delete
    if (approved && action === "delete") {
      // Validate selectedUser before attempting to delete
      if (selectedUser && selectedUser._id) {
        deleteDirectOperation(selectedUser._id);
      } else {
        console.error("No user selected for deletion.");
      }
    }
  }, [approved, action]);

  const approveCS = () => {
    setApproved(true);
  };

  const rejectCS = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: "OrgSettings", params: { screen: "Users" } }],
    });
  };

  const updateOperation = () => {
    navigation.navigate("UpdateUser", { userId: userId });
  };

  const mobileDevice = isSmallDevice || isMediumDevice || isMobileDevice;

  const deleteOperation = async (id: string) => {
    if (confirm("Do you want to Delete this Operation?")) {
      await deleteBypassUserById(id)
        .then((result) => {
          toast.show(result?.data.message, {
            type: "success",
          });
          navigation.reset({
            index: 0,
            routes: [{ name: "OrgSettings", params: { screen: "Users" } }],
          });
        })
        .catch((err) => {
          toast.show(err.message, {
            type: "danger",
          });
        });
    }
  };

  const deleteDirectOperation = async (id: string) => {
    await deleteBypassUserById(id)
      .then((result) => {
        toast.show(result?.data.message, {
          type: "success",
        });
        navigation.reset({
          index: 0,
          routes: [{ name: "OrgSettings", params: { screen: "Users" } }],
        });
        // backToList();
      })
      .catch((err) => {
        toast.show(err.message, {
          type: "danger",
        });
      })
      .finally(() => {
        // setDisplayLoader(!displayLoader);
      });
  };

  const updateCurrentAction = (action: string) => {
    setAction((prev) => action);
  };

  const DrawerHeader = () => {
    return (
      <View style={styles.drawerStyle}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <AntDesign name="arrowleft" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <View style={{ paddingLeft: 10, justifyContent: "center" }}>
            <Text style={{ fontWeight: "500" }}>
              {title} - {selectedUser.customerReference}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity onPress={() => deleteOperation(selectedUser._id)}>
            <View style={styles.headerBtnContainer}>
              <AntDesign name="delete" size={24} color="black" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <>
      {approved ? (
        <ScrollView
          style={{
            height: "100%",
            backgroundColor: colorScheme === "light" ? "#fff" : "black",
          }}
        >
          {!isSmallDevice && (
            <View style={{ flexDirection: "row", width: "100%", height: 60 }}>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40%",
                  height: 60,
                }}
              >
                <CompanyLogo />
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "60%",
                  height: 60,
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    (window.location.href = `${oktaConfig.api.redirectUri}`)
                  }
                >
                  <TabBarIcon name="home" color={"black"} />
                </TouchableOpacity>
              </View>
            </View>
          )}
          {!isSmallDevice && (
            <View style={{ paddingVertical: 25 }}>
              <View style={{}}>
                <ContentTitleHeader
                  name="View User Details"
                  navigation={navigation}
                  centered
                />
              </View>
            </View>
          )}
          <DrawerHeader />

          {/* User Details Section */}
          <View style={styles.card}>
            <View style={styles.cardHeader}>
              <Text style={styles.cardTitle}>User Details</Text>
              {action === "edit" && (
                <TouchableOpacity onPress={updateOperation}>
                  <Feather name="edit" size={20} color="#007BFF" />
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>First Name:</Text>
              <Text style={styles.value}>{selectedUser.first_name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Last Name:</Text>
              <Text style={styles.value}>{selectedUser.last_name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Zone Info:</Text>
              <Text style={styles.value}>{selectedUser.zone_info}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Email:</Text>
              <Text style={styles.value}>{selectedUser.email}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Username:</Text>
              <Text style={styles.value}>{selectedUser.login || ""}</Text>
            </View>
          </View>

          {/* Summary Section */}
          <View style={styles.card}>
            <View style={styles.cardHeader}>
              <Text style={styles.cardTitle}>Summary</Text>
              {action === "edit" && (
                <TouchableOpacity onPress={updateOperation}>
                  <Feather name="edit" size={20} color="#007BFF" />
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Team:</Text>
              <Text style={styles.value}>{selectedUser.team}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Role:</Text>
              <Text style={styles.value}>
                {selectedUser?.roles?.length > 0 &&
                  selectedUser?.roles?.[0]?.role}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Company ID:</Text>
              <Text style={styles.value}>
                {selectedUser?.roles?.length > 0 &&
                  selectedUser?.roles?.[0]?.company_id}
              </Text>
            </View>
          </View>
        </ScrollView>
      ) : (
        <UpdateConfirmationScreen
          confirmationText={confirmationTextCS}
          successfulText={successfulTextCS}
          approve={approveCS}
          reject={rejectCS}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 15,
    paddingBottom: 25,
  },
  formHeaders: {
    color: "#B5B5B5",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 7,
  },
  formSection: {
    paddingVertical: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#000",
  },
  customerRow: {
    flexDirection: "row",
    paddingVertical: 8,
  },
  shippingRow: {
    flexDirection: "row",
    paddingVertical: 5,
  },
  checkbox: {
    paddingLeft: 0,
    marginLeft: -7,
    paddingVertical: 0,
  },
  checkboxLabel: {
    marginTop: 2,
    textAlign: "left",
    justifyContent: "center",
    fontWeight: "500",
  },
  datatableHeader: {
    height: 45,
    backgroundColor: "#D9D9D9",
  },
  productColumn: {
    flex: 2.75,
    paddingVertical: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  orderQtyColumn: {
    flex: 0.75,
    paddingVertical: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  uom: {
    flex: 1.1,
    justifyContent: "center",
    paddingVertical: 0,
    alignItems: "center",
  },
  inventory: {
    flex: 1.1,
    justifyContent: "center",
    paddingVertical: 0,
    alignItems: "center",
  },
  price: {
    flex: 1.2,
    justifyContent: "flex-end",
    paddingVertical: 0,
    alignItems: "center",
  },
  remarksInput: {
    borderWidth: 1,
    borderColor: "#000",
    height: 60,
    padding: 4,
  },
  datatableHeaderItemText: {
    fontSize: 12,
    textAlign: "center",
    lineHeight: 16,
    color: "#000",
  },
  headerBtnContainer: {
    padding: 5,
    marginHorizontal: 3,
  },
  fileList: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
  filePreviewHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 8,
    paddingVertical: 5,
    backgroundColor: "#f8f8f8",
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  fileNameText: {
    fontSize: 12, // Reduced font size
    fontWeight: "400",
    color: "#333",
    flexWrap: "wrap",
    maxWidth: "80%", // Prevents text overflow
  },
  fileLink: {
    fontSize: 12, // Adjusted font size
    color: "#007bff",
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 5,
  },
  previewContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  imagePreview: {
    width: "100%",
    height: 80, // Reduced height for image preview
    backgroundColor: "#f5f5f5",
    borderTopWidth: 1,
    borderTopColor: "#eee",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
  },
  placeholderIconContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    width: "100%",
    height: 80, // Same height for placeholder as the image preview
  },
  pdfLink: {
    fontSize: 12,
    color: "#007aff",
    textDecorationLine: "underline",
    textAlign: "center",
    marginVertical: 3,
  },
  drawerStyle: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderColor: "grey",
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 999,
    backgroundColor: "#fff",
    height: 40,
  },
  container: {
    flex: 1,
    backgroundColor: "#F5F5F5",
    padding: 16,
  },
  header: {
    marginBottom: 16,
    padding: 16,
    backgroundColor: "#007BFF",
    borderRadius: 8,
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  headerText: {
    color: "#FFF",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  card: {
    backgroundColor: "#FFF",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  cardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  label: {
    fontSize: 16,
    color: "#555",
    fontWeight: "600",
  },
  value: {
    fontSize: 16,
    color: "#333",
  },
});

export default CreateOktaUserNavigate;
