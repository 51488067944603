import { actionTypesOperations as actionTypes } from "../constants/actionTypesOperations";
import { operationsState } from "../state/operations";

const operationsReducer = (state = operationsState, action: any) => {
  switch (action.type) {
    case actionTypes.ListOfOperations:
      return {
        ...state,
        operationsList: action.payload,
        isOperationsListLoaded: true,
      };
    case actionTypes.IsOperationsTableRefreshed:
      return {
        ...state,
        isOperationsListLoaded: action.payload,
      };
    case actionTypes.SelectedOperation:
      return {
        ...state,
        selectedOperation: action.payload,
      };
    case actionTypes.FilterOperations:
      return {
        ...state,
        operationsList: action.payload,
        isOperationsListLoaded: true,
      };
    case actionTypes.SearchOperations:
      return {
        ...state,
        isOperationsListLoaded: true,
        operationsList: action.payload,
      };
    default:
      return state;
  }
};

export default operationsReducer;
