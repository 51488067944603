import { useContext, useEffect, useState } from "react";
import { View, Text } from "../../../components/shared/Themed";
import { StyleSheet, ScrollView, Dimensions } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { ScanCard } from "../utils/scanCard";
import { TotalScanContent } from "../utils/totalScanContent";
import { LabelBannerWithSearchTags } from "../../../components/custom/Banners/LabelBannerWithSearchTags";

import { ScanDateFilter } from "./utils/ScanDateFilter";
import {
  formatDate,
  formatDateToMongoISOFormat,
  getFirstDayOfTheMonth,
  getYesterdayFromDate,
} from "../../../utils/dateUtility";
import { getAllOperatorsList } from "../../../services/barcodeService";
import { getStartDateForScanStats } from "./utils/ScanDateCalculations";
import { ScanPerLineMonthly } from "../utils/scanPerLineMonthlyContent";
import { ScanByOperatorMonthly } from "../utils/scanByOperatorMontlyContent";
import { AvgScanContent } from "../utils/avgScanContent";
import { ScanByOperator } from "../utils/scanByOperatorContent";
import { ScanByLinesContent } from "../utils/scanByLinesContent";
import Spinner from "../../../components/shared/Spinner";

export default function ScanDashboardScreen({ navigation }: any) {
  const isFocused = useIsFocused();
  const windowWidth = Dimensions.get("window").width;

  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );

  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [allOperators, setAllOperators] = useState<Array<string>>([]);
  const [selectedOperator, setSelectedOperator] = useState<string>("All");
  const [scanDate, setScanDate] = useState<Date>(new Date());

  type cardPlacement = "start" | "middle" | "end";

  const conditionalPadding = (): string => {
    return !isSmallDevice || !isMobileDevice ? "5px" : "0px";
  };

  const scanCardConditionalStyling = (placement: cardPlacement) => {
    let styling = null;
    if (placement === "middle") {
      styling = {
        paddingRight: conditionalPadding(),
        paddingLeft: conditionalPadding(),
      };
    } else if (placement === "start") {
      styling = {
        paddingRight: conditionalPadding(),
      };
    } else {
      styling = {
        paddingLeft: conditionalPadding(),
      };
    }

    return {
      ...styling,
      width: !isSmallDevice || !isMobileDevice ? windowWidth / 3 - 18 : "100%",
      marginBottom: isSmallDevice || isMobileDevice ? "2%" : "0px",
    };
  };

  const startDate = getStartDateForScanStats(scanDate);
  const endDate = formatDateToMongoISOFormat(scanDate);

  const fetchAllOperators = async () => {
    try {
      const startDate = getStartDateForScanStats(scanDate);
      const endDate = formatDateToMongoISOFormat(scanDate);

      const allOperatorsResults = await getAllOperatorsList(startDate, endDate);
      setAllOperators(["All", ...allOperatorsResults.data.allOperatorsList]);
      setIsDataLoading(allOperatorsResults.isLoading);
    } catch (error) {
      setAllOperators([] as unknown as Array<string>);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    let isMounted: boolean = true;

    if (isMounted) {
      setIsDataLoading(true);
      fetchAllOperators();
    }

    setTimeout(() => {
      setRefresh(false);
    }, 2000);
    return () => {
      isMounted = false;
    };
  }, [isFocused, isRefresh]);

  useEffect(() => {
    setIsDataLoading(true);
    setSelectedOperator("All");
    fetchAllOperators();
  }, [scanDate]);

  return (
    <ScrollView style={[styles.bgWhite]}>
      <View>
        {!isDataLoading ? (
          <>
            <LabelBannerWithSearchTags
              label="Quick Filter"
              isMobileDevice={isMobileDevice}
              isSmallDevice={isSmallDevice}
              filterCategory={selectedOperator}
              setFilterCategory={setSelectedOperator}
              filterCategoryList={allOperators}
              bannerHeight="52px"
              childern={
                !isSmallDevice || !isMobileDevice ? (
                  <ScanDateFilter
                    isMobileDevice={isMobileDevice}
                    isSmallDevice={isSmallDevice}
                    scanDate={scanDate}
                    setScanDate={setScanDate}
                    setRefresh={setRefresh}
                  />
                ) : (
                  <Text></Text>
                )
              }
            />
            {(isMobileDevice || isSmallDevice) && (
              <LabelBannerWithSearchTags
                bannerHeight="52px"
                childern={
                  <>
                    <ScanDateFilter
                      isMobileDevice={isMobileDevice}
                      isSmallDevice={isSmallDevice}
                      scanDate={scanDate}
                      setScanDate={setScanDate}
                      setRefresh={setRefresh}
                    />
                  </>
                }
              />
            )}
          </>
        ) : (
          <Spinner
            size="small"
            color={"#00ff00"}
            styles={{ flexDirection: "row", marginTop: "1%" }}
          />
        )}
      </View>
      <View style={[styles.bgWhite]}>
        <View style={[styles.container, styles.bgLightSilver]}>
          <View style={[styles.containerContent]}>
            <View
              style={{
                flexDirection:
                  !isSmallDevice || !isMobileDevice ? "row" : "column",
              }}
            >
              <View style={[scanCardConditionalStyling("start")]}>
                <ScanCard
                  title="Total Scans"
                  subTitle={`${formatDate(scanDate, "short")}`}
                  cardContent={
                    <TotalScanContent
                      selectedScanDate={scanDate}
                      startDate={startDate}
                      endDate={endDate}
                      operatorName={selectedOperator}
                      isRefresh={isRefresh}
                    />
                  }
                  cardTitleCSS={[styles.bgLightSilver]}
                  cardInnerContentCSS={{
                    maxHeight: "450px",
                    overflow: "scroll",
                  }}
                />
              </View>
              <View style={[scanCardConditionalStyling("middle")]}>
                <ScanCard
                  title="Scan By Location"
                  subTitle={`${formatDate(
                    getYesterdayFromDate(scanDate),
                    "short"
                  )} - ${formatDate(scanDate, "short")}`}
                  cardContent={
                    <ScanByLinesContent
                      selectedScanDate={scanDate}
                      startDate={startDate}
                      endDate={endDate}
                      operatorName={selectedOperator}
                      isRefresh={isRefresh}
                    />
                  }
                  cardTitleCSS={[styles.bgLightSilver]}
                  cardInnerContentCSS={{
                    maxHeight: "450px",
                    overflow: "scroll",
                  }}
                />
              </View>
              <View style={[scanCardConditionalStyling("middle")]}>
                <ScanCard
                  title="AVG. Scan"
                  subTitle={`${formatDate(scanDate, "short")}`}
                  cardContent={
                    <AvgScanContent
                      selectedScanDate={scanDate}
                      startDate={startDate}
                      endDate={endDate}
                      operatorName={selectedOperator}
                      isRefresh={isRefresh}
                    />
                  }
                  cardTitleCSS={[styles.bgLightSilver]}
                />
                <ScanCard
                  title="Scan By Operator"
                  subTitle={`${formatDate(scanDate, "short")}`}
                  cardContent={
                    <ScanByOperator
                      selectedScanDate={scanDate}
                      startDate={startDate}
                      endDate={endDate}
                      operatorName={selectedOperator}
                      isRefresh={isRefresh}
                    />
                  }
                  cardTitleCSS={[styles.bgLightSilver]}
                  cardCSS={{ marginTop: "2%" }}
                  cardInnerContentCSS={{
                    maxHeight: "200px",
                    overflow: "scroll",
                  }}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection:
                  !isSmallDevice || !isMobileDevice ? "row" : "column",
                marginTop: "1.5%",
              }}
            >
              <View
                style={[scanCardConditionalStyling("start"), { flexGrow: 0.5 }]}
              >
                <ScanCard
                  title="Monthly Scan By Operators"
                  subTitle={`${formatDate(
                    getFirstDayOfTheMonth(scanDate),
                    "short"
                  )} - ${formatDate(scanDate, "short")}`}
                  cardContent={
                    <ScanByOperatorMonthly
                      selectedScanDate={scanDate}
                      startDate={startDate}
                      endDate={endDate}
                      operatorName={selectedOperator}
                      isRefresh={isRefresh}
                    />
                  }
                  cardTitleCSS={[styles.bgLightSilver]}
                  cardInnerContentCSS={{
                    maxHeight: "450px",
                    overflow: "scroll",
                  }}
                />
              </View>
              <View
                style={[
                  scanCardConditionalStyling("middle"),
                  { flexGrow: 0.5 },
                ]}
              >
                <ScanCard
                  title="Monthly Scan Per Line"
                  subTitle={`${formatDate(
                    getFirstDayOfTheMonth(scanDate),
                    "short"
                  )} - ${formatDate(scanDate, "short")}`}
                  cardContent={
                    <ScanPerLineMonthly
                      selectedScanDate={scanDate}
                      startDate={startDate}
                      endDate={endDate}
                      operatorName={selectedOperator}
                      isRefresh={isRefresh}
                    />
                  }
                  cardTitleCSS={[styles.bgLightSilver]}
                  cardInnerContentCSS={{
                    maxHeight: "450px",
                    overflow: "scroll",
                  }}
                />
              </View>
            </View>
            <Text
              style={{ fontSize: 10, fontStyle: "italic", marginTop: "1%" }}
            >
              <Text style={{ fontWeight: "bold" }}>Note:</Text>{" "}
              <Text style={styles.fontUnderline}>Line 1</Text> = 02PRD10001,{" "}
              <Text style={styles.fontUnderline}>Line 2</Text> = 02PRD10002 and{" "}
              <Text style={styles.fontUnderline}>Line 3</Text>= 02PRD10003
            </Text>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  bgSkyBlue: {
    backgroundColor: "rgba(47, 149, 220, 0.5)",
  },
  colorWhite: {
    color: "white",
  },
  bgWhite: {
    backgroundColor: "white",
  },
  bgLightSilver: {
    backgroundColor: "#f2f2f2",
  },
  fontUnderline: { textDecorationLine: "underline" },
  label: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    padding: "2%",
    width: "100%",
  },
  cardTitleStyles: {
    color: "rgb(128, 128, 128)",
    fontSize: 18,
    fontWeight: "bold",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  cardTitle: {
    borderWidth: 1,
    borderColor: "rgba(216, 216, 216, 0.4)",
  },
  bold: {
    fontWeight: "bold",
  },
  fab: {
    bottom: -10,
    position: "absolute",
    zIndex: 1,
  },
});
