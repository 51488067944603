import { Text, View } from "../shared/Themed";
import useColorScheme from "../../hooks/useColorScheme";

let colorScheme;
type PropsFunction = () => void;

export default function TabTitle(props: {
  name: String;
  route?: Object;
  navigation?: PropsFunction;
  routeTo?: String;
  routeToScreen?: Object;
  centered?: boolean;
}) {
  colorScheme = useColorScheme();
  return (
    <View
      style={{
        flexDirection: "row",
        height: "8%",
        borderTopWidth: 0.1,
        borderBottomWidth: 3,
        borderColor: colorScheme === "light" ? "darkgrey" : "white",
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        marginBottom: 10,
        justifyContent: props.centered ? "center" : "flex-start",
        alignItems: "center",
      }}
    >
      {!props.centered && (
        <View
          style={{
            width: "25%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
      <View
        style={{
          flexDirection: "column",
          alignItems: props.centered ? "center" : "flex-start", // Adjust text alignment
          justifyContent: "center",
          flex: props.centered ? undefined : 1, // Occupy full width if centered
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: props.centered ? "center" : "left", // Adjust text alignment
              color: colorScheme === "light" ? "grey" : "white",
            }}
          >
            {props.name}
          </Text>
        </View>
      </View>
    </View>
  );
}
