import React, { useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Text, View } from "../../shared/Themed";
import { ReadOnlyProps } from "../../../utils/templates";
import { BadgeHorizontalList } from "../Badge/BadgeHorizontalList";
import { OperationBadgeHorizontalList } from "../Badge/OperationBadgeHorizontalList";

interface Props {
  childern?: React.ReactChild;
  filterCategoryList?: Array<string>;
  isSmallDevice?: boolean;
  isMobileDevice?: boolean;
  label?: string;
  filterCategory?: string;
  bannerHeight?: string;
  setFilterCategory?: (event: string) => void;
  noBorder?: boolean;
  titleEnabled?: boolean;
  tableHeaders?: any;
}

export const OperationLabelBannerWithSearchTags = ({
  filterCategory,
  isSmallDevice,
  isMobileDevice,
  label,
  childern,
  filterCategoryList,
  bannerHeight = "50px",
  setFilterCategory,
  noBorder,
  titleEnabled,
  tableHeaders,
}: ReadOnlyProps<Props>) => {
  return (
    <View
      style={[
        styles.container,
        (noBorder || isSmallDevice) && { borderWidth: 0 },
      ]}
    >
      <View
        style={[
          styles.box,
          { height: bannerHeight },
          (noBorder || isSmallDevice) && { borderWidth: 0 },
        ]}
      >
        {filterCategoryList && filterCategoryList?.length > 0 && (
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <View style={styles.filterBox}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                {((!isSmallDevice && !isMobileDevice) || titleEnabled) && (
                  <Text style={styles.title}>{label}</Text>
                )}
                <OperationBadgeHorizontalList
                  itemsList={filterCategoryList}
                  filterCategory={filterCategory || ""}
                  setFilterCategory={setFilterCategory!}
                  tableHeaders={tableHeaders}
                />
              </View>
            </View>
          </ScrollView>
        )}
        {childern}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    borderWidth: 0.1,
    width: "100%",
    borderRadius: 2,
    borderColor: "#F5F5F5",
  },
  box: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: "darkgrey",
  },
  filterBox: { paddingLeft: "0.5%", paddingRight: "1%", flexDirection: "row" },
  title: {
    marginLeft: "10px",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: "5px",
  },
});
