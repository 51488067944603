import { useIsFocused, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Text } from "react-native-paper";

interface Props {
  navigateBack: () => void;
  mobileDevice: boolean;
  confirmationType: string;
  onSubmit: (form: any) => void;
  form: any;
}

const OrderScreenFourth: React.FC<Props> = ({
  confirmationType,
  navigateBack,
  mobileDevice,
  onSubmit,
  form,
}) => {
  const { control, handleSubmit, reset } = form;

  const [approved, setApproved] = useState(false);

  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const reject = () => {
    navigateBack();
  };

  const approve = async (type: string) => {
    handleSubmit(onSubmit)();
  };

  const draftApprove = () => {
    console.log("This is draft approve");
    console.log("Form before update: ", form);

    // Update orderStatus to 'draft' before submitting the form
    control._formValues.orderStatus = "Draft"; // Update the orderStatus here

    // Call the onSubmit function with the updated form
    onSubmit(control._formValues); // Make sure onSubmit is invoked with the updated form
  };

  const reservationApprove = () => {
    approve("reservation");
  };

  const approvedView = () => {
    if (confirmationType == "draft")
      return (
        <View>
          <Text style={styles.textCenter}>Draft successfully saved</Text>
          <ActivityIndicator
            size="large"
            color="lightgrey"
            style={{ marginVertical: 5 }}
          />
          <Text style={{ fontSize: 12, color: "red", textAlign: "center" }}>
            Redirecting...
          </Text>
        </View>
      );
    else
      return (
        <View>
          <Text style={styles.textCenter}>Successfully Submitted</Text>
          <ActivityIndicator
            size="large"
            color="lightgrey"
            style={{ marginVertical: 5 }}
          />
          <Text style={{ fontSize: 12, color: "red", textAlign: "center" }}>
            Redirecting...
          </Text>
        </View>
      );
  };

  const confirmationView = () => {
    if (confirmationType == "draft")
      return (
        <View>
          <Text style={[styles.textCenter, { paddingBottom: 15 }]}>
            Would you like to save this as a draft?
          </Text>
          <View style={styles.btnContainer}>
            <View>
              <TouchableOpacity
                onPress={draftApprove}
                style={styles.confirmationBtns}
              >
                <Text style={styles.confirmationBtnsText}>Yes</Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                onPress={reject}
                style={styles.confirmationBtns}
              >
                <Text style={styles.confirmationBtnsText}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    else
      return (
        <View>
          <Text style={[styles.textCenter, { paddingBottom: 15 }]}>
            Are you sure you are ready to submit this order request?
          </Text>
          <View style={styles.btnContainer}>
            <View>
              <TouchableOpacity
                onPress={reservationApprove}
                style={styles.confirmationBtns}
              >
                <Text style={styles.confirmationBtnsText}>Yes</Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                onPress={reject}
                style={styles.confirmationBtns}
              >
                <Text style={styles.confirmationBtnsText}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
  };

  return (
    <View
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      {approved ? approvedView() : confirmationView()}
    </View>
  );
};

const styles = StyleSheet.create({
  textCenter: {
    textAlign: "center",
  },
  btnContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  confirmationBtns: {
    backgroundColor: "#D9D9D9",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 25,
  },
  confirmationBtnsText: {
    color: "#6C6C6C",
  },
});

export default OrderScreenFourth;
