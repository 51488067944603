import { combineReducers } from "redux";

import containersReducer from "../reducers/containersReducer";
import itemsReducer from "./itemsReducer";
import configurationReducer from "./configurationsReducer";
import artworkReducer from "./artworkReducer";
import reportsReducer from "./reportsReducer";
import operationsReducer from "./operationsReducer";
import { deliveryReducer, stockTransferReducer } from "./deliveryReducer";

const rootReducer = combineReducers({
  configurations: configurationReducer,
  containers: containersReducer,
  items: itemsReducer,
  delivery: deliveryReducer,
  stockTransfer: stockTransferReducer,
  artwork: artworkReducer,
  reports: reportsReducer,
  operations: operationsReducer,
});

export default rootReducer;
