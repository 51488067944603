import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, TextInput } from "react-native";
import { Button } from "react-native-paper";
import { Text, View } from "../../shared/Themed";
import Icon from "react-native-vector-icons/FontAwesome5";
import { ReadOnlyProps } from "../../../utils/templates";
import DeviceOrientationContext from "../../context/DeviceOrientationContext";
import { exportDataToExcel } from "../../../utils/exportDataToExcel";
import {
  getSalesSearchOperations,
  getSearchOperations,
} from "../../../services/operationService";
import { useSelector } from "react-redux";

interface Props {
  childern?: React.ElementType;
  titleName: string;
  isSmallDevice?: boolean;
  isMobileDevice?: boolean;
  isEditable?: boolean;
  ref?: any;
  isResetFields?: boolean;
  setRefresh: (event: any) => void;
  setEditable?: (event: any) => void;

  //for excel download
  dataToExport?: Array<unknown>;
  fileName?: string;
  sheetName?: string;

  // J
  padding?: boolean;
  disableTitle?: boolean;
  noBorder?: boolean;
}

export const SearchOperationLabelBannerWithSearchBar = (
  props: ReadOnlyProps<Props>
) => {
  const Children = (props.childern || {}) as React.ElementType;
  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );
  const userInfo = useSelector((state: any) => state.configurations.userInfo);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = (searchTerm: string) => {
    if (searchTerm && searchTerm != "") {
      getSalesSearchOperations(userInfo.login, {}, {}, searchTerm);
    }
  };

  useEffect(() => {
    // if search text is empty, then return all data
    if (searchTerm && searchTerm.trim().length === 0) {
      handleSearch("");
      return;
    }

    handleSearch(searchTerm.trim());
  }, [searchTerm]);

  useEffect(() => {
    if (props.isResetFields) {
      setSearchTerm("");
    }
  }, [props.isResetFields]);

  const submitSearch = () => {
    handleSearch(searchTerm.trim());
  };

  const isExportAllowed = () => {
    return (
      !isSmallDevice &&
      !isMobileDevice &&
      props.dataToExport &&
      props.dataToExport.length > 0
    );
  };

  return (
    <View>
      <View style={[styles.container, props.noBorder && { borderWidth: 0 }]}>
        <View
          style={[
            {
              flexDirection: "row",
              width: "100%",
              height: 50,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: props.isEditable ? "#fece85" : "white",
              borderBottomWidth: 1,
              borderColor: "darkgrey",
              paddingHorizontal: props.padding ? 35 : 0,
            },
            props.noBorder && { borderWidth: 0 },
          ]}
        >
          {!isSmallDevice &&
            !isMobileDevice &&
            props.disableTitle == undefined && (
              <Text
                style={[styles.title, { width: "80%" }]}
                // TODO: Enable only when auto-theming is applied
                // lightColor="rgba(0,0,0,0.8)"
                // darkColor="rgba(255,255,255,0.8)"
              >
                {props.titleName}
              </Text>
            )}
          {props.isEditable && (
            <Button
              mode="contained"
              uppercase={false}
              onPress={() => props.setEditable?.(!props.isEditable)}
              color="grey"
              style={{ marginRight: "1%" }}
            >
              Cancel
            </Button>
          )}
          <TouchableOpacity
            onPress={() => {
              setSearchTerm("");
              props.setRefresh(true);
            }}
          >
            <Icon name={"sync"} size={15} color="#000080" />
          </TouchableOpacity>
          <TextInput
            ref={props.ref}
            style={[styles.input, { width: !props.isMobileDevice ? 250 : 180 }]}
            onChangeText={(value) => {
              setSearchTerm(value);
            }}
            value={searchTerm || ""}
            placeholder="Type your search"
            onSubmitEditing={() =>
              searchTerm.trim().length < 3 ? {} : submitSearch()
            }
          />
          <View style={{ flexDirection: "row" }}>
            <Button
              icon="magnify"
              disabled={(searchTerm && searchTerm.trim().length < 3) || false}
              mode="contained"
              uppercase={false}
              onPress={() => submitSearch()}
              color={props.isEditable ? "#7F00FF" : "#b22442"}
              style={isExportAllowed() ? styles.searchBtn : styles.margin_10PX}
              contentStyle={{
                paddingTop: "10%",
                paddingBottom: "10%",
                paddingLeft: "25%",
              }}
            >
              {" "}
            </Button>
            {isExportAllowed() && (
              <Button
                icon="file-excel"
                mode="contained"
                uppercase={false}
                onPress={() =>
                  exportDataToExcel(
                    props.dataToExport || [],
                    props.fileName,
                    props.sheetName
                  )
                }
                color="rgb(47, 149, 220)"
                style={styles.excelBtn}
                contentStyle={{
                  paddingTop: "10%",
                  paddingBottom: "10%",
                  paddingLeft: "25%",
                }}
              >
                {" "}
              </Button>
            )}
          </View>
        </View>
        {props.childern && <Children {...props} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    borderWidth: 0.1,
    width: "100%",
    borderRadius: 2,
    borderColor: "#F5F5F5",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: "2%",
  },
  input: {
    height: 35,
    borderColor: "#F5F5F5",
    margin: 12,
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  padding_1_Percent: {
    padding: "1%",
  },
  margin_10PX: { marginRight: "10px" },
  searchBtn: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: "1%",
  },
  excelBtn: {
    marginRight: "10px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
