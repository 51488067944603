import React, { useContext, useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import useColorScheme from "../../hooks/useColorScheme";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { LabelBannerWithSearchBar } from "../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../components/custom/DataTable/DataTableWithPagination2";
import { TouchableOpacity } from "react-native-gesture-handler";
import { headers, mdHeaders, smHeaders } from "./utils/operationHeaders";
import {
  mdNestedHeaders as ordersMdHeaders,
  nestedHeaders as ordersHeader,
  smNestedHeaders as ordersSmHeaders,
} from "./utils/nestedHeaders";

import { ordersCollectionModel } from "../../models/ordersModel";
import { getAllOperations } from "../../services/operationService";
import { OperationLabelBannerWithSearchTags } from "../../components/custom/Banners/OperationLabelBannerWithSearchTags";
import Spinner from "../../components/shared/Spinner";
import { OperationLabelBannerWithSearchBar } from "../../components/custom/Banners/OperationLabelBannerWithSearchBar";

export default function OperationsManagementScreen({ route, navigation }: any) {
  const colorScheme = useColorScheme();
  const isFocused = useIsFocused();
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const operationsFilterCategoryList = [
    "All",
    "Draft",
    "Pending",
    "Cancelled",
    "Processed",
    "Dispatched",
    "Completed",
    "Last 3 months",
  ];

  const { operationsList, isOperationsListLoaded } = useSelector(
    (state: any) => state.operations
  );

  const combinedOrders = operationsList.reduce((acc, item) => {
    if (item.orders && Array.isArray(item.orders)) {
      return acc.concat(item.orders);
    }
    return acc;
  }, []);

  const { isLargeDevice, isMediumDevice, isMobileDevice, isSmallDevice } =
    useContext(DeviceOrientationContext);

  const [_filterCategory, _setFilterCategory] = useState<string>("all");
  const [lastRefresh, setLastRefresh] = useState<string>("");
  const [_isDisplayViewOn, _setIsDisplayViewOn] = useState(false);
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );

  const [nestedTableHeaders, setNestedTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      ordersHeader
    );
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const isUserAdmin = true;
  const columnsToNotHaveThousandsSeparator: Array<string> = ["Packing #"];
  const [disableHeader, setDisableHeader] = useState<boolean>(false);

  const handleSearch = () => {
    return true;
  };
  const onHandleRowClick = () => {
    return true;
  };
  const [selected, setSelected] = useState<ordersCollectionModel>(
    {} as unknown as ordersCollectionModel
  );
  const onHandleDeleteRow = () => {
    return true;
  };
  const onHandleSortColumn = () => {
    return true;
  };

  const navigateToOrder = (order: any, action: string) => {
    navigation.navigate("SingleOperation", { order, action });
  };

  useEffect(() => {
    setRefresh(false);
    setTableHeaders(
      isLargeDevice ? headers : isMediumDevice ? mdHeaders : smHeaders
    );
    setNestedTableHeaders(
      isLargeDevice
        ? ordersHeader
        : isMediumDevice
        ? ordersMdHeaders
        : ordersSmHeaders
    );
    setDisableHeader(isSmallDevice);
    getAllOperations(tableHeaders);
  }, [isFocused, isRefresh]);

  const PageHeader = () => {
    if (isSmallDevice) return <SmPageHeader />;
    else return <DefaultPageHeader />;
  };

  const SmPageHeader = () => {
    return (
      <>
        <View style={styles.drawerHeader}>
          <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
            <Text>
              <MaterialCommunityIcons name="menu" size={24} color="black" />
            </Text>
          </TouchableOpacity>
          <View style={{ flex: 1, paddingLeft: 15 }}>
            <Text style={{ fontSize: 18, fontWeight: "500" }}>
              Order Status
            </Text>
          </View>
          <View
            style={{
              justifyContent: "center",
              borderBottomWidth: isSmallDevice || isMobileDevice ? 0 : 1,
              borderBottomColor: "#aeaeae",
            }}
          >
            <TouchableOpacity
              // onPress={() => navigation.navigate("ManageOperations")}
              onPress={() => navigation.navigate("PlaceOrder")}
            >
              <Text style={styles.newOrder}>
                New Order Request{" "}
                <MaterialCommunityIcons
                  name="plus-thick"
                  size={15}
                  color="#74BBFF"
                />{" "}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <OperationLabelBannerWithSearchTags
            isMobileDevice={isMobileDevice}
            isSmallDevice={isSmallDevice}
            label="Quick Filter"
            noBorder={true}
            filterCategoryList={operationsFilterCategoryList}
            filterCategory={_filterCategory}
            setFilterCategory={_setFilterCategory}
            titleEnabled={true}
            tableHeaders={tableHeaders}
          />
        </View>
        <OperationLabelBannerWithSearchBar
          titleName={`Total Items: ${operationsList.length}`}
          setRefresh={setRefresh}
          dataToExport={combinedOrders}
          padding={true}
          fileName="orderReportScreen"
          sheetName="Order Report Screen"
        />
      </>
    );
  };

  const DefaultPageHeader = () => {
    return (
      <View>
        <View style={styles.drawerHeader}>
          <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
            <Text>
              <MaterialCommunityIcons name="menu" size={24} color="black" />
            </Text>
          </TouchableOpacity>
          <View style={{ flex: 1, paddingLeft: 15 }}>
            <Text style={{ fontSize: 18, fontWeight: "500" }}>
              Order Status
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#B5B5B5",
          }}
        >
          <View style={{ flex: 1 }}>
            <OperationLabelBannerWithSearchTags
              isMobileDevice={isMobileDevice}
              isSmallDevice={isSmallDevice}
              label="Quick Filter"
              noBorder={true}
              filterCategoryList={operationsFilterCategoryList}
              filterCategory={_filterCategory}
              setFilterCategory={_setFilterCategory}
              titleEnabled={true}
              tableHeaders={tableHeaders}
            />
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ justifyContent: "center", paddingHorizontal: 20 }}>
              <TouchableOpacity
                // onPress={() => navigation.navigate("ManageOperations")}
                onPress={() => navigation.navigate("PlaceOrder")}
              >
                <Text style={styles.newOrder}>New Order Request +</Text>
              </TouchableOpacity>
            </View>
            <OperationLabelBannerWithSearchBar
              titleName={`Total Items: ${operationsList.length}`}
              setRefresh={setRefresh}
              dataToExport={combinedOrders}
              fileName="orderReportScreen"
              sheetName="Order Report Screen"
              disableTitle={true}
              noBorder={true}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <ScrollView style={{ flex: 1, backgroundColor: "#fff" }}>
      <PageHeader />
      {!isOperationsListLoaded ? (
        <View>
          <Spinner
            size="small"
            color="green"
            styles={{
              flexDirection: "row",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          />
        </View>
      ) : (
        <DataTableWithPagination
          headers={tableHeaders}
          nestedHeader={nestedTableHeaders}
          rows={operationsList}
          itemsPerPage={20}
          isDataLoading={isSearched}
          useModal={true}
          isRowsExpandable={true}
          setModalVisible={setModalVisible}
          handleRowClick={onHandleRowClick}
          setSelected={setSelected}
          isToExpandAllRows={_isDisplayViewOn}
          rowKeyValue="_id"
          nestedItemKeyValue="orders"
          isDetail={true}
          isEdit={isUserAdmin}
          isDelete={isUserAdmin}
          columnsToNotHaveThousandSeparator={columnsToNotHaveThousandsSeparator}
          handleDeleteRow={onHandleDeleteRow}
          handleSortColumn={onHandleSortColumn}
          operationsScreen={false}
          disableHeader={disableHeader}
          operationActions={navigateToOrder}
        />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  newOrder: {
    color: "#74BBFF",
    fontSize: 12,
  },
  drawerHeader: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderBottomWidth: 0.5,
    borderColor: "#ededed",
  },
});
