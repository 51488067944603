import { actionTypesReports } from "../constants/actionTypesReports";
import store from "../configureStore";
import allActions from "../actions";
import {
  pmxDetailModel,
  pmxOverviewModel,
  productModel,
  deliveryModel,
  customerStatusModel,
  customerOrderModel,
  productionStockModel,
  rawMaterialStockModel,
  rawMaterialStockOverviewModel,
  stockModel,
} from "../../models/reportsModel";

const { dispatch } = store;

export const ReportsDispatcher = (
  actionType: actionTypesReports,
  data: unknown
) => {
  const reportsAction = allActions.reportsActions;

  switch (actionType) {
    case actionTypesReports.ListOfStockReport:
      dispatch(reportsAction.setStockReportList(data as Array<stockModel>));
      break;
    case actionTypesReports.LastRefreshStockReport:
      dispatch(reportsAction.setLastRefreshStockReport(data as string));
      break;
    case actionTypesReports.ListOfProductReport:
      dispatch(reportsAction.setProductReportList(data as Array<productModel>));
      break;
    case actionTypesReports.LastRefreshProductReport:
      dispatch(reportsAction.setLastRefreshProductReport(data as string));
      break;
    case actionTypesReports.ListOfCustomerStatus:
      dispatch(
        reportsAction.setCustomerStatusReportList(data as Array<customerStatusModel>)
      );
      case actionTypesReports.ListOfCustomerOrder:
        dispatch(
          reportsAction.setCustomerOrderReportList(data as Array<customerOrderModel>)
        );
    case actionTypesReports.ListOfPmxInventory:
      dispatch(
        reportsAction.setPmxInventoryReportList(data as Array<pmxOverviewModel>)
      );
    case actionTypesReports.ListOfPmxInventoryDetail:
      dispatch(
        reportsAction.setPmxInventoryDetailReportList(
          data as Array<pmxDetailModel>
        )
      );
    case actionTypesReports.ListOfDelivery:
      dispatch(
        reportsAction.setDeliveryReportList(
          data as Array<deliveryModel>
        )
      );
    case actionTypesReports.ListOfProductionStockReport:
      dispatch(
        reportsAction.setProductionStockReportList(
          data as Array<productionStockModel>
        )
      );
      break;
    case actionTypesReports.ListOfRawMaterialOverviewStockReport:
      dispatch(
        reportsAction.setRawMaterialOverviewStockReportList(
          data as Array<rawMaterialStockOverviewModel>
        )
      );
      break;
    case actionTypesReports.ListOfRawMaterialStockReport:
      dispatch(
        reportsAction.setRawMaterialStockReportList(
          data as Array<rawMaterialStockModel>
        )
      );
      break;
  }
};
