import React from "react";
import { StyleSheet, Text, TextInput, TouchableOpacity } from "react-native";
import Icons from "@expo/vector-icons/MaterialCommunityIcons";
import { View } from "../shared/Themed";
import { ReadOnlyProps } from "../../utils/templates";

interface Props {
  required?: boolean;
  title: string;
  placeholder?: string;
  value: string;
  width: string;
  editable?: boolean;
  blurOnSubmit?: boolean;
  isMultiline?: boolean;
  refInput?: any;
  height?: number;
  isTextEntrySecure?: boolean;
  showSoftInputOnFocus?: boolean;
  setChangeText: (event: any) => void;
  handleOnSubmitEditing?: (event: any) => void;
  autofocus?: boolean;
}

export const TextInputControlOrder: React.FC<ReadOnlyProps<Props>> = ({
  required = false,
  title,
  placeholder = "",
  value,
  width,
  editable = true,
  blurOnSubmit = true,
  isMultiline = false,
  isTextEntrySecure = false,
  showSoftInputOnFocus = true,
  refInput,
  height = 28,
  setChangeText,
  handleOnSubmitEditing,
  autofocus = false,
}: ReadOnlyProps<Props>) => {
  return (
    <View style={{ flexDirection: "column", width: width, marginBottom: 15 }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text style={[styles.label, { color: "black" }]}>
            {title}{" "}
            {!required && (
              <Text style={{ fontSize: 10, color: "lightgrey" }}>
                (Optional)
              </Text>
            )}
          </Text>
        </View>
        <View
          style={isMultiline ? { flex: 1.75, paddingLeft: 15 } : { flex: 1 }}
        >
          <TextInput
            style={[styles.input, { height: height }]}
            ref={refInput}
            onChangeText={setChangeText}
            placeholder={placeholder}
            value={value}
            multiline={isMultiline}
            editable={editable}
            blurOnSubmit={blurOnSubmit}
            autoFocus={autofocus}
            onSubmitEditing={handleOnSubmitEditing}
            secureTextEntry={isTextEntrySecure}
            showSoftInputOnFocus={showSoftInputOnFocus}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  label: {
    fontSize: 15,
    textAlign: "left",
    fontWeight: "400",
  },
  input: {
    borderWidth: 1,
    borderRadius: 4,
    padding: "2%",
    fontSize: 14,
    borderColor: "#79747E",
    textAlign: "center",
  },
});
