import { DataTable } from "react-native-paper";
import React from "react";
import { Text, StyleSheet } from "react-native";

const StyledDataTable = ({
  products,
  totalBoxes,
  totalPieces,
  totalPrice,
  orderType,
}: {
  products: any[];
  totalBoxes: number;
  totalPieces: number;
  totalPrice: number;
  orderType: string;
}) => {
  return (
    <DataTable>
      {/* Header */}
      <DataTable.Header style={styles.datatableHeader}>
        <DataTable.Title style={styles.productColumn}>
          <Text style={styles.datatableHeaderItemText}>Product</Text>
        </DataTable.Title>
        <DataTable.Title numeric style={styles.orderQtyColumn}>
          <Text style={styles.datatableHeaderItemText}>Order Qty</Text>
        </DataTable.Title>
        <DataTable.Title numeric style={styles.uom}>
          <Text style={styles.datatableHeaderItemText}>UoM</Text>
        </DataTable.Title>
        <DataTable.Title numeric style={styles.price}>
          <Text style={styles.datatableHeaderItemText}>
            Price <Text style={styles.exGST}>(Ex GST)</Text>
          </Text>
        </DataTable.Title>
      </DataTable.Header>

      {/* Product Rows */}
      {products.length > 0 &&
        products.map((p) => (
          <React.Fragment key={p.ItemCode}>
            <DataTable.Row style={styles.productRow}>
              <DataTable.Cell style={styles.productColumn}>
                <Text style={styles.productName}>{p.name}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.orderQtyColumn}>
                {p.quantity}
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.uomColumn}>
                {p.uom}
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.priceColumn}>
                <Text style={styles.priceText}>
                  {orderType === "contract_order" ? "Contract" : p.price}
                </Text>
              </DataTable.Cell>
            </DataTable.Row>
          </React.Fragment>
        ))}

      {/* Summary Rows */}
      <DataTable.Row style={styles.summaryRow}>
        <DataTable.Cell style={styles.summaryCell}>
          <Text style={styles.summaryText}>Total Boxes:</Text>
        </DataTable.Cell>
        <DataTable.Cell numeric style={styles.summaryValueCell}>
          {totalBoxes}
        </DataTable.Cell>
      </DataTable.Row>

      <DataTable.Row style={styles.summaryRow}>
        <DataTable.Cell style={styles.summaryCell}>
          <Text style={styles.summaryText}>Total Pieces:</Text>
        </DataTable.Cell>
        <DataTable.Cell numeric style={styles.summaryValueCell}>
          {totalPieces}
        </DataTable.Cell>
      </DataTable.Row>

      <DataTable.Row style={styles.summaryRow}>
        <DataTable.Cell style={styles.summaryCell}>
          <Text style={styles.summaryText}>Total Amount:</Text>
        </DataTable.Cell>
        <DataTable.Cell numeric style={styles.summaryValueCell}>
          {orderType === "contract_order" ? "Contract" : totalPrice}
        </DataTable.Cell>
      </DataTable.Row>
    </DataTable>
  );
};

const styles = StyleSheet.create({
  datatableHeader: {
    backgroundColor: "#f8f9fa",
    borderBottomWidth: 1,
    borderBottomColor: "#dee2e6",
  },
  datatableHeaderItemText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#495057",
  },
  exGST: {
    fontSize: 10,
    color: "#6c757d",
  },
  productColumn: {
    flex: 2,
    paddingHorizontal: 10,
    flexDirection: "column",
    width: "auto",
  },
  productName: {
    fontWeight: "600",
    fontSize: 14,
    color: "#212529",
    flexWrap: "wrap",
    width: "100%",
    overflow: "visible",
  },
  orderQtyColumn: { flex: 1, marginRight: "2px" },
  uom: { flex: 1 },
  price: { flex: 1.5 },
  productRow: {
    backgroundColor: "#ffffff",
    borderBottomWidth: 1,
    borderBottomColor: "#e9ecef",
    paddingVertical: 10,
  },
  productId: { fontSize: 12, color: "#868e96" },
  priceText: { fontSize: 14, fontWeight: "500", color: "#212529" },
  summaryRow: {
    backgroundColor: "#f1f3f5",
    paddingVertical: 12,
  },
  summaryCell: { flex: 2, paddingLeft: 10 },
  summaryText: { fontSize: 14, fontWeight: "bold", color: "#495057" },
  summaryValueCell: { flex: 1, textAlign: "right", paddingRight: 10 },
  uomColumn: {
    flex: 1,
  },
  priceColumn: {
    flex: 1.5,
  },
});

export default StyledDataTable;
