import { actionTypesReports as actionTypes } from "./../constants/actionTypesReports";
import {
  stockModel,
  productModel,
  customerStatusModel,
  customerOrderModel,
  pmxOverviewModel,
  pmxDetailModel,
  productionStockModel,
  rawMaterialStockOverviewModel,
  rawMaterialStockModel,
  deliveryModel,
} from "../../models/reportsModel";

const setStockReportList = (data: Array<stockModel>) => {
  return {
    type: actionTypes.ListOfStockReport,
    payload: data,
  };
};

const setLastRefreshStockReport = (data: string) => {
  return {
    type: actionTypes.LastRefreshStockReport,
    payload: data,
  };
};

const setCustomerStatusReportList = (data: Array<customerStatusModel>) => {
  return {
    type: actionTypes.ListOfCustomerStatus,
    payload: data,
  };
};

const setCustomerOrderReportList = (data: Array<customerOrderModel>) => {
  return {
    type: actionTypes.ListOfCustomerOrder,
    payload: data,
  };
};

const setPmxInventoryReportList = (data: Array<pmxOverviewModel>) => {
  return {
    type: actionTypes.ListOfPmxInventory,
    payload: data,
  };
};

const setPmxInventoryDetailReportList = (data: Array<pmxDetailModel>) => {
  return {
    type: actionTypes.ListOfPmxInventoryDetail,
    payload: data,
  };
};

const setDeliveryReportList = (data: Array<deliveryModel>) => {
  return {
    type: actionTypes.ListOfDelivery,
    payload: data,
  };
};

const setProductReportList = (data: Array<productModel>) => {
  return {
    type: actionTypes.ListOfProductReport,
    payload: data,
  };
};

const setLastRefreshProductReport = (data: string) => {
  return {
    type: actionTypes.LastRefreshProductReport,
    payload: data,
  };
};

const setProductionStockReportList = (data: Array<productionStockModel>) => {
  return {
    type: actionTypes.ListOfProductionStockReport,
    payload: data,
  };
};

const setRawMaterialOverviewStockReportList = (
  data: Array<rawMaterialStockOverviewModel>
) => {
  return {
    type: actionTypes.ListOfRawMaterialOverviewStockReport,
    payload: data,
  };
};

const setRawMaterialStockReportList = (data: Array<rawMaterialStockModel>) => {
  return {
    type: actionTypes.ListOfRawMaterialStockReport,
    payload: data,
  };
};

export default {
  setStockReportList,
  setLastRefreshStockReport,
  setCustomerStatusReportList,
  setPmxInventoryReportList,
  setPmxInventoryDetailReportList,
  setProductReportList,
  setLastRefreshProductReport,
  setProductionStockReportList,
  setRawMaterialOverviewStockReportList,
  setRawMaterialStockReportList,
  setCustomerOrderReportList,
  setDeliveryReportList,
};
