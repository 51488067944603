import { useState, useEffect, useContext } from "react";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  Image,
} from "react-native";

import * as Okta from "../../services/oktaAuthService";
import oktaConfig from "../../configs/okta";
import { useSelector } from "react-redux";
import { clearSessionStorage } from "../../services/utils/storageService";
import { SimpleLineIcons } from "@expo/vector-icons";

function CustomDrawerContent(props: any) {
  const [mainDrawer, setMainDrawer] = useState(true);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const { isMobileDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );
  const { userInfo } = useSelector((state: any) => state.configurations);
  const { logo } = useSelector((state: any) => state.configurations);

  const toggleMainDrawer = () => {
    setMainDrawer(true);
    setFilteredItems([]);
  };

  const logoutHandler = () => {
    Okta.deleteSession().then(() => {
      clearSessionStorage();
      window.location.href = `${oktaConfig.api.redirectUri}`;
    });
  };

  const onItemParentPress = (key: any) => {
    const filteredMainDrawerRoutes = props.drawerItems.find((e: any) => {
      return e && e.key === key;
    });
    if (filteredMainDrawerRoutes.routes.length === 1) {
      const selectedRoute = filteredMainDrawerRoutes.routes[0];
      props.navigation.toggleDrawer();
      props.navigation.navigate(selectedRoute.nav, {
        screen: selectedRoute.routeName,
      });
    } else {
      setMainDrawer(false);
      setFilteredItems(filteredMainDrawerRoutes);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      //setLogo(logo);
    }
    return () => {
      isMounted = false;
    };
  }, [logo]);

  function renderMainDrawer() {
    return (
      <View>
        {props.drawerItems.map(
          (parent: any) =>
            parent && (
              <View key={parent.key}>
                <TouchableOpacity
                  key={parent.key}
                  testID={parent.key}
                  onPress={() => {
                    onItemParentPress(parent.key);
                  }}
                >
                  <View style={styles.parentItem}>
                    <Text style={[styles.title]}>{parent.title}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            )
        )}
        {renderLogoutBtn()}
      </View>
    );
  }

  function renderFilteredItemsDrawer() {
    return (
      <View>
        <TouchableOpacity
          onPress={() => toggleMainDrawer()}
          style={styles.backButtonRow}
        >
          <Text style={[styles.backButtonText, styles.title]}>{"Back"}</Text>
        </TouchableOpacity>
        {filteredItems.routes.map((route: any) => {
          return (
            <TouchableOpacity
              key={route.routeName}
              testID={route.routeName}
              onPress={() =>
                props.navigation.navigate(route.nav, {
                  screen: route.routeName,
                })
              }
              //style={styles.item}
            >
              <Text style={styles.title}>{route.title}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }

  function renderLogoutBtn() {
    return (
      <View>
        <TouchableOpacity
          onPress={() => logoutHandler()}
          testID="customDrawer-logout"
        >
          <View style={styles.parentItem}>
            <Text style={styles.title}>{"Log out"}</Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  // J
  function renderBottomSettings() {
    return (
      <View style={styles.accountSetting}>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.accountUserImgContainer}>
            <Image
              source={{ uri: "" }}
              style={styles.accountUserImg}
              resizeMode="contain"
            />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.accountUserText}>{userInfo?.display_name}</Text>
          </View>
          <TouchableOpacity
            style={{ justifyContent: "center", paddingRight: 10 }}
            onPress={() => {
              props.navigation.navigate("SettingsM");
            }}
          >
            <View>
              <SimpleLineIcons name="options" size={16} color="black" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <ScrollView style={styles.drawerContainer} scrollEventThrottle={16}>
      <SafeAreaView
        style={styles.container}
        forceInset={{ top: "always", horizontal: "never" }}
      >
        <View style={styles.centered}>
          <Image
            source={{ uri: logo }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        {mainDrawer ? renderMainDrawer() : renderFilteredItemsDrawer()}
        {(isMobileDevice || isSmallDevice) && renderBottomSettings()}
      </SafeAreaView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 80,
    height: 80,
  },
  drawerContainer: {
    backgroundColor: "white",
  },
  container: {
    flex: 1,
    zIndex: 1000,
  },
  centered: {
    alignItems: "center",
  },
  parentItem: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#F0F0F0",
    paddingTop: 4,
    paddingBottom: 4,
  },
  title: {
    margin: 16,
    fontWeight: "bold",
    color: "#707B7C",
    fontSize: 12,
    textAlign: "left",
  },
  backButtonRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 17,
    paddingLeft: 3,
    borderBottomColor: "#F0F0F0",
    borderBottomWidth: 0.5,
  },
  backButtonText: {
    marginLeft: 10,
    color: "#F0F0F0",
  },

  // J
  accountSetting: {
    borderTopWidth: 1,
    borderTopColor: "#F0F0F0",
    paddingVertical: 2,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  row: {
    flexDirection: "row",
  },
  accountUserImgContainer: {
    paddingLeft: 15,
    justifyContent: "center",
  },
  accountUserImg: {
    width: 25,
    height: 25,
    backgroundColor: "grey",
    borderRadius: 50,
  },
  accountUserText: {
    paddingVertical: 16,
    paddingLeft: 14,
    fontSize: 14,
    fontWeight: "bold",
  },
});

export default CustomDrawerContent;
