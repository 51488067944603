import containerActions from "./containersActions";
import itemsActions from "./itemsActions";
import configurationsActions from "./configurationsActions";
import artworkActions from "./artworkActions";
import reportsActions from "./reportsActions";
import operationsActions from "./operationsActions"
import deliveryActions from "./deliveryActions";

const allActions = {
  configurationsActions,
  containerActions,
  artworkActions,
  itemsActions,
  reportsActions,
  operationsActions,
  deliveryActions
};

export default allActions;
