import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  ViewStyle,
  TextStyle,
} from "react-native";

interface OrderActionComponentProps {
  selectedOrder: { orderStatus: string }; // Define selectedOrder type if more details are available
  sapDocNumber: string;
  setSapDocNumber: (value: string) => void;
  handleProcessOrder: () => void;
}

const OrderActionComponent: React.FC<OrderActionComponentProps> = ({
  selectedOrder,
  sapDocNumber,
  setSapDocNumber,
  handleProcessOrder,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {selectedOrder.orderStatus === "Pending"
          ? "Process Your Order"
          : "Complete Order"}
      </Text>

      {selectedOrder.orderStatus === "Pending" && (
        <View style={styles.inputContainer}>
          <Text style={styles.label}>SAP Document Number</Text>
          <TextInput
            style={styles.input}
            value={sapDocNumber}
            onChangeText={setSapDocNumber}
            placeholder="Enter SAP Document Number"
          />
        </View>
      )}

      <Button
        title={
          selectedOrder.orderStatus === "Pending"
            ? "Process Order"
            : "Complete Order"
        }
        onPress={handleProcessOrder}
        color="#4E93D6"
      />
    </View>
  );
};

export default OrderActionComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center", // Vertically center the content
    alignItems: "center", // Horizontally center the content
    padding: 20,
  } as ViewStyle,

  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    textAlign: "center", // Center the text
  } as TextStyle,

  inputContainer: {
    marginBottom: 20,
    width: "100%", // Make the input container take full width
    alignItems: "center", // Center the content inside the container
  } as ViewStyle,

  label: {
    fontSize: 16,
    marginBottom: 5,
    textAlign: "center", // Center the label text
  } as TextStyle,

  input: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    backgroundColor: "#f9f9f9",
    width: "80%", // Adjust input width for better presentation
  } as TextStyle,
});
