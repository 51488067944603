import { AccessToken, IDToken } from "@okta/okta-auth-js";
import { tokenManager } from "../oktaAuthService";

// export const accessToken = async () => {
//   return (await tokenManager.get("accessToken")) as AccessToken;
// };

export const accessToken = async () => {
  const token = await tokenManager.get("accessToken");
  if (token === undefined) {
    // Fallback to sessionStorage or another custom storage
    const sessionToken = sessionStorage.getItem("accessToken");
    if (sessionToken) {
      return { accessToken: sessionToken }; // Token exists in sessionStorage
    }
    throw new Error("Token not found");
  } else {
    return (await tokenManager.get("accessToken")) as AccessToken;
  }
};

export const idToken = async () => {
  return (await tokenManager.get("idToken")) as IDToken;
};
