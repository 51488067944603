export enum actionTypesItems {
  ListOfItems = "itemsList",
  ListOfNestedItems = "nestedItemsList",
  IsNestedItemsListLoaded = "isNestedItemsListLoaded",
  ListOfDeliveryItems = "deliveryItemsList",
  ListOfNestedDeliveryItems = "nestedDeliveryItemsList",
  isDeliveryItemsListLoaded= "isDeliveryItemsListLoaded",
  IsNestedDeliveryItemsListLoaded = "isNestedDeliveryItemsListLoaded",
  ListOfStockTransferItems = "stockTransferItemsList",
  ListOfNestedStockTransferItemsList = "nestedStockTransferItemsList",
  isStockTransferItemsListLoaded = "isStockTransferItemsListLoaded",
  IsNestedStockTransferItemsListLoaded = "isNestedStockTransferItemsListLoaded"
}
