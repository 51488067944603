import mapiConfig from "../configs/mapi";
import { ordersModel, ordersCollectionModel } from "../models/ordersModel";
import { getUserInfo } from "./usersService";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import { OperationsDispatcher } from "../redux/dispatcher/operationsDispatcher";
import { actionTypesOperations } from "../redux/constants/actionTypesOperations";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

const BASE_URL = mapiConfig.api.domain;
const Controller = "/operations";

interface resultProps {
  data: any;
  isSuccessfull?: boolean;
  isLoading: boolean;
}

/* --------------------------------- OPERATIONS --------------------------------- */

export const getAllOperations = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>
): Promise<void> => {
  const methodPath = `${Controller}/list`;
  let operationResult: Array<ordersModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      operationResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  OperationsDispatcher(actionTypesOperations.ListOfOperations, operationResult);
};

export const getAllFilteredOperations = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  filters: { status?: string; dateRange?: { from: string; to: string } }
): Promise<void> => {
  OperationsDispatcher(actionTypesOperations.IsOperationsTableRefreshed, false);

  const methodPath = `${Controller}/list`;

  // Generate query parameters
  const queryParams = Object.entries(filters)
    .flatMap(([key, value]) =>
      key === "dateRange"
        ? [
            `from=${encodeURIComponent(value.from || "")}`,
            `to=${encodeURIComponent(value.to || "")}`,
          ]
        : `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    )
    .join("&");

  const fullPath = queryParams ? `${methodPath}?${queryParams}` : methodPath;

  let operationResult: Array<ordersModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: fullPath,
  })
    .then((result) => {
      operationResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  OperationsDispatcher(actionTypesOperations.FilterOperations, operationResult);
};

export const getSearchOperations = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  filters?: { status?: string; dateRange?: { from: string; to: string } },
  search?: string
): Promise<void> => {
  const methodPath = `${Controller}/operations`;

  // Generate query parameters
  const queryParams = [
    // Handle filters
    ...(filters
      ? Object.entries(filters).flatMap(([key, value]) =>
          key === "dateRange"
            ? [
                `from=${encodeURIComponent(value?.from || "")}`,
                `to=${encodeURIComponent(value?.to || "")}`,
              ]
            : value != null
            ? `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
            : []
        )
      : []),
    // Handle search
    ...(search ? [`search=${encodeURIComponent(search)}`] : []),
  ].join("&");

  const fullPath = queryParams ? `${methodPath}?${queryParams}` : methodPath;

  let operationResult: Array<ordersModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: fullPath,
  })
    .then((result) => {
      operationResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  OperationsDispatcher(actionTypesOperations.SearchOperations, operationResult);
};

/* --------------------------------- SALES OPERATIONS --------------------------------- */

export const getAllSalesOperationsList = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  createdBy: string
): Promise<void> => {
  const methodPath = `${Controller}/operations?createdBy=${createdBy}`;
  let operationResult: Array<ordersModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      operationResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  OperationsDispatcher(actionTypesOperations.ListOfOperations, operationResult);
};

export const getAllSalesFilteredOperationsList = async (
  createdBy: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  filters: { status?: string; dateRange?: { from: string; to: string } }
): Promise<void> => {
  OperationsDispatcher(actionTypesOperations.IsOperationsTableRefreshed, false);

  // Base path without query parameters
  const methodPath = `${Controller}/operations`;

  // Generate query parameters
  const queryParams = [
    `createdBy=${encodeURIComponent(createdBy)}`,
    ...Object.entries(filters).flatMap(([key, value]) =>
      key === "dateRange"
        ? [
            `from=${encodeURIComponent(value?.from || "")}`,
            `to=${encodeURIComponent(value?.to || "")}`,
          ]
        : `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    ),
  ].join("&");

  const fullPath = `${methodPath}?${queryParams}`;

  let operationResult: Array<ordersModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: fullPath,
  })
    .then((result) => {
      operationResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  OperationsDispatcher(actionTypesOperations.FilterOperations, operationResult);
};

export const getSalesSearchOperations = async (
  createdBy: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  filters?: { status?: string; dateRange?: { from: string; to: string } },
  search?: string
): Promise<void> => {
  // Base path without hardcoded "?" in the query string
  const methodPath = `${Controller}/operations`;

  // Generate query parameters
  const queryParams = [
    `createdBy=${encodeURIComponent(createdBy)}`,
    // Handle filters
    ...(filters
      ? Object.entries(filters).flatMap(([key, value]) =>
          key === "dateRange"
            ? [
                `from=${encodeURIComponent(value?.from || "")}`,
                `to=${encodeURIComponent(value?.to || "")}`,
              ]
            : value != null
            ? `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
            : []
        )
      : []),
    // Handle search
    ...(search ? [`search=${encodeURIComponent(search)}`] : []),
  ].join("&");

  // Ensure the query string starts correctly
  const fullPath = `${methodPath}?${queryParams}`;

  let operationResult: Array<ordersModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: fullPath,
  })
    .then((result) => {
      operationResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  OperationsDispatcher(actionTypesOperations.SearchOperations, operationResult);
};

export const getOperationById = async (operationId: string) => {
  const methodPath = `${Controller}/search/${operationId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data as ordersModel,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

/* --------------------------------- MANAGE OPERATIONS --------------------------------- */

export const manageOperation = async (operation: any, type: string) => {
  const userInfo = await getUserInfo();
  const methodPath =
    type === "add"
      ? `${Controller}/add`
      : `${Controller}/update/${operation._id}`;

  const httpBody = {
    ...operation,
    ...(type === "add" && { created_Date: new Date() }),
    ...(type === "add" && {
      created_by: `${userInfo.given_name} ${userInfo.family_name}`,
    }),
  };
  // return;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: type === "add" ? "POST" : "PUT",
    path: methodPath,
    body: httpBody,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const cloneOperationOrder = async (operation: any) => {
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: `${Controller}/add`,
    body: operation,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const manageBulkOperations = async (operations: Array<any>) => {
  const methodPath = `${Controller}/insert/bulk`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: operations,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const deleteOperationById = async (operationId: string) => {
  const methodPath = `${Controller}/delete/${operationId}`;
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "DELETE",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isSuccessfull: true,
        isLoading: false,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        isSuccessfull: false,
        isLoading: false,
      } as resultProps;
    });
};
